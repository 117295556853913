import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Button, IconButton } from '@material-ui/core';

export const ChatProfileButton = withStyles(() => ({
  root: {
    display: 'flex',
    minWidth: 110,
    '& svg': {
      marginRight: 4,
      height: 18,
    },
  },
}))(
  React.forwardRef((props, ref) => (
    <Button ref={ref} color="primary" variant="outlined" size="small" {...props} />
  ))
);

export const ChatProfileIconButton = withStyles((theme) => ({
  root: {
    padding: 6,
    color: theme.palette.primary.main,
  },
}))(React.forwardRef((props, ref) => <IconButton ref={ref} {...props} />));
