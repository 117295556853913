import { createAsyncThunk } from '@reduxjs/toolkit';

import { logDevMessage } from 'utils/utils';
import { FILTERS_SLICE_NAME } from '../slicesNames';
import * as selectAxios from '../config/selectAxios';
import { MATCH_ON_PARAM_VALUES } from './filterConstants';

export const fetchPredictedResultCount = createAsyncThunk(
  `${FILTERS_SLICE_NAME}/fetchPredictedResultCount`,
  async (args, thunkApi) => {
    const { queryString, wasPlaceSearch } = args;
    const { getState } = thunkApi;
    const state = getState();

    if (!queryString) throw new Error('No queryString provided');

    const axios = selectAxios.axiosInstance(state);

    const resultCountQuery = wasPlaceSearch ? '/places/count/?' : '/providers/count/?';
    const response = await axios.get(resultCountQuery.concat(queryString));

    return response.data;
  }
);

function transformFilterOptions(filterOptionsResponseData) {
  const result = {};

  // **if new dynamic filters are added, they need to be added to the transform map**
  // the transform map is responsible for transforming each filter key into an array of options with
  // shape { label: string, value: any }. The label being the option displayed in the filter menu,
  // the value being the value that used to compose the filter query string on searches

  const transformMap = {
    specialtyCounts: {
      filter: 'specialties',
      transform: (specialtyArray) =>
        specialtyArray.map((specialty) => ({
          label: specialty.specialty,
          value: String(specialty.specialtyId), // this needs to be a string because the <Checkbox /> component converts it's value to a string.
        })),
    },
    availableLanguages: {
      filter: 'languages',
      transform: (languagesArray) =>
        languagesArray
          .filter((lang) => lang !== 'English')
          .map((lang) => ({ label: lang, value: lang })),
    },
    availableCredentials: {
      filter: 'credentials',
      transform: (credentialsArray) =>
        credentialsArray.map((cred) => ({ label: cred, value: cred })),
    },
    hospitalAffiliations: {
      filter: 'hospitalAffiliations',
      transform: (affiliations) => affiliations.map((aff) => ({ label: aff, value: aff })),
    },
    groupAffiliations: {
      filter: 'groupAffiliations',
      transform: (affiliations) => affiliations.map((group) => ({ label: group, value: group })),
    },
    subspecialties: {
      filter: 'subspecialties',
      transform: (subspecialtiesArray) =>
        subspecialtiesArray.map((specialty) => ({
          label: specialty.subspecialty,
          value: String(specialty.subspecialtyId), // this needs to be a string because the <Checkbox /> component converts it's value to a string.
        })),
    },
    /**
     * Expect that highlightCounts will be an object where the key represents the field that was matched, and the value represents the number of results that matched on that field
     */
    highlightCounts: {
      filter: 'matchedOn',
      transform: (highlightObject) => {
        if (!highlightObject || typeof highlightObject !== 'object') return [];

        const options = [];

        // name
        const { entityName = 0 } = highlightObject;
        if (entityName) options.push({ label: 'Name', value: MATCH_ON_PARAM_VALUES.NAME });

        // affiliations
        const { hospitalAffiliations = 0, groupAffiliations = 0 } = highlightObject;
        const affiliationTotal = hospitalAffiliations + groupAffiliations;

        if (affiliationTotal)
          options.push({
            label: 'Affiliations',
            value: MATCH_ON_PARAM_VALUES.AFFILIATIONS,
          });

        // specialty
        const { keyword = 0, specialty = 0, subspecialtyNames = 0 } = highlightObject;
        const specialtyTotal = keyword + specialty + subspecialtyNames;

        if (specialtyTotal)
          options.push({
            label: 'Specialty, Focus Area, or Keyword',
            value: MATCH_ON_PARAM_VALUES.SPECIALTY,
          });

        return options;
      },
    },
  };

  for (const key in filterOptionsResponseData) {
    if (transformMap[key]) {
      result[transformMap[key].filter] = transformMap[key].transform(
        filterOptionsResponseData[key]
      );
    } else {
      logDevMessage(
        `Unhandled filter key in transformFilterOptions: ${key}. No corresponding transformMap available. To ingest this filter options data into the filtersSlice a transformMap must be provided.`
      );
    }
  }
  return result;
}

export const fetchFilterOptions = createAsyncThunk(
  `${FILTERS_SLICE_NAME}/fetchFilterOptions`,
  async (queryString, thunkApi) => {
    const { getState } = thunkApi;
    const state = getState();

    if (!queryString) throw new Error('No query string provided');

    const axios = selectAxios.axiosInstance(state);

    // fetch filter options
    const result = await axios.get(`/filter-options/?${queryString}`);

    // transform response into format that the front-end uses
    const transformedResults = transformFilterOptions(result.data);

    // returned the transformed response to the filtersSlice
    return transformedResults;
  }
);
