import { DEFAULT_LOCATIONS, DROPDOWN_AUTOCOMPLETE, WALMART } from 'utils/constants';
import { selectToken } from 'utils/selectToken';
import walmartLogo from 'assets/pg-walmart-header-logo.png';
import walmartLoginLogo from 'assets/wmt.png';
import { getEnv } from 'utils/utils';

export const apiTokens = {
  dev: 'Token 878f51af12d0432d04d00b538c0289d13a8c33a1',
  staging: 'Token 1dede7e7d6641a20bf5c8176614a500548b17752',
  prod: 'Token cdfb7df55a175e0b6c56064012d3c6c8dac52c2f',
};

const token = selectToken(apiTokens);

const walmartConfig = {
  // config name used for fetching client config from fusion
  CONFIG_NAME: getEnv() === 'production' ? 'wpg' : 'walmart',

  API_TOKEN: token,
  EMBOLD_CLIENT: WALMART,
  EMBOLD_CLIENT_SLUG: 'wmt',
  SHOW_DR_SCORE: false,
  SHOW_REGION_SELECTOR: true,
  HIGH_QUALITY_INTRO_MODAL: true,
  DEFAULT_LOCATION: { ...DEFAULT_LOCATIONS.Bentonville },
  LOGIN_NETWORK_NAME: 'wmtLoggedIn',

  // content
  DISPLAY_NAME: 'Walmart',
  HEADER_LOGO: walmartLogo,
  NO_WELCOME_LINK: true,
  EMBOLD_LOGO_PRESENT: true,
  LOCAL_LOGO_ASSET: walmartLoginLogo,
  FORM_VARIANT: DROPDOWN_AUTOCOMPLETE,
};

export default walmartConfig;
