import { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useQueryParams } from 'use-query-params';

import queryParamConfig, { selectUrlDirectSearchParams } from 'hooks/queryString/queryParamConfig';
import { select, thunks } from 'store/toolkit';
import { isEmpty } from 'lodash';

export default function useUrlSearch() {
  const dispatch = useDispatch();
  const requestParams = useSelector(selectUrlDirectSearchParams); // returns an object of values to enter into the query string
  const isClientConfigLoading = useSelector(select.config.isLoading);
  const shouldPreventRefetch = useSelector(select.results.shouldPreventRefetch);

  const [queryParams, setQueryParams] = useQueryParams(queryParamConfig); // stateful representation of the querystring

  const { action: historyAction, location } = useHistory();
  const { state: locationState } = useLocation();

  const wasRedirectedFromLogin = Boolean(locationState?.wasRedirectedFromLogin);

  // This effect updates the query string any time the request params changes. Request params change when a new search is complete
  useEffect(() => {
    if (requestParams) {
      setQueryParams(requestParams, 'replace');
    }
  }, [requestParams, setQueryParams]);

  // determine if we should perform a url direct search
  const shouldExecuteUrlDirectSearch = useMemo(
    () =>
      !isClientConfigLoading && // prevent url search if client config is still loading
      location.pathname.includes('/results') && // prevent passing params to specialties route
      !isEmpty(queryParams) && // prevent url search if there are no query params
      (wasRedirectedFromLogin || historyAction === 'POP') && // allow search if we were directed from login OR used a forward/back button
      !shouldPreventRefetch, // do not allow search if user clicked mobile search form, changed no params, and then clicked back
    [
      isClientConfigLoading,
      queryParams,
      wasRedirectedFromLogin,
      historyAction,
      location,
      shouldPreventRefetch,
    ]
  );

  // This effect watches the querystring of the url, and dispatches an action to redux to update the store with the received params
  useEffect(() => {
    if (shouldExecuteUrlDirectSearch) {
      dispatch(thunks.results.urlDirectSearch(queryParams));
    }
  }, [dispatch, queryParams, shouldExecuteUrlDirectSearch]);
}
