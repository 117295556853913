import { useCallback } from 'react';
import { distance as calcDistance } from 'utils/utils';
import { strengthLanguageMap } from 'components/Profile/ProfileSections/ProviderStrengths';
import { PERCENTILE_THRESHOLD, SUBSPECIALTY_SECTION } from 'utils/constants';
import { useSelector } from 'react-redux';
import { select } from 'store/toolkit';

import { useGetProviderScore } from './useProviderScore';
import useGetNetworkContent from './useGetNetworkContent';

/** @deprecated This has been replaced by a selector. We will extend it to be usable by chat in TECH-3695 */
export default function useProviderDetailsToString() {
  const resultType = useSelector(select.results.resultsType);
  const networkSlug = useSelector(select.networks.currentSlug);
  const showDrScore = useSelector(select.featureFlags.showDrScore);
  const clientName = useSelector(select.client.name);
  const getProviderScore = useGetProviderScore();
  const getNetworkContent = useGetNetworkContent();
  const userLocation = useSelector(select.location.latLong);

  const showCost = useSelector(select.featureFlags.showCost);

  /**
   * @param {object} provider An object returned from the API for a provider result
   * @returns {string} A string representation of the provider object used for copy and paste feature
   */
  const providerDetailsToString = useCallback(
    (provider) => {
      const NA = 'N/A'; // constant used when no value is available
      let result = ''; // complete string to be returned at the end

      const appendLine = (text) => {
        result += `\n${text}`;
      };
      const appendHeader = (text) => {
        appendLine(`--${text}--`);
      };

      // name
      result = `Name: ${provider.entityName}`;

      if (provider.titleArray) {
        result += ' - ';
        // iterate over title array, appending to the string
        for (let i = 0; i < provider.titleArray.length; i += 1) {
          result += provider.titleArray[i];
          if (i < provider.titleArray.length - 1) result += ',';
        }
      } else if (provider.title) {
        // add only a single title
        result += ` - ${provider.title}`;
      }

      // OVERVIEW SECTION
      appendHeader('Overview');

      // get the text used for Network Coverage. This function comes from the <CoverageRibbon />
      const { networkText = '' } = getNetworkContent(provider, clientName, networkSlug, resultType);
      appendLine(`Network Coverage: ${networkText}`);

      // location details, all fields in this if block are dependant on this closestVisibleLocation object
      if (provider.closestVisibleLocation) {
        const {
          address1,
          address2 = '',
          city,
          state,
          zipcode,
          phone,
          distanceInMiles = null,
          location: providerLocation,
          acceptingNewPatients,
        } = provider.closestVisibleLocation;

        // address
        if (address1 && city && state) {
          appendLine(`Address: ${address1}`); // Address: 123 Main St
          if (address2) result += ` ${address2}`; // Address: 123 Main St Ste 456
          result += `, ${city}, ${state} ${zipcode}`; // Address: 123 Main St Ste 456, Chicago, IL 12345
        }

        // phone
        appendLine(`Phone: ${phone || NA}`);

        // distance
        // NOTE that this distance logic is duplicated from CompareTable.jsx
        let distance;
        if (distanceInMiles) {
          distance = distanceInMiles; // if distance is provided on the closestLocation object, then use it
        } else if (providerLocation) {
          // if not then we need to calculate the distance
          const calculatedDistance = +calcDistance(
            providerLocation.lat,
            providerLocation.lon,
            userLocation.latitude,
            userLocation.longitude
          );
          // ensure that calculated result is a valid number
          const resultIsValid =
            typeof calculatedDistance === 'number' && !Number.isNaN(calculatedDistance);

          distance = resultIsValid ? calculatedDistance : NA;
        } else {
          distance = NA;
        }

        appendLine(`Distance: ${distance}`);

        if (distance !== NA) {
          result += ' mile';
          if (distance !== 1.0) result += 's';
        }

        // accepting new patients
        switch (acceptingNewPatients) {
          case true:
            appendLine(`Accepting New Patients: Yes`);
            break;
          case false:
            appendLine(`Accepting New Patients: No`);
            break;
          case undefined:
            appendLine(`Accepting New Patients: ${NA}`);
            break;
          default:
            appendLine(`Accepting New Patients: ${NA}`);
        }
      }

      // gender
      appendLine(`Gender: ${provider.gender || NA}`);

      // EMBOLD SCORES SECTION
      if (showDrScore) {
        appendHeader('Embold Measured Areas');
      }

      const score = getProviderScore(provider);
      if (score) appendLine(`${score.name}: ${score.value}`);

      // for every strength we need to calculate the wording based on the strengthLanguageMap
      // NOTE, this logic is duplicated from ProviderStrengths.jsx
      function getStrengthText(percentile, key) {
        if (provider.emboldEvaluated && percentile >= PERCENTILE_THRESHOLD) {
          const context = strengthLanguageMap[key];

          if (percentile >= 0.9) return context.top10.performanceLanguage;
          if (percentile >= 0.75) return context.top25.performanceLanguage;
        }
        return NA;
      }

      appendLine(
        `Diagnosing Quality: ${getStrengthText(provider.diagnosingPercentile, 'diagnosing')}`
      );

      appendLine(
        `Treatment Plan Quality: ${getStrengthText(provider.treatmentPercentile, 'treatment')}`
      );

      appendLine(
        `Patient Outcomes Quality: ${getStrengthText(provider.outcomesPercentile, 'outcomes')}`
      );

      // specialty areas
      if (provider.subspecialties) {
        let subspecialtiesString = '';
        for (let i = 0; i < provider.subspecialties.length; i += 1) {
          subspecialtiesString += provider.subspecialties[i].subspecialtyName;
          if (i < provider.subspecialties.length - 1) subspecialtiesString += ', ';
        }

        appendLine(`${SUBSPECIALTY_SECTION.title}: ${subspecialtiesString}`);
      }

      // cost
      // NOTE much of this section contains duplicate logic from CompareTable.jsx
      if (showCost && provider.scoreCareJourneyCost) {
        appendLine('Cost: ');
        switch (provider.scoreCareJourneyCost) {
          case 3:
            result += 'Lower Cost';
            break;
          case 2:
            result += 'Average Cost';
            break;
          case 1:
            result += 'Higher Cost';
            break;
          default:
            result += NA;
        }
      }

      // CREDENTIALS AND EXPERIENCE SECTION
      appendHeader('Credentials & Experience');

      // medical school
      appendLine(`Medical School: ${provider.medicalSchool || NA}`);
      // years experience
      appendLine(`Years Experience: ${provider.yearsExperience || NA}`);

      return result;
    },
    [
      resultType,
      networkSlug,
      userLocation,
      clientName,
      showCost,
      showDrScore,
      getProviderScore,
      getNetworkContent,
    ]
  );

  return providerDetailsToString;
}
