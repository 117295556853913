import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Button, IconButton } from '@material-ui/core/';

import { useLinkToModal } from 'components/LinkToModal';
import { AiSparkle } from 'icons/colored';
import { select } from 'store/toolkit';

const useStyles = makeStyles((theme) => ({
  aiSearchButton: {
    whiteSpace: 'nowrap',
    marginLeft: theme.spacing(0.5),
  },
}));

export default function AiSearchButton({ iconOnly }) {
  const classes = useStyles();

  const enableAiSearch = useSelector(select.featureFlags.enableAiSearch);
  const handleLinkToModal = useLinkToModal();
  const handleOpenAiSearch = useCallback(
    () => handleLinkToModal('/ai-search'),
    [handleLinkToModal]
  ); // TODO: constant route

  if (!enableAiSearch) return null; // safeguard in case we forget to render this component conditionally

  if (iconOnly) {
    return (
      <IconButton
        classes={{ root: classes.aiSearchButton }}
        color="secondary"
        onClick={handleOpenAiSearch}
        aria-label="Use AI search"
      >
        <AiSparkle />
      </IconButton>
    );
  }

  return (
    <Button
      classes={{ root: classes.aiSearchButton }}
      color="secondary"
      startIcon={<AiSparkle />}
      onClick={handleOpenAiSearch}
      aria-label="Use AI search"
    >
      AI Search
    </Button>
  );
}
AiSearchButton.propTypes = {
  iconOnly: PropTypes.bool,
};
AiSearchButton.defaultProps = {
  iconOnly: false,
};
