import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useFocusTo } from 'utils/FocusRefContext';
import { thunks } from 'store/toolkit';
import useSearchDispatchWithHistory from 'hooks/useSearchDispatchWithHistory';
import { _analyticsActions } from 'analytics/index';

const useSubmitSearchForm = () => {
  const dispatch = useDispatch();
  const searchDispatch = useSearchDispatchWithHistory();

  const focusTo = useFocusTo();

  return useCallback(
    (e) => {
      e?.preventDefault();
      searchDispatch(thunks.results.executeSearch({ clearArrayFilters: true }));
      dispatch(_analyticsActions.searchFormSubmitted());
      focusTo.searchButton();
    },
    [searchDispatch, focusTo, dispatch]
  );
};

export default useSubmitSearchForm;
