import { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core';

import { CHAT_BANNER_VARIANT, MARKDOWN_BANNER_VARIANT } from 'store/slices/banner/bannerConstants';
import { INFO } from 'store/slices/notifications/notificationsConstants';
import { actions, select } from 'store/toolkit';
import { QUANTUM } from 'utils/constants';
import { useLocation } from 'react-router-dom';
import { MOBILE_SEARCH_ROUTE } from 'navigation/navigationConstants';

export function getChatBannerColor(themeVariant) {
  switch (themeVariant) {
    case QUANTUM:
      return 'primary';
    default:
      return 'secondary';
  }
}

export const filterEnabled = (b) => Boolean(b.isEnabled);
export const sortByPriority = (a, b) => a.priority - b.priority;
export const getFirstEnabled = (arr) => arr.sort(sortByPriority).find(filterEnabled);

export const handleBanner = (hasDismissed, banners, showBanner, hideBanner) => {
  if (hasDismissed) return;

  const banner = getFirstEnabled(banners);
  if (!banner) {
    hideBanner();
    return;
  }

  if (banner.isValidRoute) showBanner(banner.bannerProps);
  else hideBanner();
};

export default () => {
  const dispatch = useDispatch();

  const enableChat = useSelector(select.featureFlags.enableChat);
  const searchNoticeBannerText = useSelector(select.content.searchNoticeBannerText);
  const location = useLocation();
  const [hasDismissed, setHasDismissed] = useState(false);
  const dismissBanner = useCallback(() => setHasDismissed(true), [setHasDismissed]);

  const { variantName } = useTheme();

  const hideBanner = useCallback(() => dispatch(actions.banner.hideBanner()), [dispatch]);
  const showBanner = useCallback(
    (bannerProps) => dispatch(actions.banner.showBanner(bannerProps)),
    [dispatch]
  );

  const banners = useMemo(
    () => [
      {
        bannerProps: {
          variant: MARKDOWN_BANNER_VARIANT,
          color: INFO,
          text: searchNoticeBannerText,
        },
        isValidRoute: true, // all routes valid
        isEnabled: Boolean(searchNoticeBannerText),
        priority: 1,
      },
      {
        bannerProps: {
          variant: CHAT_BANNER_VARIANT,
          color: getChatBannerColor(variantName),
          text: 'Try Virtual Assistant',
        },
        isValidRoute:
          location.pathname === '/' ||
          location.pathname.startsWith(MOBILE_SEARCH_ROUTE) ||
          location.pathname.startsWith('/results') ||
          location.pathname.startsWith('/specialties'),
        isEnabled: enableChat,
        priority: 5,
      },
    ],
    [enableChat, location.pathname, searchNoticeBannerText, variantName]
  );

  useEffect(() => {
    handleBanner(hasDismissed, banners, showBanner, hideBanner);
  }, [banners, hasDismissed, hideBanner, showBanner]);

  return { dismissBanner };
};
