import React from 'react';
import { Switch, Route } from 'react-router-dom';

import AuthProtectedRoute from 'components/Navigation/AuthProtectedRoute';

import ColorsStyleguide from 'components/Pages/StyleGuide/ColorsStyleguide';
import CustomComponentStyleguide from './CustomComponentsStyleguide';
import StyleguideNav from './StyleguideNav';
import ModernExperienceStyleguide from './ModernExperienceStyleguide';
import ResultCardsStyleguide from './ResultCardsStyleguide';
import ProfileStyleguide from './ProfileStyleguide';
import StyleGuide from './StyleGuide';
import IconsStyleguide from './IconsStyleguide';
import AutocompleteStyleguide from './AutocompleteStyleguide';
import SplashPageStyleguide from './SplashPageStyleguide';

export default function StyleguideApp() {
  return (
    <>
      <StyleguideNav />
      <Switch>
        <Route path="/styleguide/" exact component={StyleGuide} />
        <Route path="/styleguide/colors" exact component={ColorsStyleguide} />
        <Route path="/styleguide/components" exact component={CustomComponentStyleguide} />
        <Route path="/styleguide/modern-experience" exact component={ModernExperienceStyleguide} />
        <Route path="/styleguide/icons" exact component={IconsStyleguide} />
        <Route path="/styleguide/autocomplete" exact component={AutocompleteStyleguide} />
        <AuthProtectedRoute
          path="/styleguide/result-cards"
          exact
          render={() => <ResultCardsStyleguide />}
        />
        <AuthProtectedRoute path="/styleguide/profile" exact render={() => <ProfileStyleguide />} />
        <AuthProtectedRoute
          path="/styleguide/splash-page"
          exact
          render={() => <SplashPageStyleguide />}
        />
      </Switch>
    </>
  );
}
