import { useCallback } from 'react';
import * as Sentry from '@sentry/react';
import { useDispatch } from 'react-redux';

import LinkToModal from 'components/LinkToModal';
import { FEATURED_ACTION_VARIANTS } from 'utils/constants';
import { thunks } from 'store/toolkit';
import { _analyticsActions } from 'analytics/index';
import useSearchDispatchWithHistory from './useSearchDispatchWithHistory';

/**
 * This hook returns a function that accepts a featured action object as an argument.
 * The function returns an object of props to be spread to the FeaturedActionCard.
 * @returns {Function} getFeaturedActionVariantProps
 */
export default function useGetFeaturedActionVariantProps() {
  const dispatch = useDispatch();
  const searchDispatch = useSearchDispatchWithHistory();

  return useCallback(
    (action) => {
      const props = {};

      if (typeof action !== 'object') {
        Sentry.captureMessage(
          `Invalid argument of type ${typeof action} in useGetFeaturedActionVariantProps`
        );
        return props;
      }

      switch (action.variant) {
        case FEATURED_ACTION_VARIANTS.PROMOTION:
          props.ButtonProps = {
            component: LinkToModal,
            to: `/promotion/${action.promotion}`,
            underline: 'none',
          };
          props.onClick = () => dispatch(_analyticsActions.featuredActionClick(action));
          return props;
        case FEATURED_ACTION_VARIANTS.SEARCH:
          props.onClick = () => searchDispatch(thunks.results.featuredActionSearch(action));
          return props;
        default:
          Sentry.captureMessage(`Unhandled featured action variant ${action.variant}`);
          return props;
      }
    },
    [dispatch, searchDispatch]
  );
}
