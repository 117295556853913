import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import 'whatwg-fetch';
import { withRouter, Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import { Container, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { select } from 'store/toolkit';
import withModalTemplate from 'components/Modals/withModalTemplate';
import BenefitDetails from 'components/ModernExperience/Benefit/BenefitDetails';
import LoadingSpinner from 'components/LoadingSpinner';
import Promotion from 'icons/Promotion';
import PageTemplate from './PageTemplate';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    maxWidth: 800,
  },
  titleContainer: {
    '& img, svg': { width: theme.typography.h1.fontSize, marginRight: theme.spacing(1) },
    '& svg': { height: theme.typography.h1.fontSize },
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  errorPage: {
    textAlign: 'center',
    '& .MuiButton-root': {
      marginTop: theme.spacing(2),
    },
  },
}));

function PromotionProfilePage({ match }) {
  const loading = useSelector(select.promotions.isLoading);
  const loaded = useSelector(select.promotions.isLoaded);
  const profileId = parseInt(match.params.id, 10);
  const classes = useStyles();
  const benefit = useSelector(select.promotions.byId(profileId));
  const isSmDown = useSelector(select.ui.isSmDown);

  useEffect(() => {
    if (!profileId) {
      Sentry.captureException(new Error(`profileId required to load Promotion Profile`));
    } else if (loaded && !benefit) {
      Sentry.captureException(new Error(`No Promotions found`));
    }
  }, [profileId, benefit, loaded]);

  if (loading) {
    return <LoadingSpinner loading />;
  }

  if (!benefit) {
    return (
      <Container classes={{ root: `${classes.root} ${classes.errorPage}` }}>
        <Typography variant="h2">Something Went Wrong</Typography>
        <Typography>
          We were unable to find this benefit based on your current network. Please visit the home
          page to see all available benefits for your current network.
        </Typography>
        <Button component={RouterLink} to="/" variant="contained">
          Go Home
        </Button>
      </Container>
    );
  }

  return (
    <Container classes={{ root: classes.root }}>
      <div className={classes.titleContainer}>
        {benefit.logo ? (
          <img src={benefit.logo} alt={`${benefit.programName} logo`} />
        ) : (
          <Promotion color="primary" />
        )}
        <Typography variant="h2" color="primary" align="center" id="benefit-title">
          {benefit.programName}
        </Typography>
      </div>
      <BenefitDetails headingLevel="h3" benefit={benefit} isMobile={isSmDown} />
    </Container>
  );
}

PromotionProfilePage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) }),
  }).isRequired,
};

export default withRouter(PageTemplate(PromotionProfilePage, 'Benefit Details'));
export const PromotionProfileModal = withRouter(
  withModalTemplate(PromotionProfilePage, { ariaId: 'benefit', maxWidth: 'full' })
);
