/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import { capitalize } from 'lodash';

import Alert from '@material-ui/lab/Alert';
import {
  Container,
  Grid,
  Typography,
  Button,
  Divider,
  MenuItem,
  TextField,
  FormControlLabel,
  RadioGroup,
  Radio,
  IconButton,
  InputAdornment,
  Link,
  Fab,
  ButtonGroup,
  Checkbox,
  Paper,
  CircularProgress,
  Switch,
  Slider,
  useTheme,
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import NavigationIcon from '@material-ui/icons/Navigation';
import FavoriteIcon from '@material-ui/icons/Favorite';

import AntSwitch from 'components/AntSwitch';
import Modal from 'components/Modals/Modal';
import QualityMediumIcon from 'icons/QualityMedium';

import { ERROR, INFO, SUCCESS, WARNING } from 'store/slices/notifications/notificationsConstants';

import DownshiftExample from './DownshiftExample';
import SampleModalContent from './SampleModalContent';
import TabsExample from './TabsExample';
import ComponentPropGrid from './ComponentPropGrid';

const MODAL_SIZES = ['sm', 'md', 'full'];

const buttonVariants = ['text', 'contained', 'outlined'];
const buttonColors = ['default', 'primary', 'secondary'];

// https://v4.mui.com/api/text-field/#textfield-api
const textField = {
  variant: ['outlined', 'filled', 'standard'],
  size: ['medium', 'small'],
  margin: ['normal', 'dense', 'none'],
  color: ['primary', 'secondary'],
  error: [true],
  helperText: ['This is some helpful text'],
  disabled: [true],
  placeholder: ["I'm the placeholder text"],
};

function StyleGuide() {
  const { palette } = useTheme();
  const [radioValue, setRadioValue] = useState('1');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalProps, setModalProps] = useState({});
  const [typographyColor, setTypographyColor] = useState('initial');
  const [pageBackgroundColor, setPageBackgroundColor] = useState(palette.common.white);
  const [slider, setSlider] = useState(30);

  const handleModalOpen = (props) => {
    setModalIsOpen(true);
    setModalProps(props);
  };

  const handleRadio = ({ target: { value } }) => {
    setRadioValue(value);
  };

  const handleSlider = (evt, val) => setSlider(val);

  return (
    <Container style={{ padding: 10, backgroundColor: pageBackgroundColor }}>
      <label htmlFor="page-background">
        Background Color
        <select
          id="page-background"
          value={pageBackgroundColor}
          onChange={(evt) => setPageBackgroundColor(evt.target.value)}
        >
          <option value={palette.common.white}>White</option>
          <option value={palette.grey[100]}>Grey 100</option>
        </select>
      </label>
      <Container>
        <select onChange={(evt) => setTypographyColor(evt.target.value)}>
          {[
            'initial',
            'inherit',
            'primary',
            'secondary',
            'textPrimary',
            'textSecondary',
            'error',
          ].map((val) => (
            <option value={val} key={val}>
              {val}
            </option>
          ))}
        </select>
        <Typography variant="h1" color={typographyColor}>
          Header 1
        </Typography>
        <Typography variant="h2" color={typographyColor}>
          Header 2
        </Typography>
        <Typography variant="h3" color={typographyColor}>
          Header 3
        </Typography>
        <Typography variant="h4" color={typographyColor}>
          Header 4
        </Typography>
        <Typography variant="h5" color={typographyColor}>
          Header 5
        </Typography>
        <Typography variant="h6" color={typographyColor}>
          Header 6
        </Typography>
        <Typography variant="subtitle1" color={typographyColor}>
          Subtitle 1
        </Typography>
        <Typography variant="subtitle2" color={typographyColor}>
          Subtitle 2
        </Typography>
        <Typography variant="body1" paragraph color={typographyColor}>
          Body 1: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
          incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
          exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
          dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
          Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
          anim id est laborum.
        </Typography>
        <Typography variant="body2" paragraph color={typographyColor}>
          Body 2: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
          incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
          exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
          dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
          Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
          anim id est laborum.
        </Typography>
        <Typography variant="button" color={typographyColor}>
          Button
        </Typography>
        <br />
        <Typography variant="caption" color={typographyColor}>
          Caption: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
          incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
          exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
          dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
          Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
          anim id est laborum.
        </Typography>
        <br />
        <Typography variant="overline" color={typographyColor}>
          Overline
        </Typography>
      </Container>

      <Divider />

      <Grid container component={Container} spacing={2}>
        <Grid item xs={12} href="#" component={Typography} variant="h2">
          Links
        </Grid>

        <Grid item component={Link} href="#" color={typographyColor} underline="none">
          Link: underline="none"
        </Grid>
        <Grid item component={Link} href="#" color={typographyColor} underline="hover">
          Link: underline="hover"
        </Grid>
        <Grid item component={Link} href="#" color={typographyColor} underline="always">
          Link: underline="always" (theme default)
        </Grid>
      </Grid>
      <Divider />

      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} component={Typography} variant="h2">
            Downshift Autocomplete
          </Grid>
          <Grid item xs={12} sm={4}>
            <DownshiftExample />
          </Grid>
        </Grid>
      </Container>
      <Divider />

      <Container>
        <Typography variant="h2">Modals</Typography>

        <Grid container spacing={2}>
          {MODAL_SIZES.map((size) => (
            <Grid key={size} item xs={12} sm={4}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleModalOpen({ maxWidth: size })}
              >
                Open modal
              </Button>
              <pre>maxWidth: {size}</pre>
            </Grid>
          ))}
          {MODAL_SIZES.map((size) => (
            <Grid key={size} item xs={12} sm={4}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleModalOpen({ maxWidth: size, fullWidth: true })}
              >
                Open modal
              </Button>
              <pre>
                maxWidth: {size}
                <br />
                fullWidth: true
              </pre>
            </Grid>
          ))}

          <Grid item xs={12} sm={4}>
            <Button variant="contained" onClick={() => handleModalOpen({ fullScreen: true })}>
              Open modal
            </Button>
            <pre>fullScreen: true</pre>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Button variant="contained" onClick={() => handleModalOpen({ errorModal: true })}>
              Open modal
            </Button>
            <pre>errorModal: true</pre>
          </Grid>
        </Grid>

        <Modal
          open={modalIsOpen}
          ariaId="styleguide-modal"
          handleClose={() => setModalIsOpen(false)}
          title="Modal Styleguide Example"
          {...modalProps}
        >
          <SampleModalContent />
        </Modal>
      </Container>

      <Divider />

      <Container>
        <ComponentPropGrid
          component={TextField}
          title="TextField"
          propOptions={textField}
          sm={4}
          fullWidth
          propValueCallback={(prop, value) => {
            let label = value;
            if (typeof value === 'boolean' || prop === 'helperText') label = prop;
            if (prop === 'placeholder') label = 'With placeholder';
            return { label };
          }}
        >
          <Grid item xs={12} component={Typography} variant="h3" color="textSecondary">
            With Start Adornment
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Start Adornment"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AddIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="End Adornment"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AddIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </ComponentPropGrid>

        <ComponentPropGrid
          component={TextField}
          title='TextField with custom class className="rounded"'
          propOptions={{ size: textField.size }}
          sm={4}
          className="rounded"
          fullWidth
          label="Rounded"
        />

        <ComponentPropGrid
          component={TextField}
          title='TextField with custom className="square"'
          propOptions={{ size: textField.size }}
          sm={4}
          className="square"
          fullWidth
          label="Square"
        />

        <ComponentPropGrid
          component={TextField}
          title="TextField with Select"
          propOptions={textField}
          sm={4}
          select
          fullWidth
          propValueCallback={(prop, value) => {
            let label = value;
            if (typeof value === 'boolean' || prop === 'helperText') label = prop;
            if (prop === 'placeholder') label = 'With placeholder';
            return { label };
          }}
          renderChildren={() => [
            <MenuItem key="default-option">-Please Select-</MenuItem>,
            ...[1, 2, 3].map((el) => (
              <MenuItem key={el} value={el}>
                Option {el}
              </MenuItem>
            )),
          ]}
        >
          <Grid item xs={12} component={Typography} variant="h3" color="textSecondary">
            With Start Adornment
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              select
              label="Start Adornment"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AddIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </ComponentPropGrid>
      </Container>

      <Divider />

      <Container>
        <Typography variant="h2">Radio Buttons</Typography>

        <RadioGroup value={radioValue} onChange={handleRadio}>
          <FormControlLabel
            value="1"
            control={<Radio color="primary" />}
            label="Radio 1 (primary)"
          />
          <FormControlLabel
            value="2"
            control={<Radio color="secondary" />}
            label="Radio 2 (secondary)"
          />
          <FormControlLabel value="3" control={<Radio disabled />} label="Radio 3 (disabled)" />
        </RadioGroup>
      </Container>

      <Divider />

      <Grid container component={Container}>
        <Grid item xs={12} component={Typography} variant="h2">
          Switches
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption">MUI</Typography>
          <Switch
            defaultChecked
            color="default"
            inputProps={{ 'aria-label': 'checkbox with default color' }}
          />
          <Switch
            defaultChecked
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          <Switch
            defaultChecked
            color="secondary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          <Switch disabled inputProps={{ 'aria-label': 'disabled checkbox' }} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption">Custom Ant Switch</Typography>
          <AntSwitch
            defaultChecked
            color="default"
            inputProps={{ 'aria-label': 'checkbox with default color' }}
          />
          <AntSwitch
            defaultChecked
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          <AntSwitch
            defaultChecked
            color="secondary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          <AntSwitch disabled inputProps={{ 'aria-label': 'disabled checkbox' }} />
        </Grid>
      </Grid>

      <Divider />

      <Container>
        <Typography variant="h2">Checkboxes</Typography>

        <FormControlLabel
          value="1"
          control={<Checkbox />}
          label="Checkbox 1 (primary)"
          labelPlacement="end"
        />

        <FormControlLabel
          value="2"
          control={<Checkbox color="secondary" />}
          label="Checkbox 2 (secondary)"
          labelPlacement="end"
        />

        <FormControlLabel
          value="3"
          control={<Checkbox disabled />}
          label="Checkbox 3 (disabled)"
          labelPlacement="end"
        />
      </Container>

      <Divider />

      <Grid container component={Container}>
        <Grid item xs={12} component={Typography} variant="h2">
          Slider
        </Grid>
        <Grid item xs={6}>
          <Typography id="continuous-slider" gutterBottom>
            Basic Slider
          </Typography>
          <Slider value={slider} onChange={handleSlider} aria-labelledby="continuous-slider" />
          <Slider
            value={slider}
            onChange={handleSlider}
            color="secondary"
            aria-labelledby="continuous-slider"
          />
          <Slider
            value={slider}
            onChange={handleSlider}
            step={10}
            marks
            aria-labelledby="continuous-slider"
          />
        </Grid>
      </Grid>

      <Divider />

      {/* ***** Buttons ****** */}
      <Grid container component={Container}>
        <Grid item xs={12} component={Typography} variant="h2">
          Buttons
        </Grid>
        {buttonVariants.map((variant) => (
          <Grid
            item
            container
            xs={12}
            sm={6}
            md={3}
            key={variant}
            spacing={1}
            component={Paper}
            variant="outlined"
            style={{ margin: 4, backgroundColor: 'transparent' }}
          >
            <Grid item xs={12} component={Typography} variant="subtitle1">
              Variant: {variant}
            </Grid>
            {buttonColors.map((color) => (
              <React.Fragment key={`${variant}-${color}`}>
                <Grid
                  item
                  xs={12}
                  component={Typography}
                  variant="subtitle2"
                  style={{ borderBottom: '1px solid lightgrey', backgroundColor: '#f7f7f7' }}
                >
                  Color: {color}
                </Grid>
                <Grid item xs={12}>
                  <Button variant={variant} color={color}>
                    {capitalize(color)} {capitalize(variant)}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button variant={variant} color={color} startIcon={<QualityMediumIcon />}>
                    Start Icon
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button variant={variant} color={color} endIcon={<QualityMediumIcon />}>
                    End Icon
                  </Button>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        ))}

        <Grid
          item
          container
          xs={12}
          sm={6}
          md={3}
          spacing={1}
          component={Paper}
          variant="outlined"
          style={{ margin: 4 }}
        >
          <Grid item xs={12} component={Typography} variant="subtitle1">
            Sizes
          </Grid>
          {['small', 'medium', 'large'].map((size) => (
            <Grid item xs={12} key={size}>
              <Button
                variant="contained"
                color="primary"
                size={size}
                startIcon={<QualityMediumIcon />}
              >
                Size: {capitalize(size)}
              </Button>
            </Grid>
          ))}
        </Grid>

        <Grid item xs={12} style={{ backgroundColor: 'lightgrey', padding: 8 }} component={Paper}>
          <Typography variant="h3">Outlined with a white background</Typography>
          <Typography paragraph>
            There may be instances where you need an outlined variant button, but with a white
            background. By default, the outlined button has a transparent background.
          </Typography>
          <Typography paragraph>
            This can be accomplished by adding a{' '}
            <i style={{ display: 'inline' }}>className="white-background"</i> to any{' '}
            <i style={{ display: 'inline' }}>variant="outlined"</i> button
          </Typography>
          <Button variant="outlined" color="primary">
            Regular Outlined
          </Button>
          <Button variant="outlined" color="primary" className="white-background">
            className="white-background"
          </Button>
          <Typography>
            The code for this customization is found in the default theme overrides for the
            MuiButton
          </Typography>
        </Grid>
      </Grid>

      <Divider />

      <Grid container component={Container}>
        <Grid item xs={12} component={Typography} variant="h2">
          Icon Buttons
        </Grid>

        {buttonColors.map((color) => (
          <React.Fragment key={color}>
            <Grid item xs={12} component={Typography} variant="subtitle2">
              Color: {color}
            </Grid>
            <Grid item xs={6}>
              <Typography>small</Typography>
              <IconButton color={color} size="small">
                <QualityMediumIcon />
              </IconButton>
            </Grid>
            <Grid item xs={6}>
              <Typography>medium</Typography>
              <IconButton color={color} size="medium">
                <QualityMediumIcon />
              </IconButton>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>

      <Grid container component={Container}>
        <Typography variant="h2">Button Group</Typography>

        <Grid item xs={12}>
          <Typography variant="h3">Text</Typography>
          <ButtonGroup variant="text">
            <Button>One</Button>
            <Button>Two</Button>
            <Button>Three</Button>
          </ButtonGroup>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h3">Contained</Typography>
          <ButtonGroup variant="contained" color="primary">
            <Button id="test">One</Button>
            <Button>Two</Button>
            <Button>Three</Button>
          </ButtonGroup>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h3">Outlined (default)</Typography>
          <ButtonGroup variant="outlined" color="primary">
            <Button>One</Button>
            <Button>Two</Button>
            <Button>Three</Button>
          </ButtonGroup>
        </Grid>
      </Grid>

      <Divider />

      <Grid container component={Container} spacing={2}>
        <Grid item xs={12} component={Typography} variant="h2">
          Fab (Floating Action Button)
        </Grid>
        <Grid item>
          <Fab aria-label="add">
            <AddIcon />
          </Fab>
        </Grid>
        <Grid item>
          <Fab color="primary" aria-label="add">
            <AddIcon />
          </Fab>
        </Grid>
        <Grid item>
          <Fab color="secondary" aria-label="edit">
            <EditIcon />
          </Fab>
        </Grid>
        <Grid item>
          <Fab variant="extended">
            <NavigationIcon />
            Navigate
          </Fab>
        </Grid>
        <Grid item>
          <Fab variant="extended" color="primary">
            <NavigationIcon />
            Navigate
          </Fab>
        </Grid>
        <Grid item>
          <Fab disabled aria-label="like">
            <FavoriteIcon />
          </Fab>
        </Grid>
      </Grid>

      <Divider />

      <Grid container component={Container} spacing={1}>
        <Typography variant="h2">Alerts</Typography>
        <Grid item xs={12}>
          <Alert severity={ERROR}>This is an error alert — check it out!</Alert>
        </Grid>
        <Grid item xs={12}>
          <Alert severity={WARNING}>This is a warning alert — check it out!</Alert>
        </Grid>
        <Grid item xs={12}>
          <Alert severity={INFO}>This is an info alert — check it out!</Alert>
        </Grid>
        <Grid item xs={12}>
          <Alert severity={SUCCESS}>This is a success alert — check it out!</Alert>
        </Grid>
      </Grid>

      <Grid container component={Container} spacing={2}>
        <Grid item xs={12} component={Typography} variant="h2">
          CircularProgress
        </Grid>
        <Grid item>
          <Typography>Primary</Typography>
          <CircularProgress color="primary" />
        </Grid>
        <Grid item>
          <Typography>Secondary</Typography>
          <CircularProgress color="secondary" />
        </Grid>
        <Grid item>
          <Typography>Thickness: 1</Typography>
          <CircularProgress thickness={1} />
        </Grid>
        <Grid item>
          <Typography>Tickness: 3</Typography>
          <CircularProgress thickness={3} />
        </Grid>
        <Grid item>
          <Typography>Thickness: 6</Typography>
          <CircularProgress thickness={6} />
        </Grid>
      </Grid>

      <Divider />

      <Grid container component={Container}>
        <Grid item xs={12} component={Typography} variant="h2">
          Tabs
        </Grid>
        <Grid item xs={12}>
          <TabsExample indicatorColor="primary" />
        </Grid>
        <Grid item xs={12}>
          <TabsExample indicatorColor="secondary" />
        </Grid>
      </Grid>
    </Container>
  );
}

export default StyleGuide;
