import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'propTypes/index';
import CloseIcon from '@material-ui/icons/Close';
import { Slide, Box, IconButton } from '@material-ui/core';
import { thunks, select, actions } from 'store/toolkit';
import QuickFeedbackForm from '../ChatModal/Feedback/QuickFeedbackForm';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    background: theme.palette.common.white,
    paddingBottom: theme.spacing(1.5),
    margin: `0 ${theme.spacing(1.5)}px`,
  },
  form: {
    position: 'relative',
    margin: 0,
    borderRadius: theme.spacing(1.5),
    padding: theme.spacing(1.5),
    background: theme.palette.grey[100],
  },
  button: {
    right: 0,
    top: 0,
    padding: 6,
    position: 'absolute',
  },
}));

export default function AiSearchQuickFeedback({ isOpen, dismiss }) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const progress = useSelector(select.aiSearch.quickFeedbackProgress);
  const chatKey = useSelector(select.aiSearch.aiSearchKey);

  const setProgress = useCallback(
    (step) => {
      dispatch(actions.aiSearch.setQuickFeedbackProgress(step));
    },
    [dispatch]
  );

  const handleSubmit = useCallback(
    (payload) => {
      dispatch(thunks.chat.submitQuickFeedback({ ...payload, chatKey }));
    },
    [chatKey, dispatch]
  );

  return (
    <Slide direction="up" in={isOpen} mountOnEnter unmountOnExit timeout={400}>
      <Box maxWidth="md" classes={{ root: classes.wrapper }}>
        <Box classes={{ root: classes.form }}>
          <QuickFeedbackForm
            compressed
            dismissTextFeedback={dismiss}
            prompt="How was your overall experience with Embold's AI Search?"
            handleSubmit={handleSubmit}
            progress={progress}
            setProgress={setProgress}
          />
          <IconButton classes={{ root: classes.button }} onClick={dismiss}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
    </Slide>
  );
}

AiSearchQuickFeedback.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  dismiss: PropTypes.func.isRequired,
};
