import React, { useMemo, useCallback } from 'react';
import PropTypes, { ProviderDataShape } from 'propTypes/index';
import { useDispatch, useSelector } from 'react-redux';
import { actions, select } from 'store/toolkit';
import useFocusAnchors from 'utils/FocusRefContext';
import useReturnFocus from 'hooks/useReturnFocus';

import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { ChatProfileButton, ChatProfileIconButton } from './ChatProfileButtons';

export default function ScheduleButton({ provider, isIconButton }) {
  const showScheduleButton = useSelector(select.featureFlags.showScheduleButton);

  const { schedulingUrl } = provider;

  const dispatch = useDispatch();
  const focusAnchors = useFocusAnchors();
  const { ref: callButtonRef, returnFocus: focusCallButton } = useReturnFocus();

  const handleScheduleClick = useCallback(
    (e) => {
      e?.stopPropagation();
      focusAnchors.profileModalReturnFocus = focusCallButton;
      dispatch(actions.ui.openProfileModal({ type: 'call', data: provider }));
    },
    [dispatch, focusAnchors, focusCallButton, provider]
  );

  const linkProps = useMemo(
    () => ({
      target: '_blank',
      rel: 'noreferrer',
      href: schedulingUrl,
    }),
    [schedulingUrl]
  );

  if (!showScheduleButton) return null;

  if (isIconButton) {
    return schedulingUrl ? (
      <ChatProfileIconButton {...linkProps}>
        <CalendarMonthIcon titleAccess="Schedule" />
      </ChatProfileIconButton>
    ) : (
      <ChatProfileIconButton ref={callButtonRef} onClick={handleScheduleClick}>
        <HelpOutlineIcon titleAccess="Need Help?" />
      </ChatProfileIconButton>
    );
  }

  return schedulingUrl ? (
    <ChatProfileButton {...linkProps}>
      <CalendarMonthIcon />
      Schedule
    </ChatProfileButton>
  ) : (
    <ChatProfileButton onClick={handleScheduleClick} ref={callButtonRef}>
      Need Help?
    </ChatProfileButton>
  );
}

ScheduleButton.propTypes = {
  provider: ProviderDataShape.isRequired,
  isIconButton: PropTypes.bool,
};
ScheduleButton.defaultProps = {
  isIconButton: false,
};
