/* eslint-disable no-alert */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Typography, Box, Button, Divider, Container, Link, ButtonGroup } from '@material-ui/core';

import { select } from 'store/toolkit';

import useUpdateTitle from 'hooks/useUpdateTitle';

import FeaturedBenefit from 'components/ModernExperience/Benefit/FeaturedBenefit';
import ShowMoreButton from 'components/ShowMoreButton';
import useToggleState from 'hooks/useToggleState';
import FeaturedBenefitsList from 'components/ModernExperience/Benefit/FeaturedBenefitsList';
import ShowMapButton from 'components/ModernExperience/ShowMapButton';
import PaginationController from 'components/PaginationController';
import { Breadcrumbs } from 'components/ModernExperience/Breadcrumbs/SearchBreadcrumbComponents';
import Pill from 'components/ModernExperience/ResultCards/ProviderCard/Pill';
import ServiceInfoContent from 'components/ModernExperience/ServiceInfoContent';
import ScoreIcon from 'components/ModernExperience/ScoreIcon';
import ScoreTierIcon from 'components/ModernExperience/ScoreTierIcon';
import FocusAreaTypeIcon from 'components/ModernExperience/FocusAreaTypeIcon';
import DynamicFocusAreaText from 'components/ModernExperience/DynamicFocusAreaText';
import Accordion from 'components/ModernExperience/Profile/Accordion';
import EmboldFactsCarousel from 'components/Modals/ChatModal/ChatLoading/EmboldFactsCarousel';
import EmboldAnalysisStepper from 'components/Modals/ChatModal/ChatLoading/EmboldAnalysisStepper';
import ProfileBadge from 'components/ModernExperience/Profile/ProfileBadge';
import Banner from 'components/Banner/Banner';
import LinkToModal from 'components/LinkToModal';
import MarkdownNotification from 'components/MarkdownNotification';
import {
  ERROR,
  INFO,
  SUCCESS,
  VALID_SEVERITIES,
  WARNING,
} from 'store/slices/notifications/notificationsConstants';

import ErrorIcon from '@material-ui/icons/Error';
import emboldLogo from 'assets/embold-logo-transparent.svg';
import aboutPgImage from 'assets/pg-phone.png';

import { SUBSPECIALTY_TYPES, PLACE_TYPE, PROVIDER_TYPE } from 'utils/constants';
import HospitalGradeIcon from 'icons/dynamic/HospitalGradeIcon';
import ExpandingPill from 'components/ExpandingPill';
import SearchSuggestion from 'components/SearchSuggestion/SearchSuggestion';
import QuickFeedbackForm from 'components/Modals/ChatModal/Feedback/QuickFeedbackForm';
import PopupVideo from 'components/PopupVideo';
import StyleguideCanvas from './StyleguideCanvas';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flex: 1,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export default function ModernExperienceStyleguide() {
  const classes = useStyles();
  const isSmDown = useSelector(select.ui.isSmDown);

  const hasCostEstimator = useSelector(select.featureFlags.costEstimatorLink);

  useUpdateTitle('Modern Experience Styleguide', { hidePrefix: true });
  const benefits = useSelector(select.promotions.promotions);
  const exampleBenefit = {
    ...benefits[0],
    // hard code in some of the optional properties to show all UI available
    accessAppLink: 'https://www.emboldhealth.com/',
    accessChatLink: 'https://www.emboldhealth.com/',
    accessEmail: 'https://www.emboldhealth.com/',
    pdfLink: 'https://www.emboldhealth.com/',
    locations: [],
  };

  const [chatAnalysisLoading, toggleChatAnalysisLoading] = useToggleState();
  const [chatLoading, toggleChatLoading] = useToggleState();
  const [showMore, toggleShowMore] = useToggleState();
  const [page, setPage] = useState(0);
  const [isAccordionOpen, setAccordionOpen] = useState(false);
  const [compressChatQuickFeedback, setCompressChatQuickFeedback] = useState(false);

  const [progress, setProgress] = useState(0);

  return (
    <div className={classes.root}>
      <Typography variant="h1">Modern Experience Styleguide</Typography>

      <StyleguideCanvas name="Chat quick feedback">
        <Container maxWidth="md">
          <Box align="center">
            <ButtonGroup variant="outlined">
              <Button onClick={() => setProgress(0)}>Reset</Button>
              <Button onClick={() => setCompressChatQuickFeedback((p) => !p)}>
                View {compressChatQuickFeedback ? 'default' : 'compressed'} variant
              </Button>
            </ButtonGroup>
          </Box>
          <Divider />
          <QuickFeedbackForm
            prompt="How did you like this AI powered experience?"
            progress={progress}
            setProgress={setProgress}
            handleSubmit={() => {}}
            compressed={compressChatQuickFeedback}
            dismissTextFeedback={compressChatQuickFeedback ? () => {} : undefined}
          />
        </Container>
      </StyleguideCanvas>

      <StyleguideCanvas name="Search appendix modal">
        <LinkToModal to={`/specialties/${PROVIDER_TYPE}`}>
          Open &quot;provider specialties&quot; appendix modal
        </LinkToModal>
        <Divider />
        <LinkToModal to={`/specialties/${PLACE_TYPE}`}>
          Open &quot;facility types&quot; appendix modal
        </LinkToModal>
      </StyleguideCanvas>

      <StyleguideCanvas
        name="Chat Loading State"
        description="When the `askEva` api call is pending for more than 3 seconds, the 'Embold fact carousel' will begin, and will cycle through facts every 8 seconds. It will pause on hover, and can be paused onClick. When the loading state resolves, the fact carousel will collapse, unless it has been manually paused, in which case it can be manually collapsed."
      >
        <Button variant="contained" onClick={toggleChatLoading}>
          Toggle loading: &quot;{chatLoading.toString()}&quot;
        </Button>
        <div style={{ padding: 20 }}>
          <EmboldFactsCarousel scrollToBottom={() => {}} sendingChatMessage={chatLoading} />
        </div>
      </StyleguideCanvas>

      <StyleguideCanvas
        name="Chat Analysis Loading State"
        description="When the `analyzeChat` endpoint is pending, this loading state will appear. When loading is complete, the third line item will fill and the UI will collapse."
      >
        <Button variant="contained" onClick={toggleChatAnalysisLoading}>
          Toggle loading: &quot;{chatAnalysisLoading.toString()}&quot;
        </Button>
        <div style={{ padding: 20 }}>
          <EmboldAnalysisStepper scrollToBottom={() => {}} loading={chatAnalysisLoading} />
        </div>
      </StyleguideCanvas>

      <StyleguideCanvas
        name="SearchBreadcrumbs"
        description={`
        The <SearchBreadcrumbs /> component is used to display the number of results visible, and the total number of results above the results list.
        `}
      >
        <Breadcrumbs visible={50} total={555} searchTerm="Example search term" onClick={() => {}} />
        <br />
        <Breadcrumbs
          visible={50}
          total={555}
          searchTerm="With parent specialty"
          parentSpecialty={{ specialtyName: 'Parent specialty' }}
          onClick={() => {}}
        />
      </StyleguideCanvas>

      <StyleguideCanvas
        name="Show Map Button"
        display="row"
        justify="evenly"
        description={`
      This button has a different design for mobile compared to desktop. This is controlled by the isMobile prop.
      On desktop it uses a standard MUI button. On mobile it uses the MUI Fab. 
      On desktop it says "Show" or "Hide" map. On mobile it says "Show Map" or "Show List"
      `}
      >
        <Box>
          <Typography>Mobile</Typography>
          <ShowMapButton isMobile />
        </Box>
        <Box>
          <Typography>Desktop</Typography>
          <ShowMapButton />
        </Box>
      </StyleguideCanvas>

      <StyleguideCanvas
        name="Show More Button"
        display="row"
        justify="evenly"
        showContainerOptions
        description={`
        This is intended to be a reusable component for controlling <Collapse /> components. 
        It provides some default text along with animation on the Chevron icon.
        `}
      >
        <ShowMoreButton
          isOpen={!showMore}
          onClick={toggleShowMore}
          ariaControls="test-id-1"
          ariaLabel="test 1"
        />
        <ShowMoreButton
          isOpen={!showMore}
          onClick={toggleShowMore}
          variant="contained"
          color="primary"
          ariaControls="test-id-2"
          ariaLabel="test 2"
        />
        <ShowMoreButton
          isOpen={!showMore}
          onClick={toggleShowMore}
          variant="outlined"
          ariaControls="test-id-3"
          ariaLabel="test 3"
        />
        <ShowMoreButton
          isOpen={!showMore}
          onClick={toggleShowMore}
          showMoreText="Custom Show Me More Text"
          showLessText="Custom Show Me Less Text"
          ariaControls="test-id-4"
          ariaLabel="test 4"
        />
        <ShowMoreButton
          isOpen={!showMore}
          onClick={toggleShowMore}
          showMoreText="Custom IconProps"
          showLessText="Custom IconProps"
          ariaControls="test-id-5"
          ariaLabel="test 5"
          IconProps={{ color: 'secondary' }}
        />
      </StyleguideCanvas>

      <StyleguideCanvas
        name="Pagination Controller"
        description={`
      The Pagination controller is a simple set of controls for moving and index up or down with a prop for the upper limit (length).
      `}
      >
        <PaginationController index={page} length={5} setIndex={setPage} label="Thing" />
      </StyleguideCanvas>

      <StyleguideCanvas name="ExpandingPill">
        <pre>variant: desktop</pre>
        <ExpandingPill
          label="Label Prop"
          labelIcon={<ErrorIcon />}
          title="I'm a title"
          variant="desktop"
        >
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Veritatis optio quas eaque amet
          dignissimos labore tempora, ad ipsam aspernatur a, aut quisquam. Adipisci ab, excepturi
          quibusdam alias doloremque odio aut.
        </ExpandingPill>
        <Box maxWidth={370}>
          <pre>variant: mobile</pre>
          <ExpandingPill
            label="Label Prop"
            labelIcon={<ErrorIcon />}
            title="I'm a title"
            variant="mobile"
          >
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Veritatis optio quas eaque
            amet dignissimos labore tempora, ad ipsam aspernatur a, aut quisquam. Adipisci ab,
            excepturi quibusdam alias doloremque odio aut.
          </ExpandingPill>
        </Box>
      </StyleguideCanvas>

      {Boolean(benefits.length) && (
        <StyleguideCanvas
          name="Featured Benefit"
          display="col"
          description={`
         The FeaturedBenefit component is used to display a preview of given benefit, with the option to see the full details using a ShowMore button.
         This component has a listItem prop that should be used when rendering a list of benefits.
         The isMobile prop determines the layout that elements are rendered in. This was done using a prop rather that a breakpoint to give the parent component control over how to render this component.
         `}
        >
          <Typography>Desktop</Typography>
          <FeaturedBenefit benefit={exampleBenefit} headingLevel="h3" />
          <br />
          <Typography>Mobile</Typography>
          <Box maxWidth={370}>
            <FeaturedBenefit benefit={exampleBenefit} isMobile />
          </Box>
        </StyleguideCanvas>
      )}

      <StyleguideCanvas
        name="Featured Benefits List"
        description={`
        The FeatureBenefitsList is responsible for selecting the relevant benefits from Redux, and then rendering them using the FeaturedPromotion component in a list.
        This component also dictates the breakpoint at which the FeaturedPromotion switches from desktop to mobile view.
        For the time being, this list will default to only 1 benefit. But you can optionally include a higher limit with  limit={10}. @TODO, this needs to be removed and handled by a selector in the future.
      `}
      >
        <FeaturedBenefitsList limit={3} />
      </StyleguideCanvas>
      <StyleguideCanvas
        name="Service Info Component"
        description="The ServiceInfo Component renders when Cost Estimator Link and Show Service Cost"
      >
        <Box maxWidth={900}>
          <Typography>Desktop</Typography>
          <ServiceInfoContent
            mobileView={isSmDown}
            fullWidthServiceDescription={!hasCostEstimator}
            service={{
              id: 2679,
              serviceId: 51,
              serviceName: 'Colonoscopy',
              state: 'TN',
              specialtyId: 6,
              specialtyName: 'Gastroenterology',
              subspecialtyId: 9,
              subspecialtyName: 'Endoscopy',
              descriptionPlainLanguage:
                'Colonoscopy is a medical procedure used to examine the inside of the large intestine and rectum to detect abnormalities like polyps, tumors or inflammation. It involves using a thin, flexible tube with a camera and light at the end to examine the colon.',
              professionalLow: 60,
              professionalAverage: 640,
              professionalHigh: 4852,
              facilityLow: 305,
              facilityAverage: 1267,
              facilityHigh: 4217,
            }}
          />
        </Box>
        <br />

        <Box maxWidth={370}>
          <Typography>Mobile</Typography>
          <ServiceInfoContent
            mobileView
            fullWidthServiceDescription={!hasCostEstimator}
            service={{
              id: 2679,
              serviceId: 51,
              serviceName: 'Colonoscopy',
              state: 'TN',
              specialtyId: 6,
              specialtyName: 'Gastroenterology',
              subspecialtyId: 9,
              subspecialtyName: 'Endoscopy',
              descriptionPlainLanguage:
                'Colonoscopy is a medical procedure used to examine the inside of the large intestine and rectum to detect abnormalities like polyps, tumors or inflammation. It involves using a thin, flexible tube with a camera and light at the end to examine the colon.',
              professionalLow: 60,
              professionalAverage: 640,
              professionalHigh: 4852,
              facilityLow: 305,
              facilityAverage: 1267,
              facilityHigh: 4217,
            }}
          />
        </Box>
      </StyleguideCanvas>

      <StyleguideCanvas name="SearchSuggestion">
        <pre>variant: desktop</pre>
        <SearchSuggestion
          title="Try searching for somthing else"
          suggestion={{ specialtyId: 5, name: 'Primary Care' }}
          variant="desktop"
        >
          <Typography gutterBottom>
            This is where you tell a user why they should try this other search that you are
            suggesting
          </Typography>
          <Typography paragraph>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque perspiciatis voluptatibus
            ratione at eos eveniet magnam repellendus quos est repudiandae nam libero id deserunt
            fuga facilis, iusto eligendi suscipit ullam.
          </Typography>
        </SearchSuggestion>

        <Box maxWidth={370}>
          <pre>variant: mobile</pre>
          <SearchSuggestion
            title="Try searching for somthing else"
            suggestion={{ specialtyId: 5, name: 'Primary Care' }}
            variant="mobile"
          >
            <Typography gutterBottom>
              This is where you tell a user why they should try this other search that you are
              suggesting
            </Typography>
            <Typography paragraph>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque perspiciatis
              voluptatibus ratione at eos eveniet magnam repellendus quos est repudiandae nam libero
              id deserunt fuga facilis, iusto eligendi suscipit ullam.
            </Typography>
          </SearchSuggestion>
        </Box>
      </StyleguideCanvas>

      <StyleguideCanvas
        name="Pill"
        display="row"
        justify="evenly"
        showContainerOptions
        description={`Display "Pills" with an optional tooltip`}
      >
        <Pill
          label="Img icon with iconUrl prop"
          iconUrl={emboldLogo}
          color="primary.main"
          TooltipProps={{
            message:
              'The icon is set with a string url passed to iconUrl. Example: iconUrl="http://icons.myicon.con/123"',
          }}
        />
        <Pill
          label="SVG Icon with icon prop"
          icon={<ErrorIcon />}
          color="warning.main"
          TooltipProps={{
            message: 'The icon is set with the icon prop. Example icon={<MyIcon />}',
          }}
        />
        <Pill
          label="No icon"
          color="info.main"
          TooltipProps={{
            message: "Here's a basic tooltip message",
          }}
        />
        <Pill
          label="Color as a hex value"
          color="#c0c0c0"
          TooltipProps={{
            message: "You can pass a hex value string like '#c0c0c0'",
          }}
        />
        <Pill
          label="Color as a palette property"
          color="error.main"
          TooltipProps={{
            message:
              "You can pass a string that contains the path to a theme color like 'error.main'",
          }}
        />
        <Pill
          label="Default color"
          TooltipProps={{
            message: "When no color is provided, it defaults to the theme's primary color",
          }}
          tooltipMessage="When no color is provided, it defaults to the theme's primary color"
        />
        <Pill
          label="Inverted"
          inverted
          TooltipProps={{
            message:
              'When the inverted prop is passed, the color is used for the label text and the border. The background is set to white and the tooltip icon is outlined',
          }}
        />
        <Pill label="No Tooltip" />
        <Pill label="No Tooltip Inverted" inverted />
      </StyleguideCanvas>
      <StyleguideCanvas
        name="Badge"
        display="row"
        justify="evenly"
        showContainerOptions
        description="Display Badge with an optional tooltip. The Profile's equivalent of the pill"
      >
        <ProfileBadge
          label="With iconUrl"
          iconUrl={emboldLogo}
          color="primary.main"
          TooltipProps={{
            message:
              'The icon is set with a string url passed to iconUrl. Example: iconUrl="http://icons.myicon.con/123"',
          }}
        />
        <ProfileBadge
          label="With iconUrl inverted"
          inverted
          iconUrl={emboldLogo}
          color="primary.main"
          TooltipProps={{
            message:
              'The icon is set with a string url passed to iconUrl. Example: iconUrl="http://icons.myicon.con/123"',
          }}
        />
        <ProfileBadge
          label="With icon"
          icon={<ErrorIcon />}
          color="warning.main"
          TooltipProps={{
            message: 'The icon is set with the icon prop. Example icon={<MyIcon />}',
          }}
        />
        <ProfileBadge
          label="With icon inverted"
          inverted
          icon={<ErrorIcon />}
          color="warning.main"
          TooltipProps={{
            message: 'The icon is set with the icon prop. Example icon={<MyIcon />}',
          }}
        />
        <ProfileBadge
          label="No icon"
          color="info.main"
          TooltipProps={{
            message: "Here's a basic tooltip message",
          }}
        />
        <ProfileBadge
          label="Text as icon"
          icon="SP"
          TooltipProps={{
            message: 'You can pass text as the icon like this icon="SP"',
          }}
        />
        <ProfileBadge
          label="Text as icon inverted"
          inverted
          icon="SP"
          TooltipProps={{
            message: 'You can pass text as the icon like this icon="SP"',
          }}
        />
        <ProfileBadge
          label="square variant avatar"
          icon={<HospitalGradeIcon grade="a" />}
          color="grey.100"
          TooltipProps={{
            message: 'You can pass props to the Avatar component with AvatarProps',
          }}
          AvatarProps={{ variant: 'square' }}
        />
        <ProfileBadge label="No Tooltip" />
      </StyleguideCanvas>
      <StyleguideCanvas
        name="DynamicFocusAreaText"
        display="row"
        justify="evenly"
        showContainerOptions
        description="DynamicFocusAreaText is used to display a subspecialty name. When the isActive prop is passed, the subspecialty will be bolded. If there is explanatory text available, it will also be clickable to open a tooltip"
      >
        <DynamicFocusAreaText
          subspecialty={{
            subspecialtyId: 1,
            subspecialtyName: 'Inactive Subspecialty',
            subspecialtyType: SUBSPECIALTY_TYPES.HIGH_VOLUME,
          }}
        />
        <DynamicFocusAreaText
          subspecialty={{
            subspecialtyId: 1,
            subspecialtyName: 'High Scoring',
            subspecialtyScore: 95,
            subspecialtyScoreTier: 1,
            subspecialtyType: SUBSPECIALTY_TYPES.SCORED,
          }}
          isActive
        />
        <DynamicFocusAreaText
          subspecialty={{
            subspecialtyId: 1,
            subspecialtyName: 'Medium Scoring',
            subspecialtyScore: 85,
            subspecialtyScoreTier: 2,
            subspecialtyType: SUBSPECIALTY_TYPES.SCORED,
          }}
          isActive
        />
        <DynamicFocusAreaText
          subspecialty={{
            subspecialtyId: 1,
            subspecialtyName: 'Low Scoring',
            subspecialtyScore: 55,
            subspecialtyScoreTier: 3,
            subspecialtyType: SUBSPECIALTY_TYPES.SCORED,
          }}
          isActive
        />
        <DynamicFocusAreaText
          subspecialty={{
            subspecialtyId: 1,
            subspecialtyName: 'High Volume',
            subspecialtyType: SUBSPECIALTY_TYPES.HIGH_VOLUME,
          }}
          isActive
        />
        <DynamicFocusAreaText
          subspecialty={{
            subspecialtyId: 1,
            subspecialtyName: 'Self Identified',
            subspecialtyType: SUBSPECIALTY_TYPES.HIGH_VOLUME,
          }}
          isActive
        />
      </StyleguideCanvas>
      <StyleguideCanvas name="ScoreIcon" display="row" justify="evenly" showContainerOptions>
        <ScoreIcon score={100} tier={1} />
        <ScoreIcon score={85} tier={2} />
        <ScoreIcon score={75} tier={3} />
        <ScoreIcon score={90} tier={1} />
      </StyleguideCanvas>
      <StyleguideCanvas name="ScoreTierIcon" display="row" justify="evenly" showContainerOptions>
        <ScoreTierIcon tier={1} />
        <ScoreTierIcon tier={2} />
        <ScoreTierIcon tier={3} />
      </StyleguideCanvas>
      <StyleguideCanvas name="FocusAreaTypeIcon">
        {[24, 36, 50].map((size) => (
          <div key={size} style={{ display: 'flex', columnGap: 10 }}>
            <pre>size = {size}</pre>
            <FocusAreaTypeIcon
              subspecialty={{
                subspecialtyId: 1,
                subspecialtyType: SUBSPECIALTY_TYPES.SCORED,
                subspecialtyScore: 100,
                subspecialtyScoreTier: 1,
                subspecialtyName: 'Test Subspecialty',
              }}
              size={size}
            />
            <FocusAreaTypeIcon
              subspecialty={{
                subspecialtyId: 2,
                subspecialtyType: SUBSPECIALTY_TYPES.SCORED,
                subspecialtyScore: 85,
                subspecialtyScoreTier: 2,
                subspecialtyName: 'Test Subspecialty',
              }}
              size={size}
            />
            <FocusAreaTypeIcon
              subspecialty={{
                subspecialtyId: 3,
                subspecialtyType: SUBSPECIALTY_TYPES.SCORED,
                subspecialtyScore: 75,
                subspecialtyScoreTier: 3,
                subspecialtyName: 'Test Subspecialty',
              }}
              size={size}
            />
            <FocusAreaTypeIcon
              subspecialty={{
                subspecialtyId: 2202,
                subspecialtyType: SUBSPECIALTY_TYPES.HIGH_VOLUME,
                subspecialtyName: 'Test Subspecialty',
              }}
              size={size}
            />
          </div>
        ))}
      </StyleguideCanvas>
      <StyleguideCanvas name="Accordion">
        <Accordion title="No Content" />
        <Accordion title="With Content">Here is the content inside the accordion</Accordion>
        <Accordion title="With Icon" icon={<ScoreIcon score={85} tier={1} />}>
          Here is the content inside the accordion
        </Accordion>
        <br />
        <Typography>As List Items</Typography>
        <Accordion isListItem title="No Content" />
        <Accordion isListItem title="With Content">
          Here is the content inside the accordion
        </Accordion>
        <Accordion isListItem title="With Icon" icon={<ScoreIcon score={85} tier={1} />}>
          Here is the content inside the accordion
        </Accordion>
        <Accordion initialOpenState title="Open initially">
          This accordion is open by default
        </Accordion>
        <Accordion
          isOpen={isAccordionOpen}
          title="Controlled Accordion"
          onClick={() => {
            setAccordionOpen((prev) => !prev);
            alert('Accordion clicked');
          }}
        >
          Managed with external state
        </Accordion>
      </StyleguideCanvas>

      <StyleguideCanvas
        name="Banner"
        canvasStyle={{ display: 'flex', gap: 5, justifyContent: 'left' }}
      >
        <Typography variant="h3">variant=gradient (default)</Typography>
        <Banner>color=primary</Banner>
        <Banner color="secondary">color=secondary</Banner>
        <Banner color="tertiary" icon={WARNING}>
          color=tertiary, icon=warning
        </Banner>
        <Banner color="info" onClose={() => alert('I closed')}>
          color=info, onClose function added
        </Banner>
        <Banner color="success" showCloseButton={false}>
          color=success, showCloseButton=false
        </Banner>
        <Banner color="error">color=error</Banner>
        <Divider />
        <Typography variant="h3">styleVariant=solid</Typography>
        <Banner styleVariant="solid" color="info" icon={INFO}>
          color=info, icon=info
        </Banner>
        <Banner styleVariant="solid" color="success" icon={SUCCESS}>
          color=success, icon=success
        </Banner>
        <Banner styleVariant="solid" color="tertiary" icon={WARNING}>
          color=tertiary, icon=warning
        </Banner>
        <Banner styleVariant="solid" color="error" icon={ERROR}>
          color=error, icon=error
        </Banner>
      </StyleguideCanvas>

      <StyleguideCanvas name="PopupVideo">
        <pre>Using the MUI Button</pre>
        <PopupVideo
          ButtonComponent={Button}
          variant="contained"
          color="primary"
          poster="https://assets.codepen.io/32795/poster.png"
          sources={[
            { src: 'http://media.w3.org/2010/05/sintel/trailer.webm', type: 'video/webm' },
            { src: 'http://media.w3.org/2010/05/sintel/trailer.mp4', type: 'video/mp4' },
          ]}
          tracks={[]}
          title="Sintel Video"
        >
          Open Video
        </PopupVideo>

        <pre>Using the MUI Link as a button</pre>
        <PopupVideo
          ButtonComponent={Link}
          component="button"
          poster="https://assets.codepen.io/32795/poster.png"
          sources={[
            { src: 'http://media.w3.org/2010/05/sintel/trailer.webm', type: 'video/webm' },
            { src: 'http://media.w3.org/2010/05/sintel/trailer.mp4', type: 'video/mp4' },
          ]}
          tracks={[]}
          title="Sintel Video"
        >
          Open Video
        </PopupVideo>

        <pre>Using an img as the button</pre>
        <PopupVideo
          poster="https://assets.codepen.io/32795/poster.png"
          sources={[
            { src: 'http://media.w3.org/2010/05/sintel/trailer.webm', type: 'video/webm' },
            { src: 'http://media.w3.org/2010/05/sintel/trailer.mp4', type: 'video/mp4' },
          ]}
          tracks={[]}
          title="Sintel Video"
        >
          <img src={aboutPgImage} alt="Open Sintel video player" width={150} />
        </PopupVideo>
        <pre>With autoplay</pre>
        <PopupVideo
          ButtonComponent={Link}
          component="button"
          poster="https://assets.codepen.io/32795/poster.png"
          sources={[
            { src: 'http://media.w3.org/2010/05/sintel/trailer.webm', type: 'video/webm' },
            { src: 'http://media.w3.org/2010/05/sintel/trailer.mp4', type: 'video/mp4' },
          ]}
          tracks={[]}
          title="Sintel Video"
          autoPlay
        >
          Open And Autoplay
        </PopupVideo>
        <br />
        <PopupVideo
          ButtonComponent={Link}
          component="button"
          poster="https://assets.codepen.io/32795/poster.png"
          sources={[
            { src: 'http://media.w3.org/2010/05/sintel/trailer.webm', type: 'video/webm' },
            { src: 'http://media.w3.org/2010/05/sintel/trailer.mp4', type: 'video/mp4' },
          ]}
          tracks={[]}
          title="Sintel Video"
          openInFullscreen
          autoPlay
        >
          Open In Fullscreen
        </PopupVideo>
      </StyleguideCanvas>

      <StyleguideCanvas name="MarkdownNotification" showContainerOptions>
        {['standard', 'outlined', 'filled'].map((variant) => (
          <React.Fragment key={variant}>
            {VALID_SEVERITIES.map((sev) => (
              <MarkdownNotification
                key={sev}
                variant={variant}
                severity={sev}
                style={{ margin: 4 }}
                content={`# Markdown Notification! 
Here Fusion can send some markdown text like [an internal link](/styleguide/icons), [an external link](https://www.emboldhealth.com) **bold text**, _"a quote"_, or a list.
`}
              />
            ))}
          </React.Fragment>
        ))}
      </StyleguideCanvas>

      <StyleguideCanvas name="PopupVideo">
        <pre>Using the MUI Button</pre>
        <PopupVideo
          ButtonComponent={Button}
          variant="contained"
          color="primary"
          poster="https://assets.codepen.io/32795/poster.png"
          sources={[
            { src: 'http://media.w3.org/2010/05/sintel/trailer.webm', type: 'video/webm' },
            { src: 'http://media.w3.org/2010/05/sintel/trailer.mp4', type: 'video/mp4' },
          ]}
          tracks={[]}
          title="Sintel Video"
        >
          Open Video
        </PopupVideo>

        <pre>Using the MUI Link as a button</pre>
        <PopupVideo
          ButtonComponent={Link}
          component="button"
          poster="https://assets.codepen.io/32795/poster.png"
          sources={[
            { src: 'http://media.w3.org/2010/05/sintel/trailer.webm', type: 'video/webm' },
            { src: 'http://media.w3.org/2010/05/sintel/trailer.mp4', type: 'video/mp4' },
          ]}
          tracks={[]}
          title="Sintel Video"
        >
          Open Video
        </PopupVideo>

        <pre>Using an img as the button</pre>
        <PopupVideo
          poster="https://assets.codepen.io/32795/poster.png"
          sources={[
            { src: 'http://media.w3.org/2010/05/sintel/trailer.webm', type: 'video/webm' },
            { src: 'http://media.w3.org/2010/05/sintel/trailer.mp4', type: 'video/mp4' },
          ]}
          tracks={[]}
          title="Sintel Video"
        >
          <img src={aboutPgImage} alt="Open Sintel video player" width={150} />
        </PopupVideo>
        <pre>With autoplay</pre>
        <PopupVideo
          ButtonComponent={Link}
          component="button"
          poster="https://assets.codepen.io/32795/poster.png"
          sources={[
            { src: 'http://media.w3.org/2010/05/sintel/trailer.webm', type: 'video/webm' },
            { src: 'http://media.w3.org/2010/05/sintel/trailer.mp4', type: 'video/mp4' },
          ]}
          tracks={[]}
          title="Sintel Video"
          autoPlay
        >
          Open And Autoplay
        </PopupVideo>
        <br />
        <PopupVideo
          ButtonComponent={Link}
          component="button"
          poster="https://assets.codepen.io/32795/poster.png"
          sources={[
            { src: 'http://media.w3.org/2010/05/sintel/trailer.webm', type: 'video/webm' },
            { src: 'http://media.w3.org/2010/05/sintel/trailer.mp4', type: 'video/mp4' },
          ]}
          tracks={[]}
          title="Sintel Video"
          openInFullscreen
          autoPlay
        >
          Open In Fullscreen
        </PopupVideo>
      </StyleguideCanvas>

      <StyleguideCanvas name="MarkdownNotification" showContainerOptions>
        {['standard', 'outlined', 'filled'].map((variant) => (
          <React.Fragment key={variant}>
            {['info', 'success', 'warning', 'error'].map((sev) => (
              <MarkdownNotification
                key={sev}
                variant={variant}
                severity={sev}
                style={{ margin: 4 }}
                content={`# Markdown Notification! 
Here Fusion can send some markdown text like [an internal link](/styleguide/icons), [an external link](https://www.emboldhealth.com) **bold text**, _"a quote"_, or a list.
`}
              />
            ))}
          </React.Fragment>
        ))}
      </StyleguideCanvas>
    </div>
  );
}
