import React, { useCallback, useMemo } from 'react';
import { noop } from 'lodash';
import PropTypes, { ButtonColorPropType, ChildrenType } from 'propTypes/index';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Divider } from '@material-ui/core';

import PopoverChip from './PopoverChip';

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: `${theme.spacing(2)}px 0px`,
  },
  clearButton: {
    width: '100%',
  },
}));

function MenuPopoverChip({
  values,
  options,
  disabled,
  color,
  onClear,
  label,
  children,
  // any additional PopoverChip props can be passed here
  ...props
}) {
  const classes = useStyles();
  const clearButtonAriaLabel = `Clear all ${label} selections`;

  const handleClearClick = useCallback(() => {
    onClear();
  }, [onClear]);

  const handleClose = useCallback(() => noop, []);
  const isActive = values.length > 0;

  const currentValueLabels = useMemo(
    () => values.map((value) => options.find((option) => option.value === value).label),
    [options, values]
  );

  const buttonLabel = useMemo(() => {
    const valueCount = values.length;
    const firstValueLabel = currentValueLabels[0];

    if (!valueCount || !firstValueLabel) {
      return label;
    }

    if (valueCount === 1) {
      return firstValueLabel;
    }

    return `${firstValueLabel}, + ${valueCount - 1}`;
  }, [currentValueLabels, label, values.length]);

  const buttonAriaLabel = useMemo(() => {
    if (values.length === 0) {
      return label;
    }

    return `${label}. Current selections: ${currentValueLabels}`;
  }, [currentValueLabels, label, values.length]);

  const chipProps = useMemo(
    () => ({
      color: isActive ? color : 'default',
      'aria-label': buttonAriaLabel,
    }),
    [buttonAriaLabel, color, isActive]
  );

  return (
    <PopoverChip
      contentLabel={label}
      buttonLabel={buttonLabel}
      disabled={disabled}
      onClose={handleClose}
      DeleteButtonProps={{ 'aria-label': clearButtonAriaLabel, color }}
      onDelete={isActive ? handleClearClick : null}
      showCloseButton
      {...chipProps}
      {...props}
    >
      {children}
      <Divider classes={{ root: classes.divider }} />
      <Button
        onClick={handleClearClick}
        aria-label={clearButtonAriaLabel}
        color={color}
        classes={{ root: classes.clearButton }}
      >
        Clear
      </Button>
    </PopoverChip>
  );
}

export default MenuPopoverChip;

MenuPopoverChip.propTypes = {
  values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })
  ).isRequired,
  disabled: PropTypes.bool,
  onClear: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  color: ButtonColorPropType,
  children: ChildrenType.isRequired,
};

MenuPopoverChip.defaultProps = {
  disabled: false,
  color: 'primary',
};
