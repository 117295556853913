import React, { useCallback, useState } from 'react';
import PropTypes, { ProviderDataShape } from 'propTypes/index';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Grid, Grow, Typography, Box, Collapse } from '@material-ui/core/';

import { useProviderScore } from 'hooks/Provider/';

import ScoreIcon from 'components/ScoreIcon';
import HighPerformingBadge from 'components/Profile/Badges/HighPerformingBadge';
import ProviderCardLarge from './ProviderCardLarge';
import ChatScoreTierPill from '../ChatScoreTierPill';
import ScheduleButton from './Buttons/ScheduleButton';
import ShareButton from './Buttons/ShareButton';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 8,
    padding: `${theme.spacing(1)}px`,
    cursor: 'pointer',
  },
  cardTitle: {
    lineHeight: 1.2,
    margin: '4px 0',
  },
  titleRow: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiBox-root': {
      marginLeft: theme.spacing(1),
      flex: 1,
      overflow: 'hidden',
      '& .MuiTypography-root': {
        display: 'block',
        marginTop: 0,
      },
    },
  },
  buttonSection: {
    display: 'flex',
    alignItems: 'center',
  },
  scoreTierPill: { marginBottom: 4 },
}));

function ProviderCardSmall({ provider, selectedProvider, setSelectedProvider, index }) {
  const classes = useStyles();
  const smDown = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const [showDetails, setShowDetails] = useState(false);

  const { entityId, entityName, title, specialty, highPerforming } = provider;
  const score = useProviderScore(provider);
  const isSelectedProvider = selectedProvider.entityId === entityId;

  const handleAccordionClick = useCallback(
    (e) => {
      if (!smDown) return;
      e?.stopPropagation();
      setShowDetails((show) => !show);
    },
    [smDown]
  );

  const handleAccordionKeyPress = useCallback(
    (e) => {
      e?.preventDefault();
      e?.stopPropagation();
      if (e?.code === 'Enter' || e?.code === 'Space') handleAccordionClick();
    },
    [handleAccordionClick]
  );

  return (
    <Grid item xs={12} sm={4} md={4} lg={4}>
      <Grow in timeout={index * 500}>
        <Grid
          style={{
            boxShadow: !smDown && isSelectedProvider ? '0px 4px 4px rgba(0, 0, 0, 0.25)' : '',
          }}
          className={classes.container}
          onClick={() => !smDown && setSelectedProvider(provider)}
        >
          <Grid className={classes.scoreTierPill}>
            <ChatScoreTierPill provider={provider} />
          </Grid>
          <Grid className={classes.titleRow} onClick={handleAccordionClick}>
            {score && (
              <div>
                <Typography variant="srOnly">{score.name}</Typography>
                <ScoreIcon score={score.value} coloredText emboldRecommendedSize={30} />
              </div>
            )}

            <Box>
              <Typography
                variant="h3"
                classes={{
                  root: classes.cardTitle,
                }}
              >
                {entityName}
                {Boolean(title) && ` ${title}`}
              </Typography>
              {specialty && (
                <Typography style={{ fontSize: '.9rem', marginTop: '-8px' }}>
                  {specialty}
                </Typography>
              )}
            </Box>
            <Grid style={{ marginRight: 4 }}>
              <HighPerformingBadge isHighPerforming={highPerforming} />
            </Grid>
            {smDown && (
              <Grid className={classes.buttonSection}>
                <ShareButton provider={provider} isIconButton />
                <ScheduleButton isIconButton provider={provider} />
                <ExpandMoreIcon
                  tabIndex="0"
                  role="button"
                  onClick={handleAccordionClick}
                  onKeyPress={handleAccordionKeyPress}
                  titleAccess={`expand details for ${entityName} ${title} ${specialty}`}
                  className={classes.expandMoreIcon}
                  style={{ transform: showDetails ? 'rotate(180deg)' : '' }}
                />
              </Grid>
            )}
          </Grid>
          {smDown && (
            <Grid>
              <Collapse in={showDetails}>
                <ProviderCardLarge provider={provider} />
              </Collapse>
            </Grid>
          )}
        </Grid>
      </Grow>
    </Grid>
  );
}

ProviderCardSmall.propTypes = {
  provider: ProviderDataShape.isRequired,
  selectedProvider: PropTypes.shape({ entityId: PropTypes.string }).isRequired,
  setSelectedProvider: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

export default ProviderCardSmall;
