import React from 'react';
import PropTypes, { AriaLabelRule } from 'propTypes';
import { Box, alpha } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { get } from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    gap: ({ spacing }) => theme.spacing(spacing),
    overflowX: 'auto',
    display: 'flex',
    backgroundColor: ({ backgroundColor }) => get(theme.palette, backgroundColor),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    '& > *': { flexShrink: 0 },

    '& > .fade-provider': {
      // these 2 divs are placed at the start and end to give the appears of a fade, when content is scrolled off-screen
      position: 'sticky',
      top: 0,
      bottom: 0,
      zIndex: 1,
      '&:first-child': {
        width: 10, // a more subtle fade on the left so that it doesn't push the next child element too far
        background: ({ backgroundColor }) =>
          `linear-gradient(90deg, ${get(theme.palette, backgroundColor)}, ${alpha(
            get(theme.palette, backgroundColor),
            0
          )})`,
        left: -2,
        marginRight: ({ spacing }) => -1 * theme.spacing(spacing),
      },
      '&:last-child': {
        width: 20, // a more obvious fade on the right so that it's clear that content is scrollable
        background: ({ backgroundColor }) =>
          `linear-gradient(-90deg, ${get(theme.palette, backgroundColor)}, ${alpha(
            get(theme.palette, backgroundColor),
            0
          )})`,
        right: -2,
      },
    },

    // custom scrollbar colors (not supported in firefox)
    '&::-webkit-scrollbar': {
      height: theme.spacing(1),
    },

    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: ({ scrollBarColor }) => get(theme.palette, scrollBarColor),
      borderRadius: 8,
    },

    '&:focus': {
      outlineOffset: theme.spacing(0.5),
    },
  },
}));

export default function HorizontalScrollingContainer({
  children,
  spacing,
  backgroundColor,
  scrollBarColor,
  disableFade,
  ...props
}) {
  const classes = useStyles({ spacing, backgroundColor, scrollBarColor });

  return (
    <Box classes={classes} tabIndex={0} role="region" {...props}>
      {!disableFade && <div className="fade-provider" />}
      {children}
      {!disableFade && <div className="fade-provider" />}
    </Box>
  );
}

HorizontalScrollingContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  spacing: PropTypes.number,
  backgroundColor: PropTypes.string,
  scrollBarColor: PropTypes.string,
  disableFade: PropTypes.bool,

  // AriaLabelRule is NOT a prop, it's a custom rule that requires an aria-label OR aria-labelledby
  // eslint-disable-next-line react/require-default-props
  AriaLabelRule,
};

HorizontalScrollingContainer.defaultProps = {
  children: null,
  spacing: 1,
  backgroundColor: 'background.paper',
  scrollBarColor: 'grey.500',
  disableFade: false,
};
