import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import { Grid } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { actions, select, thunks } from 'store/toolkit';

import { CARE_CATEGORIES, PLACE_RESULT_TYPE, PROVIDER_RESULT_TYPE } from 'utils/constants';
import LoadingSpinner from 'components/LoadingSpinner';

import { quickSearches } from '../LandingPageSections/TopSearches';

const useStyles = makeStyles(() => ({
  resultTypeButtonGroup: {
    margin: '8px 0px',
  },
}));

export default function PopulateDataToggle() {
  const classes = useStyles();
  const loadingClientConfig = useSelector(select.config.isLoading);
  const resultsLoading = useSelector(select.results.isLoading);

  const dispatch = useDispatch();
  const defaultLocation = useSelector(select.networks.defaultLocation);

  const [resultCardType, setResultCardType] = useState(PROVIDER_RESULT_TYPE);
  const handleResultTypeClick = (e, resultType) => {
    setResultCardType(resultType);
  };

  useEffect(() => {
    // wait for config(s) to load
    if (!loadingClientConfig) {
      if (resultCardType === PROVIDER_RESULT_TYPE) {
        // set location
        dispatch(actions.location.setDefaultLocation({ defaultLocation }));
        // perform a "top search" to populate data
        dispatch(thunks.results.executeTopSearch(quickSearches[1].payload));
      } else {
        const suggestion = {
          id: '1234',
          specialtyId: 302,
        };
        dispatch(actions.search.changeType(CARE_CATEGORIES.FACILITY_TYPE));
        dispatch(
          actions.search.suggestionSelected({
            suggestion,
          })
        );
        dispatch(thunks.results.executeSearch());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultCardType, loadingClientConfig]);

  if (resultsLoading) return <LoadingSpinner loading />;

  return (
    <Grid container justifyContent="center">
      <ToggleButtonGroup
        exclusive
        className={classes.resultTypeButtonGroup}
        value={resultCardType}
        onChange={handleResultTypeClick}
      >
        <ToggleButton value={PROVIDER_RESULT_TYPE}>Provider</ToggleButton>
        <ToggleButton value={PLACE_RESULT_TYPE}>Place</ToggleButton>
      </ToggleButtonGroup>
    </Grid>
  );
}
