import React from 'react';

import MultiAutocomplete from 'components/ModernExperience/Search/Autocomplete/MultiAutocomplete';
import useSubmitSearchForm from 'hooks/useSubmitSearchForm';
import useRestoreSearchSlice from 'hooks/useRestoreSearchSlice';

import MobileSearchPageTemplate from './MobileSearchPageTemplate';

function MobileUpdateSearchPage() {
  const submitSearch = useSubmitSearchForm();
  const restoreSearchSlice = useRestoreSearchSlice();

  return (
    <MobileSearchPageTemplate title="Update Search" onClose={restoreSearchSlice}>
      <form style={{ margin: '0 10px' }} onSubmit={submitSearch}>
        <MultiAutocomplete mobileView onSubmit={submitSearch} forceOpen />
      </form>
    </MobileSearchPageTemplate>
  );
}

export default MobileUpdateSearchPage;
