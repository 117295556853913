import React, { useCallback } from 'react';
import PropTypes, { ProviderDataShape } from 'propTypes/index';
import { useDispatch, useSelector } from 'react-redux';
import { actions, select } from 'store/toolkit';
import useFocusAnchors from 'utils/FocusRefContext';
import useReturnFocus from 'hooks/useReturnFocus';

import ShareIcon from '@material-ui/icons/Share';
import { ChatProfileButton, ChatProfileIconButton } from './ChatProfileButtons';

export default function ShareButton({ provider, isIconButton }) {
  const dispatch = useDispatch();
  const focusAnchors = useFocusAnchors();
  const { ref: shareButtonRef, returnFocus: focusShareButton } = useReturnFocus();

  const locationInput = useSelector(select.chat.locationInput);
  const coords = useSelector(select.chat.coords);

  const showShare = useSelector(select.featureFlags.showShare);

  const handleShareClick = useCallback(
    (e) => {
      e?.stopPropagation();
      focusAnchors.profileModalReturnFocus = focusShareButton;
      dispatch(
        actions.ui.openShareModal({
          results: [provider],
          location: { coords, locationInput },
        })
      );
    },
    [coords, dispatch, focusAnchors, focusShareButton, locationInput, provider]
  );

  if (!showShare) return null;

  return isIconButton ? (
    <ChatProfileIconButton ref={shareButtonRef} onClick={handleShareClick}>
      <ShareIcon titleAccess="Share" />
    </ChatProfileIconButton>
  ) : (
    <ChatProfileButton onClick={handleShareClick} ref={shareButtonRef}>
      <ShareIcon />
      Share
    </ChatProfileButton>
  );
}

ShareButton.propTypes = {
  provider: ProviderDataShape.isRequired,
  isIconButton: PropTypes.bool,
};

ShareButton.defaultProps = {
  isIconButton: false,
};
