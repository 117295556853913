import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

import { actions, select } from 'store/toolkit';
import useFocusAnchors from 'utils/FocusRefContext';
import { useHistory } from 'react-router-dom';
import { MOBILE_UPDATE_LOCATION_ROUTE } from 'navigation/navigationConstants';
import GeolocateButton from './GeolocateButton';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 0,
    marginBottom: 20,
  },
}));

function LocationDummy() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { locationDummy } = useFocusAnchors();
  const classes = useStyles();

  const locationInput = useSelector(select.location.locationInput);

  return (
    <TextField
      id="location-search-dummy"
      placeholder="Address, city, or zip code"
      value={locationInput}
      variant="outlined"
      margin="dense"
      fullWidth
      inputRef={locationDummy}
      onClick={() => {
        history.push(MOBILE_UPDATE_LOCATION_ROUTE);
      }}
      onChange={({ target: { value } }) => {
        history.push(MOBILE_UPDATE_LOCATION_ROUTE);
        dispatch(actions.location.setLocationInput(value));
      }}
      classes={{ root: `${classes.root} rounded` }}
      InputProps={{
        classes: { root: classes.input },
        startAdornment: (
          <InputAdornment
            position="start"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <GeolocateButton />
          </InputAdornment>
        ),
      }}
    />
  );
}

export default LocationDummy;
