import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Collapse, Typography, Button } from '@material-ui/core';

import {
  CHAT_BANNER_VARIANT,
  MARKDOWN_BANNER_VARIANT,
  TEXT_BANNER_VARIANT,
  VARIANT_SOLID,
} from 'store/slices/banner/bannerConstants';

import EmboldMarkdown from 'components/EmboldMarkdown';
import useAppBanner from 'hooks/useAppBanner';
import { VirtualAssistant } from 'icons/index';
import { select, actions } from 'store/toolkit';
import { INFO } from 'store/slices/notifications/notificationsConstants';
import Banner from './Banner';

function AppBannerVariant({ ...props }) {
  const variant = useSelector(select.banner.variant);
  const text = useSelector(select.banner.text);
  const color = useSelector(select.banner.color);

  const dispatch = useDispatch();

  const handleChatClick = useCallback(() => dispatch(actions.ui.openModal('chat')), [dispatch]);

  switch (variant) {
    case MARKDOWN_BANNER_VARIANT:
      return (
        <Banner color={color} styleVariant={VARIANT_SOLID} icon={INFO} {...props}>
          <EmboldMarkdown markdownProps={{ components: { p: 'span' } }}>{text}</EmboldMarkdown>
        </Banner>
      );
    case TEXT_BANNER_VARIANT:
      return (
        <Banner color={color} {...props}>
          <Typography variant="h6" component="span">
            {text}
          </Typography>
        </Banner>
      );
    case CHAT_BANNER_VARIANT:
      return (
        <Banner color={color} {...props}>
          <Button startIcon={<VirtualAssistant />} onClick={handleChatClick}>
            {text}
          </Button>
        </Banner>
      );
    default:
      return null;
  }
}

AppBannerVariant.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default function AppBanner({ ...props }) {
  const showBanner = useSelector(select.banner.showBanner);
  const { dismissBanner } = useAppBanner();

  return (
    <Collapse in={showBanner} timeout={500} unmountOnExit>
      <AppBannerVariant onClose={dismissBanner} {...props} />
    </Collapse>
  );
}
