import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography, Grid } from '@material-ui/core';
import LeftArrowIcon from '@material-ui/icons/ChevronLeft';
import PropTypes, { ChildrenType } from 'propTypes/index';
import useUpdateTitle from 'hooks/useUpdateTitle';

const useStyles = makeStyles(() => ({
  backButton: {
    position: 'absolute',
    left: 0,
  },
  headerContainer: {
    position: 'relative',
    minHeight: '60px',
  },
  header: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    lineHeight: '1.5rem',
  },
}));

function MobileSearchPageTemplate({ title, TitleProps, children, onClose }) {
  const classes = useStyles();
  const history = useHistory();
  useUpdateTitle(title);

  const goBack = () => {
    history.goBack();
    if (onClose) onClose();
  };

  return (
    <Grid>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.headerContainer}
      >
        <Typography
          component="div"
          color="primary"
          classes={{ root: classes.header }}
          {...TitleProps}
        >
          {title}
        </Typography>
        <Button
          className={classes.backButton}
          color="primary"
          aria-label="go back"
          onClick={goBack}
          startIcon={<LeftArrowIcon />}
        >
          Back
        </Button>
      </Grid>

      {children}
    </Grid>
  );
}

export default MobileSearchPageTemplate;

MobileSearchPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  children: ChildrenType.isRequired,
  TitleProps: PropTypes.shape({}),
  onClose: PropTypes.func,
};

MobileSearchPageTemplate.defaultProps = {
  TitleProps: {},
  onClose: undefined,
};
