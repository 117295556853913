import React from 'react';
import { useSelector } from 'react-redux';
import { ButtonBase, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';

import NearMeIcon from '@material-ui/icons/NearMe';
import EditIcon from '@material-ui/icons/Edit';

import { select } from 'store/toolkit';
import MultiAutocompleteStartAdornment from 'components/ModernExperience/Search/Autocomplete/MultiAutocompleteStartAdornment';
import { MOBILE_SEARCH_ROUTE } from 'navigation/navigationConstants';

const StyledButton = withStyles((theme) => {
  const PADDING = theme.spacing(1.75);
  const NOTCH_HEIGHT = PADDING * 0.5;

  return {
    root: {
      width: '100%',
      padding: PADDING,
      paddingBottom: PADDING - NOTCH_HEIGHT,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      justifyContent: 'flex-end',

      '&:hover .notchedOutline': {
        backgroundColor: theme.palette.action.hover,
      },

      '& .notchedOutline': {
        position: 'absolute',
        top: NOTCH_HEIGHT,
        left: 0,
        right: 0,
        bottom: 0,
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: theme.shape.borderRadius * 12,
      },

      '& .locationLabel': {
        position: 'absolute',
        top: -1 * theme.spacing(1),
        left: theme.spacing(2),
        fontSize: '.75rem',
        display: 'flex',
        alignItems: 'center',
        columnGap: 4,
        padding: '0px 4px',
        maxWidth: '80%',

        '& .notch': {
          position: 'absolute',
          height: 3,
          left: 0,
          right: 0,
          transform: 'translateY(-1px)',
          backgroundColor: theme.palette.background.paper,
        },

        '& :not(.notch)': {
          zIndex: 1,
        },
      },

      '& .searchLabel': {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        '& .searchLabel-content': {
          display: 'flex',
          alignItems: 'center',
          maxWidth: '90%', // need a maxWidth on this to enable the ellipsis on overflow
        },
      },
    },
  };
})(ButtonBase);

export default function MobileSearchButton(props) {
  const resultsSearchText = useSelector(select.results.searchText);
  const searchSliceText = useSelector(select.search.text);
  const resultsLocationText = useSelector(select.results.locationInput);
  const locationSliceText = useSelector(select.location.locationInput);

  const searchText = resultsSearchText || searchSliceText;
  const locationText = resultsLocationText || locationSliceText;
  const accessibleText = `Update Search. Current search is ${searchText} in ${locationText}`;

  return (
    <StyledButton
      role="button"
      aria-label={accessibleText}
      component={RouterLink}
      to={MOBILE_SEARCH_ROUTE}
      {...props}
    >
      <div className="notchedOutline">
        {Boolean(locationText) && (
          <div className="locationLabel">
            <span className="notch" />
            <NearMeIcon fontSize="inherit" color="secondary" />
            <Typography component="span" color="secondary" variant="inherit" noWrap>
              {locationText}
            </Typography>
          </div>
        )}
      </div>

      <div className="searchLabel">
        <div className="searchLabel-content">
          <MultiAutocompleteStartAdornment />
          <Typography className="searchText" component="span" variant="inherit" noWrap>
            {searchText}
          </Typography>
        </div>
        <EditIcon color="primary" />
      </div>
    </StyledButton>
  );
}
