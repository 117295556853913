import React, { useState, useEffect } from 'react';
import PropTypes, { ProviderDataShape } from 'propTypes';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Grid, Fade } from '@material-ui/core/';

import ProviderCardLarge from './ResultCards/ProviderCardLarge';
import ProviderCardSmall from './ResultCards/ProviderCardSmall';

export default function Providers({ search }) {
  const smDown = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const { results = [] } = search;

  const [selectedProvider, setSelectedProvider] = useState(results[0]);

  useEffect(() => {
    setSelectedProvider(results[0]);
  }, [results]);

  return (
    <Grid container spacing={1}>
      {results.map((provider, index) => (
        <ProviderCardSmall
          key={provider.entityId}
          provider={provider}
          selectedProvider={selectedProvider}
          setSelectedProvider={setSelectedProvider}
          index={index}
        />
      ))}
      {!smDown && selectedProvider?.entityId && (
        <Fade in timeout={600}>
          <ProviderCardLarge provider={selectedProvider} />
        </Fade>
      )}
    </Grid>
  );
}

Providers.propTypes = {
  search: PropTypes.shape({
    results: PropTypes.arrayOf(ProviderDataShape).isRequired,

    specialtyId: PropTypes.number.isRequired,
    subspecialtyId: PropTypes.number,
    location: PropTypes.shape({
      latitude: PropTypes.number,
      longitude: PropTypes.number,
    }).isRequired,
  }).isRequired,
};
