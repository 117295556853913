import { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { select, actions } from 'store/toolkit';

export default function useRestoreSearchSlice() {
  const [cachedSearchSlice] = useState(useSelector(select.search.slice)); // setting this in state so it's initial return value is stored and persisted across renders
  const dispatch = useDispatch();

  return useCallback(
    () => dispatch(actions.search.overwriteSlice(cachedSearchSlice)),
    [cachedSearchSlice, dispatch]
  );
}
