import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';

import { select } from 'store/toolkit';
import HorizontalScrollingContainer from 'components/HorizontalScrollingContainer';
import FeaturedActionCard, {
  FEATURED_ACTION_VIEWS,
} from 'components/FeaturedActions/FeaturedActionCard';
import useGetFeaturedActionVariantProps from 'hooks/useGetFeaturedActionVariantProps';

const useStyles = makeStyles(() => ({
  card: {
    flex: ({ isMobile }) => (isMobile ? 'none' : 1),
  },
}));

export default function FeaturedActionsSection(props) {
  const isMobile = useSelector(select.ui.isSmDown);
  const classes = useStyles({ isMobile });
  const featuredActions = useSelector(select.content.featuredActions);
  const getVariantProps = useGetFeaturedActionVariantProps();

  return (
    <HorizontalScrollingContainer
      backgroundColor="grey.100"
      disableFade={!isMobile}
      spacing={3}
      style={{ minHeight: 200 }}
      {...props}
    >
      {featuredActions.map((action) => (
        <FeaturedActionCard
          key={`featured-action-${
            action.promotion || action.search_subspecialty || action.search_specialty
          }`}
          title={action.title}
          avatarUrl={action.image}
          buttonText={action.button_text}
          view={isMobile ? FEATURED_ACTION_VIEWS.MOBILE : FEATURED_ACTION_VIEWS.DESKTOP}
          className={classes.card}
          {...getVariantProps(action)}
        >
          {action.description}
        </FeaturedActionCard>
      ))}
    </HorizontalScrollingContainer>
  );
}
