import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { select, actions } from 'store/toolkit';
import AiSearchButton from 'components/ModernExperience/Search/AiSearchButton';
import useRestoreSearchSlice from 'hooks/useRestoreSearchSlice';
import SearchForm from 'components/ModernExperience/SearchForm';
import MobileSearchPageTemplate from './MobileSearchPageTemplate';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '1.5rem auto',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    justifyContent: 'center',
  },
}));

// @TODO: TECH-4696 consider turning into a modal route in ModalRouter
function MobileSearchPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const enableAiSearch = useSelector(select.featureFlags.enableAiSearch);
  const url = useSelector(select.results.url);
  const restoreSearchSlice = useRestoreSearchSlice();

  const clearSearchSlice = useCallback(() => {
    // if back was pressed, then no search was performed, and we want to return the searchSlice to it's previous cached state
    restoreSearchSlice();
    // if back was pressed, then no search was performed, and we want to persist searched results in its previous cached state
    dispatch(actions.results.cacheSearch(Boolean(url)));
  }, [dispatch, restoreSearchSlice, url]);

  return (
    <MobileSearchPageTemplate title="Update Search" onClose={clearSearchSlice}>
      <Container maxWidth="md" id="mobile-landing-container" className={classes.container}>
        <SearchForm mobileView showClearAll autoSearch />
        {enableAiSearch && <AiSearchButton />}
      </Container>
    </MobileSearchPageTemplate>
  );
}

export default MobileSearchPage;
