import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';

import EmboldLoadingSpinner from 'components/EmboldLoadingSpinner';
import { thunks, select, constants } from 'store/toolkit';
import useUpdateTitle from 'hooks/useUpdateTitle';
import ResultProfile from 'components/ModernExperience/Profile/ResultProfile';
import ResultProfileTabs from 'components/ModernExperience/Profile/ResultProfileTabs';
import CompareTable from 'components/ModernExperience/CompareTable/CompareTable';
import PrintButton from 'components/PrintButton';
import SplitCompareTableForPrint from 'components/ModernExperience/CompareTable/SplitCompareTableForPrint';
import CollapsingProfileList from 'components/ModernExperience/Profile/CollapsingProfileList';
import { _analyticsActions } from 'analytics/index';
import useHandleShareNetwork from 'hooks/useHandleShareNetwork';
import NetworkNotice from './NetworkNotice';
import SharePageError from './SharePageError';
import SharePageSkeleton from './SharePageSkeleton';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    rowGap: 5,
  },
  title: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorPage: {
    justifyContent: 'center',
    color: theme.palette.primary.main,
    textAlign: 'center',
    '& p': { fontWeight: 'bold' },
    '& a': { marginTop: theme.spacing(2) },
  },
  errorIcon: {
    color: theme.palette.error.main,
    fontSize: theme.typography.h1.fontSize,
  },
  printButtonRoot: {
    padding: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
  printButtonLabel: {
    display: 'flex',
  },
  collapsingProfileList: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
}));

function ShareContent({ resultIds = [], profileCompareThreshold }) {
  const classes = useStyles();
  const isMobile = useSelector(select.ui.isMdDown);

  if (resultIds.length === 0) return null;

  // single profile view
  if (resultIds.length === 1) {
    return <ResultProfile id={resultIds[0]} />;
  }

  // tabbed profile view
  if (resultIds.length <= profileCompareThreshold) {
    return <ResultProfileTabs ids={resultIds} />;
  }

  // compare view desktop
  if (!isMobile) return <CompareTable resultIds={resultIds} showActionButtons />;

  return <CollapsingProfileList resultIds={resultIds} className={classes.collapsingProfileList} />;
}

function ShareHeader({ resultIds = [], profileCompareThreshold, ...props }) {
  const classes = useStyles();
  const [isPrinting, setIsPrinting] = useState(false);
  const shareType = useSelector(select.results.shareType) || 'Result';
  const isMobile = useSelector(select.ui.isMdDown);
  const dispatch = useDispatch();

  if (resultIds.length === 0) return null;

  // single profile view
  if (resultIds.length === 1) {
    return <Typography {...props}>1 {shareType} Shared With You</Typography>;
  }

  // tabbed profile view
  if (resultIds.length <= profileCompareThreshold) {
    return (
      <Typography {...props}>
        {resultIds.length} {shareType}s Shared With You
      </Typography>
    );
  }

  // compare view
  const text = `${resultIds.length} ${shareType}s Shared With You`;

  return (
    <Typography {...props}>
      {text}

      {!isMobile && (
        <PrintButton
          variant="outlined"
          color="primary"
          classes={{ label: classes.printButtonLabel, root: classes.printButtonRoot }}
          startIcon={isPrinting && <EmboldLoadingSpinner size={20} />}
          disabled={isPrinting}
          includeWatermark
          printComponent={
            // This is what will actually print
            <SplitCompareTableForPrint
              resultIds={resultIds}
              header={<Typography {...props}>Embold Health - {text}</Typography>}
            />
          }
          options={{
            documentTitle: `Embold Health - ${text}`,
            onBeforePrint: () => {
              dispatch(_analyticsActions.printShareCompareTable(resultIds));
              setIsPrinting(true);
            },
            onAfterPrint: () => setIsPrinting(false),
          }}
        >
          Print
        </PrintButton>
      )}
    </Typography>
  );
}

export default function SharePage() {
  const classes = useStyles();
  const { id } = useParams();

  const dispatch = useDispatch();

  const isLoading = useSelector(select.results.isLoading);
  const currentSlug = useSelector(select.networks.currentSlug);
  const error = useSelector(select.results.error);
  const results = useSelector(select.results.idList);
  const shareType = useSelector(select.results.shareType);
  const profileCompareThreshold = constants.results.PROFILE_COMPARE_THRESHOLD; // after this value, switch to a compare display

  useUpdateTitle('Share');

  const { handleShareNetwork, notice } = useHandleShareNetwork();

  const getShareData = useCallback(() => {
    const sharePromise = dispatch(thunks.results.fetchShare(id));
    sharePromise.unwrap().then(handleShareNetwork);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // fetch share data on initial mount only
  useEffect(getShareData, [getShareData]);

  if (error) return <SharePageError classes={classes} />;
  if (isLoading || !currentSlug) return <SharePageSkeleton classes={classes} />;
  if (results?.length < 1) return <SharePageError classes={classes} />;

  return (
    <div className={classes.root}>
      <NetworkNotice notice={notice} type={shareType} isPlural={results.length > 1} />
      <ShareHeader
        resultIds={results}
        variant="h2"
        className={classes.title}
        profileCompareThreshold={profileCompareThreshold}
      />
      <ShareContent resultIds={results} profileCompareThreshold={profileCompareThreshold} />
    </div>
  );
}

ShareContent.propTypes = {
  resultIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  profileCompareThreshold: PropTypes.number.isRequired,
};

ShareHeader.propTypes = {
  resultIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  profileCompareThreshold: PropTypes.number.isRequired,
};
