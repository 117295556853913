import React from 'react';
import { Redirect } from 'react-router-dom';
import { Typography, Link, Paper } from '@material-ui/core/';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import { select } from 'store/toolkit';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '90%',
    maxWidth: 800,
    padding: theme.spacing(3),
    margin: `0px auto`, // auto horizonal to be in center
    marginTop: theme.spacing(3), // add top margin to push off the navbar
    textAlign: 'center',
    '& h1': {
      marginBottom: theme.spacing(3),
    },
  },
}));

function ContactBCBS() {
  const classes = useStyles();
  const showBcbsLink = useSelector(select.featureFlags.showBcbsLink);

  if (!showBcbsLink) return <Redirect to="/" />;

  return (
    <Paper className={classes.root}>
      <Typography variant="h1" className="contact-title">
        Contact Blue Cross Blue Shield
      </Typography>

      <Typography variant="body2" paragraph>
        Associates can reach Arkansas Blue Cross Blue Shield&apos;s customer service at{' '}
        <Link href="tel:866-823-3790">866-823-3790</Link>. BCBS is providing a concierge service to
        assist associates with scheduling appointments. Associates can ask questions about health
        insurance coverage, benefits or treatments.
      </Typography>
    </Paper>
  );
}

export default ContactBCBS;
