import React, { useCallback, useState, useEffect } from 'react';
import clsx from 'clsx';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { makeStyles, Button } from '@material-ui/core';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';

import { actions, select } from 'store/toolkit';
import { REFERRING_CLINIC_ERROR_TEXT } from '../Login/errorText';

import ReferringClinicSelect from './ReferringClinicSelect';

const useStyles = (mobileView = false) =>
  makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: mobileView ? '100%' : '220px',
      padding: mobileView ? '0px 8px' : 0,
    },
    continueButton: {
      marginTop: theme.spacing(2),
      maxWidth: '100%',
    },
  }));

export default function ReferringClinicForm({ mobileView, additionalClass }) {
  const history = useHistory();
  const classes = useStyles(mobileView)();
  const dispatch = useDispatch();
  const referringClinic = useSelector(select.referringClinic.referringClinic);

  const [initialValues, setInitialValues] = useState({ referringClinic: null });

  useEffect(() => {
    setInitialValues({ referringClinic });
  }, [referringClinic]);

  const validationSchema = Yup.object({
    referringClinic: Yup.string().required(REFERRING_CLINIC_ERROR_TEXT),
  });

  const handleSubmit = useCallback(
    (formValues) => {
      const { referringClinic: referringClinicFormValue } = formValues;
      dispatch(actions.referringClinic.setReferringClinic(referringClinicFormValue));

      if (mobileView) {
        history.goBack();
      }
    },
    [dispatch, history, mobileView]
  );

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Form className={clsx(classes.root, { [additionalClass]: additionalClass })}>
        <ReferringClinicSelect submitOnChange={!mobileView} />
        {mobileView && (
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.continueButton}
            onSubmit={handleSubmit}
          >
            Continue
          </Button>
        )}
      </Form>
    </Formik>
  );
}

ReferringClinicForm.propTypes = {
  mobileView: PropTypes.bool,
  additionalClass: PropTypes.string,
};

ReferringClinicForm.defaultProps = {
  mobileView: false,
  additionalClass: '',
};
