import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Button, Card, CardActions, CardContent, CardHeader } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { VALID_HEADING_VARIANTS } from 'utils/utils';
import PromotionIcon from 'icons/Promotion';

export const FEATURED_ACTION_VIEWS = {
  MOBILE: 'mobile',
  DESKTOP: 'desktop',
};

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    maxWidth: ({ view }) => (view === FEATURED_ACTION_VIEWS.MOBILE ? 300 : 550),
    display: 'flex',
    flexDirection: 'column',

    // disabled the padding on the children, handle it at the card root
    '& > *': { padding: 0 },
    padding: theme.spacing(2),
    rowGap: theme.spacing(1),
  },
  headerRoot: {
    flexDirection: ({ view }) => (view === FEATURED_ACTION_VIEWS.MOBILE ? 'column' : 'row'),
    alignItems: ({ view }) => (view === FEATURED_ACTION_VIEWS.MOBILE ? 'flex-start' : 'center'),
    rowGap: theme.spacing(1),
  },
  headerAvatar: {
    maxWidth: 120,
    width: ({ view }) => (view === FEATURED_ACTION_VIEWS.MOBILE ? '35%' : '20%'),
    '& > .MuiAvatar-root': {
      width: '100%',
      height: 'auto',
      backgroundColor: 'transparent',
      '& > svg, img': { width: '100%', height: 'auto' },
    },
  },

  headerTitle: { margin: 0, fontSize: '1.15rem' },
  headerContent: {
    padding: 0,
    maxWidth: ({ view }) => (view === FEATURED_ACTION_VIEWS.MOBILE ? 'none' : '60%'),
  },

  contentRoot: {
    flex: 1,
    color: theme.palette.text.secondary,
  },
}));

/**
 * A custom implementation of the MUI Simple Card: https://v4.mui.com/components/cards/#simple-card
 * Use on the Landing Page for displaying client specific actions that are meant to be highlighted.
 * Stateless and presentational only component.
 */
export default function FeaturedActionCard({
  children,
  avatarUrl,
  avatarIcon,
  title,
  subtitle,
  headingLevel,
  view,
  ButtonProps,
  buttonText,
  onClick,
  titleColor,
  ...props
}) {
  const classes = useStyles({ view });

  return (
    <Card variant="outlined" component="article" classes={{ root: classes.cardRoot }} {...props}>
      <CardHeader
        classes={{
          root: classes.headerRoot,
          content: classes.headerContent,
          title: classes.headerTitle,
          avatar: classes.headerAvatar,
        }}
        avatar={
          <Avatar variant="square" size="large" src={avatarUrl} alt="">
            {avatarIcon /* This is the fallback if there is no image */}
          </Avatar>
        }
        title={title}
        subheader={subtitle}
        titleTypographyProps={{ component: headingLevel, variant: 'h3', color: titleColor }}
        subheaderTypographyProps={{ component: 'span', color: titleColor }}
      />
      <CardContent classes={{ root: classes.contentRoot }}>{children}</CardContent>
      {Boolean(buttonText) && (
        <CardActions>
          <Button
            variant="contained"
            color="primary"
            size={view === FEATURED_ACTION_VIEWS.MOBILE ? 'medium' : 'large'}
            {...ButtonProps}
            onClick={onClick}
          >
            {buttonText}
          </Button>
        </CardActions>
      )}
    </Card>
  );
}

FeaturedActionCard.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  headingLevel: PropTypes.oneOf(VALID_HEADING_VARIANTS),
  avatarUrl: PropTypes.string,
  avatarIcon: PropTypes.node,
  view: PropTypes.oneOf(Object.values(FEATURED_ACTION_VIEWS)),
  onClick: PropTypes.func,
  titleColor: PropTypes.oneOf([
    'primary',
    'secondary',
    'inherit',
    'textPrimary',
    'textSecondary',
    'error',
  ]),
  buttonText: PropTypes.string,
  ButtonProps: PropTypes.shape({
    // https://v4.mui.com/api/button/#props
  }),
};

FeaturedActionCard.defaultProps = {
  avatarUrl: undefined,
  avatarIcon: <PromotionIcon color="primary" />,
  headingLevel: 'h4',
  titleColor: 'primary',
  view: FEATURED_ACTION_VIEWS.DESKTOP,
  ButtonProps: {},
  onClick: undefined,
  buttonText: undefined,
  subtitle: undefined,
};
