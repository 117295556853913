import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { select } from 'store/toolkit';
import { SEARCH_TYPE_ICONS, SEARCH_TYPE_OPTIONS } from './MultiAutocomplete';

const useStyles = makeStyles((theme) => ({
  startAdornment: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: theme.spacing(0.75),
    paddingRight: theme.spacing(0.5),
    fontWeight: 700,
    [theme.breakpoints.down('xs')]: {
      fontSize: '.8rem',
      columnGap: theme.spacing(0.25),
      paddingLeft: theme.spacing(1),
      '& svg': {
        width: '1.25rem',
        height: '1.25rem',
      },
    },
  },
}));

export const START_ADORNMENT_ID = 'multi-autocomplete-start-adornment';

export default function MultiAutocompleteStartAdornment() {
  const classes = useStyles();
  const autocompleteType = useSelector(select.search.autocompleteType);
  const opt = SEARCH_TYPE_OPTIONS.find(({ value }) => value === autocompleteType);
  const IconComponent = SEARCH_TYPE_ICONS[autocompleteType];

  return (
    <Typography
      variant="inherit"
      component="span"
      color="primary"
      className={classes.startAdornment}
      id={START_ADORNMENT_ID}
    >
      <IconComponent />
      {opt.name}:
    </Typography>
  );
}
