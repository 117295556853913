import { DEFAULT_LOCATIONS, DEFAULT_RADIUS, DROPDOWN } from 'utils/constants';
import { selectMapKey } from 'utils/selectToken';

const azureMapsKeys = {
  dev: 'KzRiwn3ytsCi9Hp_x5utRumI8eCEnsWPDK9ljSWvJcw',
  staging: 'qHn_TowRlwaSwIVj91ehzljIcE7O_sLFeGvBCVSYa2c',
  prod: '74e5OQx_c0l_8xNlwwKD4BCdl7-6KOkARE42uhzfQAY',
};

const azureMapsKey = selectMapKey(azureMapsKeys);

const defaultConfig = {
  CONFIG_NAME: null, // required. this needs to be the name of the json file fetched from the backend. example: "walmart" will fetch "walmart.json"
  API_TOKEN: null, // required. assign per client
  EMBOLD_CLIENT: null, // required. assign per client. a unique identifier set and used only by the front end.
  EMBOLD_CLIENT_SLUG: null, // required. assign per client. must match the fusion client slug

  // api & auth
  API_URL: 'https://fusion.prod.emboldhealth.com/api/pg',
  AZURE_MAPS_KEY: azureMapsKey,

  // toggles
  SHOW_DR_SCORE: true, // required. set per client
  SHOW_REGION_SELECTOR: true, // optional
  SHOW_BENEFIT_DIFF: false, // optional
  HIGH_QUALITY_INTRO_MODAL: false, // optional

  // login
  LOGIN_NETWORK_NAME: null, // required. set per client. used to
  LOGIN_COOKIE_AUTH_REGEX: ['^.*$'], // defaults to any string passing

  // other
  DEFAULT_LOCATION: { ...DEFAULT_LOCATIONS.Dallas }, // optional. override per client
  DEFAULT_SEARCH_RADIUS: DEFAULT_RADIUS, // optional. override for individual client
  REGION_SELECTOR_CODES: {}, // set by the backend, only initialized here

  // content
  DISPLAY_NAME: null, // required. set per client. Should be the user facing display name for the client.
  HEADER_LOGO: null, // required. set per client
  LOCAL_LOGO_ASSET: null, // required. set per client.
  FORM_VARIANT: DROPDOWN, // required. set per client
  EMBOLD_LOGO_PRESENT: false, // optional. set per client.
  NO_WELCOME_LINK: true, // optional. set per client.
};

export default defaultConfig;
