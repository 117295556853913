import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box, Typography, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { select } from 'store/toolkit';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > span:not(:first-child)': {
      // grey[100] text on grey.A700 background has a 5.68:1 contrast ratio
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.grey.A700,
      padding: theme.spacing(0.5),
      margin: `0px ${theme.spacing(0.25)}px`,
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

export function getMatchedOnList(highlightObject = {}) {
  const {
    entityName, // providers
    entityId, // providers
    npi, // places
    name, // places
    specialty, // providers
    specialtyName, // places
    hospitalAffiliationsText = [], // providers
    groupAffiliationsText = [], // providers
    keyword, // providers and places
    subspecialtyNames = [], // providers
  } = highlightObject;
  const matches = [];

  if (entityName || name) matches.push({ label: 'Name' });
  if (entityId) matches.push({ label: 'NPI', value: entityId[0] });
  if (npi) matches.push({ label: 'NPI', value: npi[0] });
  if (specialty) matches.push({ label: 'Specialty', value: specialty.join(', ') });
  if (specialtyName) matches.push({ label: 'Type', value: specialtyName.join(', ') });
  if (subspecialtyNames.length)
    matches.push({
      label: 'Focus Area',
      value: subspecialtyNames.join(', '),
    });
  if (keyword) matches.push({ label: `Keyword`, value: keyword });

  if (!groupAffiliationsText.length && !hospitalAffiliationsText.length) return matches;

  // use the first hospital OR the first group affiliation
  let affiliationValue = hospitalAffiliationsText[0] || groupAffiliationsText[0];
  let tooltipMessage;

  if (hospitalAffiliationsText.length + groupAffiliationsText.length > 1) {
    // add ellipses if there is more than one affiliation
    affiliationValue += '...';
    tooltipMessage = (
      <>
        {Boolean(hospitalAffiliationsText.length) && (
          <div>
            <strong>Hospital Affiliations: </strong>
            {hospitalAffiliationsText.join(', ')}
          </div>
        )}
        {Boolean(groupAffiliationsText.length) && (
          <div>
            <strong>Group Affiliations: </strong>
            {groupAffiliationsText.join(', ')}
          </div>
        )}
      </>
    );
  }
  matches.push({
    label: 'Affiliations',
    value: affiliationValue,
    tooltip: tooltipMessage,
  });

  return matches;
}

export function MatchedField({ label, value, tooltip, ...props }) {
  const hasValue = Boolean(value);
  return (
    <Tooltip title={tooltip || ''} arrow>
      <Typography component="span" tabIndex={tooltip ? 0 : -1} {...props}>
        <strong>
          {label}
          {hasValue && ': '}
        </strong>
        {hasValue && `"${value}"`}
      </Typography>
    </Tooltip>
  );
}

MatchedField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  tooltip: PropTypes.node,
};

MatchedField.defaultProps = {
  value: null,
  tooltip: null,
};

export default function MatchedOnSection({ id, ...props }) {
  const classes = useStyles(props);
  const { highlight = {} } = useSelector(select.results.byId(id)) || {};

  const matchedOnList = useMemo(() => getMatchedOnList(highlight), [highlight]);

  if (!matchedOnList.length) return null;

  return (
    <Box classes={classes} {...props}>
      <Typography component="span" color="textSecondary">
        Matched on:{' '}
      </Typography>
      {matchedOnList.map((match) => (
        <MatchedField key={match.label} {...match} />
      ))}
    </Box>
  );
}

MatchedOnSection.propTypes = {
  id: PropTypes.string.isRequired,
};
