import React from 'react';
import clsx from 'clsx';
import PropTypes from 'propTypes/index';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, lighten } from '@material-ui/core/styles';
import { _analyticsActions } from 'analytics/index';
import { Grid, List, Paper, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import Colonoscopy from 'icons/colored/Colonoscopy';
import Cardiology from 'icons/colored/Cardiology';
import Pediatrics from 'icons/colored/Pediatrics';
import PrimaryCare from 'icons/colored/PrimaryCare';
import OrthoJoint from 'icons/colored/OrthoJoint';
import Dermatology from 'icons/colored/Dermatology';
import Gastroenterology from 'icons/colored/Gastroenterology';
import AllergyTest from 'icons/colored/AllergyTest';
import VirtualAssistant from 'icons/VirtualAssistant';

import useSearchDispatchWithHistory from 'hooks/useSearchDispatchWithHistory';
import { useFocusAnchorKeys } from 'utils/FocusRefContext';
import { CARE_CATEGORIES, COMMON_SEARCH_NAMES } from 'utils/constants';
import { actions, select, thunks } from 'store/toolkit';
import { INFO } from 'store/slices/notifications/notificationsConstants';

const useStyles = makeStyles((theme) => ({
  gridItem: {
    [theme.breakpoints.up('lg')]: { flex: 1 },
  },
  paper: {
    border: 'none',
    width: '100%',
    height: '100%',
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
      boxShadow: theme.shadows[4],
    },
  },
  icon: {
    '& > svg': {
      width: '100%',
      height: '100%',
      maxHeight: 90,
    },
  },
  text: {
    marginTop: theme.spacing(1),
    textAlign: 'center',
    fontSize: '1rem',
  },
  chatTopSearch: {
    position: 'relative',
    background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main} 70%)`,
    color: theme.palette.common.white,
    zIndex: 1,
    display: 'inline-block',
    '&::before': {
      borderRadius: 12,
      position: 'absolute',
      display: 'block',
      content: 'close-quote',
      top: 0,
      right: 0,
      height: '100%',
      width: '100%',
      transition: 'opacity .5s ease',
      background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main} 175%)`,
      opacity: 0,
      zIndex: -1,
    },
    '&:focus-visible': {
      outlineColor: lighten(theme.palette.secondary.main, 0.1),
      '&::before': {
        opacity: 1,
      },
    },
  },
  loadingSkeleton: {
    // Override MuiSkeleton-text transform
    transform: 'none',
    // Override MuiSkeleton-fitContent
    maxWidth: 'none',
    pointerEvents: 'none',
  },
}));

export const quickSearches = [
  {
    icon: <PrimaryCare />,
    text: 'Primary Care',
    payload: {
      type: CARE_CATEGORIES.PROVIDER_SPECIALTY,
      name: COMMON_SEARCH_NAMES.PRIMARY_CARE,
    },
  },
  {
    icon: <Pediatrics />,
    text: 'Pediatrics',
    payload: {
      type: CARE_CATEGORIES.PROVIDER_SPECIALTY,
      name: COMMON_SEARCH_NAMES.PEDIATRICS,
    },
  },
  {
    icon: <Colonoscopy />,
    text: 'Colonoscopy',
    payload: {
      type: CARE_CATEGORIES.PROVIDER_SERVICE,
      name: COMMON_SEARCH_NAMES.COLONOSCOPY,
    },
  },
  {
    icon: <Cardiology />,
    text: 'Cardiology',
    payload: {
      type: CARE_CATEGORIES.PROVIDER_SPECIALTY,
      name: COMMON_SEARCH_NAMES.CARDIOLOGY,
    },
  },
  {
    icon: <OrthoJoint />,
    text: 'Ortho-Joint',
    payload: {
      type: CARE_CATEGORIES.PROVIDER_SPECIALTY,
      name: COMMON_SEARCH_NAMES.ORTHO_JOINT,
    },
  },
  {
    icon: <Dermatology />,
    text: 'Dermatology',
    payload: {
      type: CARE_CATEGORIES.PROVIDER_SPECIALTY,
      name: COMMON_SEARCH_NAMES.DERMATOLOGY,
    },
  },
  {
    icon: <Gastroenterology />,
    text: 'Gastroenterology',
    payload: {
      type: CARE_CATEGORIES.PROVIDER_SPECIALTY,
      name: COMMON_SEARCH_NAMES.GASTROENTEROLOGY,
    },
  },
  {
    icon: <AllergyTest />,
    text: 'Allergy Test',
    payload: {
      type: CARE_CATEGORIES.PROVIDER_SERVICE,
      name: COMMON_SEARCH_NAMES.ALLERGY_TEST,
    },
  },
];

const chatSearch = {
  icon: <VirtualAssistant htmlColor="white" />,
  text: 'Virtual Assistant',
  callback: (dispatch) => {
    dispatch(actions.ui.openModal('chat'));
    dispatch(_analyticsActions.chatModalOpened());
  },
  class: 'chatTopSearch',
  label: 'Open Virtual Assistant',
};

const chatQuickSearches = [chatSearch, ...quickSearches.slice(0, -1)];

function TopSearchSkeleton({ children, className }) {
  return <Skeleton className={className}>{children}</Skeleton>;
}

function TopSearches() {
  const classes = useStyles();
  const searchDispatch = useSearchDispatchWithHistory();
  const dispatch = useDispatch();
  const focusAnchorKeys = useFocusAnchorKeys();
  const enableChat = useSelector(select.featureFlags.enableChat);
  const searches = enableChat ? chatQuickSearches : quickSearches;
  const locationInput = useSelector(select.location.locationInput);
  const isClientConfigLoading = useSelector(select.config.isLoading);

  const handleClick = (selectedSearch) => {
    if (selectedSearch.callback) {
      selectedSearch.callback(dispatch);
    } else if (locationInput) {
      searchDispatch(thunks.results.executeTopSearch(selectedSearch.payload));

      if (
        selectedSearch.payload.type === CARE_CATEGORIES.FACILITY_NAME ||
        selectedSearch.payload.type === CARE_CATEGORIES.FACILITY_TYPE
      ) {
        // TODO, this should really be handled by a useEffect in the SortMenu component
        dispatch(actions.filters.setPlaceSort('distance'));
      }
    } else {
      dispatch(
        actions.notifications.create({
          message: 'Please enter a valid address, city, or zip code',
          severity: INFO,
          duration: 7000,
          returnFocusKey: focusAnchorKeys.locationInput,
        })
      );
    }
  };

  return (
    <Grid container justifyContent="space-between" spacing={1} component={List}>
      {searches.map((quickSearch, i) => (
        <Grid
          item
          component="li"
          id={quickSearch.text}
          className={classes.gridItem}
          key={quickSearch.text.concat(i)}
          xs={6}
          md={3}
        >
          <Paper
            component={isClientConfigLoading ? TopSearchSkeleton : 'button'}
            ref={quickSearch.ref}
            className={clsx(classes.paper, {
              [classes[quickSearch.class]]: quickSearch.class,
              [classes.loadingSkeleton]: isClientConfigLoading,
            })}
            title={quickSearch.label || `Search for ${quickSearch.text}`}
            onClick={() => handleClick(quickSearch)}
            aria-label={quickSearch.label || `Search for ${quickSearch.text}`}
          >
            <div className={classes.icon}>{quickSearch.icon}</div>
            <Typography component="span" classes={{ root: classes.text }}>
              {quickSearch.text}
            </Typography>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
}

TopSearchSkeleton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
};

export default TopSearches;
