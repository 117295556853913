import React from 'react';

import MobileSearchPageTemplate from 'components/Pages/MobileSearch/MobileSearchPageTemplate';
import ReferringClinicForm from './ReferringClinicForm';

function MobileUpdateReferringClinic() {
  return (
    <MobileSearchPageTemplate title="Update Clinic">
      <ReferringClinicForm mobileView />
    </MobileSearchPageTemplate>
  );
}

export default MobileUpdateReferringClinic;
