import React, { useEffect, useMemo } from 'react';

import { ProviderDataShape } from 'propTypes/index';
import { Link, Grid, Typography, Box, useMediaQuery, makeStyles } from '@material-ui/core/';
import { AzureMapsProvider } from 'react-azure-maps';
import { useProviderScore } from 'hooks/Provider/';
import { _analyticsActions } from 'analytics/index';
import { getPlaces } from 'utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { select } from 'store/toolkit';
import {
  PERCENTILE_THRESHOLD,
  SUBSPECIALTY_SECTION,
  COST_EFFICIENCY_SECTION,
  STRENGTHS_SECTION,
} from 'utils/constants';

import ChevronRight from '@material-ui/icons/ChevronRight';
import RecordVoiceOverOutlinedIcon from '@material-ui/icons/RecordVoiceOverOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

import Male from 'icons/Male';
import Female from 'icons/Female';
import OutcareColored from 'icons/colored/OutcareColored';
import ScoreIcon from 'components/ScoreIcon';
import CheckOrXIcon from 'icons/dynamic/CheckOrXIcon';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

import IconLabel from 'components/Results/ResultCard/IconLabel';
import CoverageRibbon from 'components/Results/ResultCard/Ribbons/CoverageRibbon';
import LocationsMinimap from 'components/Modals/ChatModal/LocationsMinimap';
import Tooltip from 'components/Tooltip';
import HighPerformingRibbon from 'components/Results/ResultCard/Ribbons/HighPerformingRibbon';
import ChatScoreTierPill from '../ChatScoreTierPill';
import ScheduleButton from './Buttons/ScheduleButton';
import ShareButton from './Buttons/ShareButton';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 10,
    padding: `${theme.spacing(1)}px`,
    marginTop: 8,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  buttonContainer: {
    marginTop: 5,
    gap: 10,
    marginBottom: 5,
  },
  cardTitle: {
    lineHeight: 1.2,
    margin: '4px 0',
  },
  titleRow: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    '& .MuiBox-root': {
      marginLeft: theme.spacing(1),
      flex: 1,
      overflow: 'hidden',
      '& .MuiTypography-root': {
        display: 'block',
        marginTop: 0,
      },
    },
  },
  row: {
    padding: 2,
  },
  section: {
    padding: '0px 8px',
  },
  specialties: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginTop: 8,
    },
  },
  locationSection: {
    width: '100%',
    backgroundColor: theme.palette.grey[50],
    padding: 12,
    borderRadius: 10,
  },
  icon: {
    height: 20,
    width: 20,
    marginLeft: 10,
    marginRight: 10,
  },
  specializationContainer: {
    width: '100%',
    backgroundColor: theme.palette.grey[50],
    padding: 8,
    borderRadius: 10,
    marginBottom: 8,
    display: 'flex',
  },
  coverageDecreasing: {
    '& .MuiAvatar-root': {
      width: '1.25rem',
      height: '1.25rem',
      marginLeft: -1.5,
      marginRight: '.15rem',
    },
    '& p': {
      fontWeight: 'bold',
    },
  },
}));

function ProviderCardLarge({ provider }) {
  const classes = useStyles();
  const smDown = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const {
    entityId,
    entityName,
    title,
    gender,
    subspecialties,
    diagnosingPercentile,
    treatmentPercentile,
    outcomesPercentile,
    scoreCareJourneyCost,
    specialty,
    outcareCompetent,
    languagesArray,
    benefitDecrease,
  } = provider;

  const score = useProviderScore(provider);
  const dispatch = useDispatch();

  const isTodayInBenefitChangeDateRange = useSelector(
    select.content.isTodayInBenefitChangeDateRange
  );
  const benefitsChangeText = useSelector(select.content.benefitsChange);

  const city = useSelector(select.chat.city);
  const state = useSelector(select.chat.state);
  const coords = useSelector(select.chat.coords);

  useEffect(() => {
    dispatch(_analyticsActions.chatProfileView({ coordinates: coords, providerData: provider }));
  }, [coords, dispatch, entityId, provider]);

  const networkSlug = useSelector(select.networks.currentSlug);
  const showOutcare = useSelector(select.featureFlags.showOutcare);
  const showHighPerformingRibbon = useSelector(select.featureFlags.showHighPerformingRibbon);

  const uniquePlaces = getPlaces(provider);
  const placesAcceptingCount = uniquePlaces?.reduce((acceptingCount, place) => {
    // eslint-disable-next-line no-param-reassign
    if (place.acceptingNewPatients) acceptingCount += 1;
    return acceptingCount;
  }, 0);

  const strengths = useMemo(() => {
    const showDiagnosing = diagnosingPercentile && diagnosingPercentile >= PERCENTILE_THRESHOLD;
    const showTreatment = treatmentPercentile && treatmentPercentile >= PERCENTILE_THRESHOLD;
    const showOutcomes = outcomesPercentile && outcomesPercentile >= PERCENTILE_THRESHOLD;

    let s = '';
    if (showDiagnosing) s = 'Diagnosing';
    if (showTreatment) s = s.length ? `${s}, Treatment` : 'Treatment';
    if (showOutcomes) s = s.length ? `${s}, Outcomes` : 'Outcomes';

    return s;
  }, [diagnosingPercentile, outcomesPercentile, treatmentPercentile]);

  const costEfficiencyMap = {
    1: 'Less Efficient',
    2: 'Average',
    3: 'More Efficient',
  };

  return (
    <Grid item xs={12} style={{ paddingTop: 0 }}>
      <Grid className={classes.container}>
        {!smDown && (
          <Grid container className={classes.row}>
            <ChatScoreTierPill provider={provider} />
            <Grid style={{ marginLeft: 'auto' }}>
              <Link
                href={`/profile/provider/${entityId}?network_slug=${networkSlug}&location=${
                  coords.latitude
                },${coords.longitude}&location_input=${city}, ${state}&city=${city || ''}&state=${
                  state || ''
                }`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                More Details <ChevronRight />
              </Link>
            </Grid>
          </Grid>
        )}
        <Grid container className={classes.row}>
          <Grid item xs={smDown ? 12 : 6} className={classes.section}>
            {!smDown && (
              <Grid className={classes.titleRow}>
                {score && (
                  <div style={{ marginRight: 8 }}>
                    <Typography variant="srOnly">{score.name}</Typography>
                    <ScoreIcon score={score.value} coloredText emboldRecommendedSize={30} />
                  </div>
                )}

                <Box style={{ marginLeft: 0 }}>
                  <Typography
                    variant="h3"
                    classes={{
                      root: classes.cardTitle,
                    }}
                  >
                    {entityName}
                    {Boolean(title) && ` ${title}`}
                  </Typography>
                  {specialty && (
                    <Typography style={{ fontSize: '.9rem', marginTop: '-8px' }}>
                      {specialty}
                    </Typography>
                  )}
                </Box>
              </Grid>
            )}
            {showHighPerformingRibbon && (
              <div style={{ marginLeft: 7 }}>
                <HighPerformingRibbon result={provider} />
              </div>
            )}
            <CoverageRibbon result={provider} />

            {isTodayInBenefitChangeDateRange && benefitDecrease && (
              <Tooltip
                title={benefitsChangeText.cardAttribute.text}
                message={benefitsChangeText.cardAttribute.tooltip}
              >
                <IconLabel
                  img={<ReportProblemOutlinedIcon color="error" />}
                  className={classes.coverageDecreasing}
                >
                  {benefitsChangeText.cardAttribute.text}
                </IconLabel>
              </Tooltip>
            )}
            {(gender === 'Female' || gender === 'Male') && (
              <IconLabel
                img={gender === 'Male' ? <Male color="primary" /> : <Female color="primary" />}
              >
                {gender}
              </IconLabel>
            )}
            {showOutcare && outcareCompetent && (
              <IconLabel img={<OutcareColored />} textClass="accepting">
                LGBTQ+ Competent
              </IconLabel>
            )}
            {languagesArray?.length > 0 && (
              <IconLabel
                style={{
                  flexWrap: 'nowrap',
                }}
                img={<RecordVoiceOverOutlinedIcon />}
                id="language"
                TypographyProps={{
                  style: { overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' },
                }}
              >
                {languagesArray.join(', ')}
              </IconLabel>
            )}
            {placesAcceptingCount > 0 ? (
              <IconLabel img={<CheckOrXIcon checked />} textClass="accepting">
                Accepting patients
              </IconLabel>
            ) : (
              typeof acceptingNewPatients === 'boolean' && (
                <IconLabel img={<CheckOrXIcon checked={false} />} textClass="not-accepting">
                  Not accepting patients
                </IconLabel>
              )
            )}
            {uniquePlaces?.length > 0 && (
              <IconLabel img={<LocationOnOutlinedIcon color="primary" />} textClass="accepting">
                {uniquePlaces?.length} nearby locations
              </IconLabel>
            )}
            {!smDown && (
              <Grid container classes={{ root: classes.buttonContainer }}>
                <ShareButton provider={provider} />
                <ScheduleButton provider={provider} />
              </Grid>
            )}
          </Grid>
          <Grid item xs={smDown ? 12 : 6} className={classes.specialties}>
            {subspecialties && subspecialties.length > 0 && (
              <Grid className={classes.specializationContainer}>
                <Grid>
                  <SUBSPECIALTY_SECTION.IconComponent className={classes.icon} />
                </Grid>
                <Grid>
                  <Tooltip
                    title={SUBSPECIALTY_SECTION.title}
                    message={SUBSPECIALTY_SECTION.description}
                  >
                    <Typography style={{ fontWeight: 'bold' }}>
                      {SUBSPECIALTY_SECTION.title}:{' '}
                    </Typography>
                  </Tooltip>
                  <Typography>
                    {subspecialties.length === 1
                      ? subspecialties[0].subspecialtyName
                      : subspecialties
                          .map((subspecialty) => subspecialty.subspecialtyName)
                          .join(', ')}
                  </Typography>
                </Grid>
              </Grid>
            )}
            {strengths && (
              <Grid className={classes.specializationContainer}>
                <Grid>
                  <STRENGTHS_SECTION.IconComponent className={classes.icon} />
                </Grid>
                <Grid>
                  <Grid>
                    <Tooltip
                      title={STRENGTHS_SECTION.title}
                      message={STRENGTHS_SECTION.description}
                    >
                      <Typography style={{ fontWeight: 'bold' }}>
                        {STRENGTHS_SECTION.title}:{' '}
                      </Typography>
                    </Tooltip>
                    <Typography>{strengths}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {scoreCareJourneyCost && costEfficiencyMap[scoreCareJourneyCost] && (
              <Grid className={classes.specializationContainer}>
                <Grid>
                  <COST_EFFICIENCY_SECTION.IconComponent className={classes.icon} />
                </Grid>
                <Grid>
                  <Grid>
                    <Tooltip
                      title={COST_EFFICIENCY_SECTION.title}
                      message={COST_EFFICIENCY_SECTION.description}
                    >
                      <Typography style={{ fontWeight: 'bold' }}>
                        {COST_EFFICIENCY_SECTION.title}:{' '}
                      </Typography>
                    </Tooltip>
                    <Typography>{costEfficiencyMap[scoreCareJourneyCost]}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid container className={classes.row}>
          <Grid item xs={12} className={classes.locationSection}>
            <AzureMapsProvider>
              <LocationsMinimap provider={provider} coords={coords} />
            </AzureMapsProvider>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

ProviderCardLarge.propTypes = {
  provider: ProviderDataShape.isRequired,
};

export default ProviderCardLarge;
