import React, { useCallback, useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Button, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';

import { actions, select, thunks, constants } from 'store/toolkit';
import { isResultsPathname } from 'store/slices/results/resultUtils';
import { LOGIN_NETWORK_NAME_VERSION, MERCY_HEALTH } from 'utils/constants';

import { setLocalStorageExpire } from 'utils/utils';

import NetworkSelector from './NetworkSelector';

import { SUPPORT_ROUTE } from '../../../navigation/navigationConstants'; // for some reason eslint can't resolve an absolute path to navigation

const useStyles = (regionModalOpen = false) =>
  makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: regionModalOpen ? '100%' : '220px',
    },
    continueButton: {
      marginTop: theme.spacing(2),
      maxWidth: '100%',
    },
  }));

export default function UpdateNetworkForm({ regionModalOpen }) {
  const location = useLocation();
  const classes = useStyles(regionModalOpen)();

  const dispatch = useDispatch();

  const regionSelectorCodes = useSelector(select.networks.all);
  const isReadyForSearch = useSelector(select.search.isReadyForSearch);
  const resultCount = useSelector(select.results.count);
  const networks = useSelector(select.networks.all);
  const currentNetworkSlug = useSelector(select.networks.currentSlug);
  const regionSelectorEnabled = useSelector(select.featureFlags.showRegionSelector);
  const clientSlug = useSelector(select.client.slug);
  const loginNetworkName = useSelector(select.client.loginNetworkName);

  const axios = useSelector(select.axios.axiosInstance);

  const smDown = useSelector(select.ui.isSm);

  const [initialValues, setInitialValues] = useState({ networkSlug: '' });

  useEffect(() => {
    setInitialValues({ networkSlug: networks[currentNetworkSlug]?.slug });
  }, [networks, currentNetworkSlug]);

  const showRegionSelector =
    (location?.pathname === '/' ||
      location?.pathname.includes('/results') ||
      location.pathname === SUPPORT_ROUTE) &&
    regionSelectorEnabled &&
    !smDown;

  const handleSubmit = useCallback(
    (formValues, formik) => {
      const { networkSlug } = formValues;

      if (networkSlug === MERCY_HEALTH) {
        dispatch(actions.ui.openModal('mercyHealthRedirect'));
        dispatch(actions.ui.closeModal('updateRegion'));
        formik.setFieldValue('networkSlug', currentNetworkSlug);
        return;
      }

      if (!networkSlug || currentNetworkSlug === networkSlug) {
        dispatch(actions.ui.closeModal('updateRegion'));
        return;
      }

      dispatch(actions.app.handleNetworkChange(networkSlug));

      // If the user is already viewing a list of results, refetch with the new networkSlug value
      if (isResultsPathname(location.pathname) && resultCount > 0) {
        if (!isReadyForSearch) {
          // in the case that the user has updated the search inputs to an invalid state,
          // revert the search state to the previous search before resubmitting the search
          dispatch(thunks.search.updateSearchFromResults());
        }
        dispatch(thunks.results.executeSearch());
      }

      setLocalStorageExpire(loginNetworkName, networkSlug, 90, LOGIN_NETWORK_NAME_VERSION);

      const payload = {
        network_slug: networkSlug,
        client_slug: clientSlug,
      };

      axios
        .post(constants.fusion.ENDPOINTS.NETWORK_SELECT, payload)
        .then(() => {
          //
        })
        // eslint-disable-next-line no-shadow
        .catch((error) => {
          Sentry.captureException(error);
          console.error(error);
        });
    },
    [
      currentNetworkSlug,
      dispatch,
      location.pathname,
      resultCount,
      loginNetworkName,
      clientSlug,
      axios,
      isReadyForSearch,
    ]
  );

  return (
    (showRegionSelector || regionModalOpen) &&
    Object.values(regionSelectorCodes).length > 1 && (
      <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit}>
        <Form className={classes.root}>
          <NetworkSelector name="networkSlug" size={regionModalOpen ? 'medium' : 'small'} />
          {regionModalOpen && (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.continueButton}
            >
              Continue
            </Button>
          )}
        </Form>
      </Formik>
    )
  );
}

UpdateNetworkForm.propTypes = {
  regionModalOpen: PropTypes.bool,
};

UpdateNetworkForm.defaultProps = {
  regionModalOpen: false,
};
