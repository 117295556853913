import React, { useRef, useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { select, thunks, actions } from 'store/toolkit';

import {
  Grid,
  Button,
  TextField,
  InputAdornment,
  CircularProgress,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import SearchIcon from '@material-ui/icons/Search';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

const useStyles = makeStyles((theme) => ({
  input: { flex: 1, margin: 0, '& fieldset': { borderRadius: `20px 0 0 20px` } },
  submitButton: {
    borderRadius: `0 20px 20px 0`,
    '&:not(.Mui-disabled)': {
      background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main} 80%)`,
    },
  },
  startAdornment: { padding: `0 ${theme.spacing(1.5)}px` },
  resetButton: { padding: theme.spacing(1) },
  hidden: { opacity: 0 },
}));

export default function AiSearchInput() {
  const inputRef = useRef(null);
  const classes = useStyles();
  const dispatch = useDispatch();

  const error = useSelector(select.aiSearch.error);
  const loading = useSelector(select.aiSearch.loading);
  const input = useSelector(select.aiSearch.searchInputValue);
  const chatResponse = useSelector(select.aiSearch.chatResponse);

  const submitSearch = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(thunks.aiSearch.aiSearch());
    },
    [dispatch]
  );

  const handleInputChange = useCallback(
    (e) => {
      dispatch(actions.aiSearch.setInput(e.target.value));
    },
    [dispatch]
  );

  const showReset = useMemo(() => error || chatResponse, [chatResponse, error]);
  const handleReset = useCallback(() => {
    inputRef?.current?.focus();
    dispatch(actions.aiSearch.reset());
  }, [dispatch]);

  return (
    <Grid container component="form" onSubmit={submitSearch}>
      <TextField
        value={input}
        onChange={handleInputChange}
        classes={{ root: classes.input }}
        disabled={loading}
        variant="outlined"
        margin="dense"
        placeholder="Find a specialist"
        inputRef={inputRef}
        inputProps={{
          maxLength: 255,
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={{
          startAdornment: (
            <InputAdornment classes={{ root: classes.startAdornment }} position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <Button
        type="submit"
        classes={{ root: classes.submitButton }}
        disabled={loading || input.length === 0}
        variant="contained"
        color="primary"
        endIcon={loading && <CircularProgress size={15} thickness={2} aria-label="" />}
      >
        <SearchIcon />
      </Button>
      <IconButton
        classes={{ root: clsx(classes.resetButton, { [classes.hidden]: !showReset }) }}
        aria-hidden={!showReset}
        disabled={!showReset}
        aria-label="reset AI search"
        onClick={handleReset}
      >
        <RestartAltIcon />
      </IconButton>
    </Grid>
  );
}
