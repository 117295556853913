import React, { useCallback } from 'react';
import clsx from 'clsx';
import PropTypes, { ChildrenType } from 'propTypes';
import { useDispatch } from 'react-redux';
import { Grid, IconButton } from '@material-ui/core';
import { lighten, darken } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

import CloseIcon from '@material-ui/icons/Close';
import Info from '@material-ui/icons/InfoOutlined';
import Error from '@material-ui/icons/ErrorOutline';
import Success from '@material-ui/icons/CheckCircleOutline';
import Warning from '@material-ui/icons/ReportProblemOutlined';

import { actions } from 'store/toolkit';
import {
  BANNER_COLORS,
  VARIANT_GRADIENT,
  VARIANT_SOLID,
} from 'store/slices/banner/bannerConstants';
import {
  ERROR,
  INFO,
  SUCCESS,
  VALID_SEVERITIES,
  WARNING,
} from 'store/slices/notifications/notificationsConstants';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(0.5),
    textAlign: 'center',
    '& button': {
      color: 'inherit',
    },
  },
  icon: {
    height: 16,
    width: 16,
    marginRight: theme.spacing(1),
  },
  gradient: {
    background: ({ color }) =>
      `linear-gradient(45deg, ${theme.palette[color].dark}, ${theme.palette[color].main} 50%)`,
    color: ({ color }) => theme.palette[color].contrastText,
    '& .Mui-focusVisible': {
      outlineColor: ({ color }) => theme.palette[color].contrastText,
    },
  },
  solid: {
    color: ({ color }) => darken(theme.palette[color].main, 0.6),
    background: ({ color }) => lighten(theme.palette[color].main, 0.9),
    '& .Mui-focusVisible': {
      outlineColor: ({ color }) => darken(theme.palette[color].main, 0.6),
    },
    '& .banner-icon': {
      color: ({ color }) => theme.palette[color].main,
    },
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeButton: {
    marginLeft: 'auto',
    display: 'block',
  },
}));

const BANNER_ICONS = {
  [INFO]: Info,
  [SUCCESS]: Success,
  [WARNING]: Warning,
  [ERROR]: Error,
};

export default function Banner({
  children,
  showCloseButton,
  onClose,
  additionalClass,
  contentContainerProps,
  color,
  styleVariant,
  icon,
  ...props
}) {
  const classes = useStyles({ color, styleVariant });
  const Icon = BANNER_ICONS[icon];

  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    dispatch(actions.banner.hideBanner());
    if (onClose) {
      onClose();
    }
  }, [dispatch, onClose]);

  return (
    <Grid
      container
      alignItems="center"
      className={clsx(classes.root, classes.styleVariant, {
        [additionalClass]: additionalClass,
        [classes.gradient]: styleVariant === VARIANT_GRADIENT,
        [classes.solid]: styleVariant === VARIANT_SOLID,
      })}
      {...props}
    >
      {showCloseButton && <Grid item xs={1} />}

      <Grid
        item
        xs={showCloseButton ? 10 : 12}
        className={classes.contentContainer}
        {...contentContainerProps}
      >
        {icon && <Icon className={clsx(classes.icon, 'banner-icon')} />}
        {children}
      </Grid>

      {showCloseButton && (
        <Grid item xs={1}>
          <IconButton
            aria-label="dismiss banner"
            size="small"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
}

Banner.propTypes = {
  showCloseButton: PropTypes.bool,
  children: ChildrenType.isRequired,
  additionalClass: PropTypes.string,
  onClose: PropTypes.func,
  contentContainerProps: PropTypes.shape({}),
  color: PropTypes.oneOf(BANNER_COLORS),
  styleVariant: PropTypes.oneOf([VARIANT_GRADIENT, VARIANT_SOLID]),
  icon: PropTypes.oneOf(VALID_SEVERITIES),
};

Banner.defaultProps = {
  showCloseButton: true,
  additionalClass: undefined,
  onClose: undefined,
  contentContainerProps: {},
  color: 'primary',
  styleVariant: VARIANT_GRADIENT,
  icon: undefined,
};
