import React, { useState, useCallback } from 'react';
import MenuPopoverChip from 'components/Chips/MenuPopoverChip';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core/';

const mockMenuChipOptions = [
  {
    label: 'Option 1',
    value: 1,
  },
  {
    label: 'Option 2',
    value: 2,
  },
  {
    label: 'Option 3',
    value: 3,
  },
  {
    label: 'Option 4',
    value: 4,
  },
  {
    label: 'Option 5',
    value: 5,
  },
  {
    label: 'Option 6',
    value: 6,
  },
  {
    label: 'Option 7',
    value: 7,
  },
  {
    label: 'Option 8',
    value: 8,
  },
];

export default function MenuChipExample() {
  const [values, setValues] = useState([]);

  const handleSelect = useCallback(
    (evt) => {
      const updateValue = Number(evt.target.value);
      if (!values.includes(updateValue)) {
        setValues([...values, updateValue]);
      } else {
        setValues(values.filter((val) => val !== updateValue));
      }
    },
    [values]
  );

  const handleClear = useCallback(() => {
    setValues([]);
  }, []);

  return (
    <MenuPopoverChip
      values={values}
      options={mockMenuChipOptions}
      label="Menu Chip"
      onClear={handleClear}
    >
      <FormGroup>
        {mockMenuChipOptions.map((option) => (
          <FormControlLabel
            key={option.label}
            control={<Checkbox onChange={handleSelect} checked={values.includes(option.value)} />}
            value={option.value}
            label={option.label}
          />
        ))}
      </FormGroup>
    </MenuPopoverChip>
  );
}
