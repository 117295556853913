import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import LocationAutocomplete from 'components/ModernExperience/Search/LocationSearch/LocationAutocomplete';

import { select } from 'store/toolkit';
import useSubmitSearchForm from 'hooks/useSubmitSearchForm';
import MobileSearchPageTemplate from './MobileSearchPageTemplate';

function MobileUpdateLocationPage() {
  const history = useHistory();
  const searchWasAttempted = useSelector(select.results.url);

  const submitSearch = useSubmitSearchForm();

  return (
    <MobileSearchPageTemplate title="Location" TitleProps={{ 'aria-label': 'Update location' }}>
      <LocationAutocomplete
        mobileView
        autoFocus
        autoSearch={Boolean(searchWasAttempted)}
        submitSearch={submitSearch}
        onSelect={history.goBack}
      />
    </MobileSearchPageTemplate>
  );
}

export default MobileUpdateLocationPage;
