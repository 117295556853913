/**
 * This map connects the Provider Guide filterKeys used in the filtersSlice (in camelCase)
 * to the Fusion API corresponding filter keys(in snake_case)
 * Exclusion filters are appended with "__exclude"
 */
const filterKeyMap = {
  acceptingNewPatients: 'accepting_new_patients',
  gender: 'gender',
  credentials: 'title',
  languages: 'languages_array__in',
  specialties: 'specialty_id__in',
  outcareCompetent: 'outcare_competent',
  telehealthAvailable: 'telehealth_available',
  boardCertified: 'board_certified',
  highPerforming: 'high_performing',
  inNetworkPreferred: 'curated_out',
  hasBenefitDiff: 'has_benefit_diff',
  healthConnectPlan: 'health_plan',
  isWheelchairAccessible: 'is_wheelchair_accessible',
  featured: 'client_featured',
  excludeClientFeatured: 'client_featured__exclude',
  featuredFacility: 'client_featured',
  groupAffiliations: 'group_affiliations__in',
  hospitalAffiliations: 'hospital_affiliations__in',
  subspecialties: 'subspecialty_id__in',
  preferredGroup: 'group_score_tier',
  matchedOn: 'match_on',
};

export default filterKeyMap;
