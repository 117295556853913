import React, { useState, useCallback, useRef, useEffect } from 'react';
import PropTypes, { ChildrenType } from 'propTypes/index';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Popover, ButtonGroup, IconButton } from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowDown';

import CloseIcon from '@material-ui/icons/Close';
import useReturnFocus from 'hooks/useReturnFocus';

const useStyles = makeStyles((theme) => ({
  activeButtonRoot: {
    borderRight: 'none',
    '&:hover,&.Mui-disabled': {
      borderRight: 'none',
    },
    paddingRight: theme.spacing(0.5),
  },
  iconFlipped: {
    transform: 'rotate(-180deg)',
  },
  popoverPaper: {
    maxHeight: '70%',
    padding: theme.spacing(1.5),
  },
  deleteButton: {
    padding: 0,
    fontSize: 'inherit',
    borderLeft: 'none',
    '&:hover,&.Mui-disabled': {
      borderLeft: 'none',
    },
  },
  closeIcon: {
    padding: theme.spacing(1),
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 9,
  },
  divider: {
    margin: `${theme.spacing(2)}px 0px`,
  },
}));

function PopoverChip({
  disabled,
  contentLabel,
  buttonLabel,
  showCloseButton,
  PopoverProps,
  DeleteButtonProps,
  DeleteIcon,
  onDelete,
  children,
  ...props
}) {
  const classes = useStyles();
  const buttonRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const { ref: closeButtonRef, returnFocus: focusCloseButton } = useReturnFocus();

  const buttonId = `${contentLabel}-chip-button`;
  const menuId = `${contentLabel}-popover`;
  const showDeleteButton = Boolean(onDelete);

  useEffect(() => {
    // automatically focus the close button on open
    if (isOpen && showCloseButton) {
      focusCloseButton();
    }
  }, [isOpen, focusCloseButton, showCloseButton]);

  const handleButtonClick = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleClose = useCallback(() => setIsOpen(false), [setIsOpen]);

  const handleOnDelete = useCallback(() => {
    if (onDelete) {
      setIsOpen(true);
      onDelete();
    }
  }, [onDelete]);

  return (
    <>
      <ButtonGroup {...props}>
        <Button
          onClick={handleButtonClick}
          classes={{ root: showDeleteButton ? classes.activeButtonRoot : '' }}
          aria-haspopup="true"
          aria-controls={menuId}
          aria-expanded={isOpen}
          id={buttonId}
          disabled={disabled}
          ref={buttonRef}
          variant="outlined"
          endIcon={
            Boolean(!showDeleteButton) && (
              <KeyboardArrowUpIcon className={isOpen ? classes.iconFlipped : ''} />
            )
          }
        >
          {buttonLabel}
        </Button>
        {Boolean(showDeleteButton) && (
          <Button
            onClick={handleOnDelete}
            disabled={disabled}
            classes={{ root: classes.deleteButton }}
            {...DeleteButtonProps}
          >
            {DeleteIcon}
          </Button>
        )}
      </ButtonGroup>

      <Popover
        id={menuId}
        classes={{ paper: classes.popoverPaper }}
        aria-labelledby={buttonId}
        anchorEl={buttonRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        getContentAnchorEl={null}
        open={isOpen}
        onClose={handleClose}
        disablePortal
        {...PopoverProps}
      >
        {showCloseButton && (
          <IconButton
            classes={{ root: classes.closeIcon }}
            aria-label={`Close ${contentLabel} popover`}
            onClick={handleClose}
            ref={closeButtonRef}
          >
            <CloseIcon />
          </IconButton>
        )}
        {children}
      </Popover>
    </>
  );
}

export default PopoverChip;

PopoverChip.propTypes = {
  disabled: PropTypes.bool,
  contentLabel: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  showCloseButton: PropTypes.bool,
  DeleteButtonProps: PropTypes.shape({}),
  DeleteIcon: PropTypes.node,
  onDelete: PropTypes.func,
  PopoverProps: PropTypes.shape({}),
  children: ChildrenType.isRequired,
};

PopoverChip.defaultProps = {
  disabled: false,
  showCloseButton: false,
  DeleteButtonProps: {},
  DeleteIcon: <CloseIcon fontSize="inherit" />,
  onDelete: undefined,
  PopoverProps: {},
};
