import React from 'react';
import { Grid, Toolbar, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

export default function StyleguideNav() {
  return (
    <Grid container justifyContent="space-evenly" component={Toolbar} variant="dense">
      <Link component={RouterLink} to="/styleguide">
        MUI Components
      </Link>
      <Link component={RouterLink} to="/styleguide/colors">
        Theme Colors
      </Link>
      <Link component={RouterLink} to="/styleguide/icons">
        Icons
      </Link>
      <Link component={RouterLink} to="/styleguide/components">
        Components
      </Link>
      <Link component={RouterLink} to="/styleguide/modern-experience">
        Modern Experience
      </Link>
      <Link component={RouterLink} to="/styleguide/result-cards">
        Result Cards
      </Link>
      <Link component={RouterLink} to="/styleguide/profile">
        Profile
      </Link>
      <Link component={RouterLink} to="/styleguide/autocomplete">
        Autocomplete
      </Link>
      <Link component={RouterLink} to="/styleguide/splash-page">
        Splash Page
      </Link>
    </Grid>
  );
}
