import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { getNextHeadingLevel } from 'utils/utils';

export default function ComponentPropGrid({
  component: Component,
  propOptions,
  spacing,
  titleVariant,
  title,
  xs,
  sm,
  md,
  lg,
  xl,
  propValueCallback,
  GridItemProps,
  children,
  renderChildren,
  ...props
}) {
  const componentProps = useMemo(() => Object.keys(propOptions), [propOptions]);

  return (
    <Grid container spacing={spacing}>
      <Grid item xs={12} component={Typography} variant={titleVariant}>
        {title}
      </Grid>

      {componentProps.map((prop, i) => (
        <React.Fragment key={`${title}-${prop}`}>
          <Grid
            item
            xs={12}
            component={Typography}
            color="textSecondary"
            variant={getNextHeadingLevel(titleVariant)}
          >
            {prop}
          </Grid>
          {propOptions[prop].map((propValue, j) => (
            <Grid
              item
              xs={xs}
              sm={sm}
              md={md}
              lg={lg}
              xl={xl}
              {...GridItemProps}
              key={`${title}-${prop}-${propValue}`}
            >
              <Component
                {...{ [prop]: propValue }}
                {...(propValueCallback ? propValueCallback(prop, propValue, i, j) : {})}
                {...props}
              >
                {renderChildren && renderChildren(prop, propValue, i, j)}
              </Component>
            </Grid>
          ))}
        </React.Fragment>
      ))}
      {Boolean(children) && children}
    </Grid>
  );
}

ComponentPropGrid.propTypes = {
  title: PropTypes.string.isRequired,
  component: PropTypes.elementType.isRequired,
  propOptions: PropTypes.shape({}).isRequired,
  titleVariant: PropTypes.string,
  spacing: PropTypes.number,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  propValueCallback: PropTypes.func,
  GridItemProps: PropTypes.shape({}),
  children: PropTypes.node,
  renderChildren: PropTypes.func,
};

ComponentPropGrid.defaultProps = {
  titleVariant: 'h2',
  spacing: 2,
  xs: 12,
  sm: undefined,
  md: undefined,
  lg: undefined,
  xl: undefined,
  propValueCallback: undefined,
  GridItemProps: {},
  children: null,
  renderChildren: undefined,
};
