import { DEFAULT_LOCATIONS, BCBS_NORTH_CAROLINA, DROPDOWN_AUTOCOMPLETE } from 'utils/constants';
import { selectToken } from 'utils/selectToken';
import bcbsNorthCarolinaLogo from 'assets/bcbs-north-carolina-logo.svg';

export const apiTokens = {
  dev: 'Token 76d540df6793f4b81e9df72579979775583c9071',
  staging: 'Token 4b0fa156df1f0d460f483fe4e60ff48b6701efdd',
  prod: 'Token 4efb77eb29ddefd56b07a754b19d1dca069c4cc3',
};

const token = selectToken(apiTokens);

const bcbsNorthCarolinaConfig = {
  // config name used for fetching client config from fusion
  CONFIG_NAME: 'northcarolina',

  API_TOKEN: token,
  EMBOLD_CLIENT: BCBS_NORTH_CAROLINA,
  EMBOLD_CLIENT_SLUG: BCBS_NORTH_CAROLINA,

  // toggles
  SHOW_DR_SCORE: true,

  // login
  SHOW_REGION_SELECTOR: true,

  // other
  DEFAULT_LOCATION: { ...DEFAULT_LOCATIONS.Raleigh },
  LOGIN_NETWORK_NAME: 'bcbsNorthCarolinaUserId',

  // content
  DISPLAY_NAME: 'BCBS North Carolina',
  HEADER_LOGO: bcbsNorthCarolinaLogo,
  EMBOLD_LOGO_PRESENT: true,
  LOCAL_LOGO_ASSET: bcbsNorthCarolinaLogo,
  FORM_VARIANT: DROPDOWN_AUTOCOMPLETE,
  NO_WELCOME_LINK: true,
};

export default bcbsNorthCarolinaConfig;
