import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions, select } from 'store/toolkit';
import { JPMC, MICROSOFT, SURGERY_PLUS, WALMART } from 'utils/constants';

/**
 * Certain filters need to be enabled/disabled based on feature flags.
 * Certain filters need to have labels updated.
 * This hook watches necessary values and updates the filtersSlice as needed.
 */
export default function useInitializeFilters() {
  const dispatch = useDispatch();
  const networkSlug = useSelector(select.networks.currentSlug);

  const client = useSelector(select.client.name);

  // feature flags for filters
  const showOutcare = useSelector(select.featureFlags.showOutcare);
  const showProviderCredentialsFilter = useSelector(
    select.featureFlags.showProviderCredentialsFilter
  );
  const showTelehealth = useSelector(select.featureFlags.showTelehealth);
  const showBoardCertified = useSelector(select.featureFlags.showBoardCertified);
  const showWheelchairAccessible = useSelector(select.featureFlags.showWheelchairAccessible);
  const showClientFeatured = useSelector(select.featureFlags.showClientFeatured);
  const showHighPerformingRibbon = useSelector(select.featureFlags.showHighPerformingRibbon);
  const showBenefitDiff = useSelector(select.featureFlags.showBenefitDiff);

  // dynamic labels for filters
  const featuredProviderText = useSelector(select.content.featuredProviderText);
  const featuredPlaceText = useSelector(select.content.featuredPlaceText);

  const filtersToUpdate = useMemo(() => {
    const filters = {
      telehealthAvailable: { disabled: !showTelehealth },
      boardCertified: { disabled: !showBoardCertified },
      outcareCompetent: { disabled: !showOutcare },
      isWheelchairAccessible: { disabled: !showWheelchairAccessible },
      hasBenefitDiff: { disabled: !showBenefitDiff },
      highPerforming: { disabled: !showHighPerformingRibbon },
      credentials: { disabled: !showProviderCredentialsFilter },
      featured: {
        disabled: !showClientFeatured || !featuredProviderText, // if there is no featured provider text, we should disable the filter
        label: featuredProviderText?.banner || 'Featured',
      },
      featuredFacility: {
        disabled: !showClientFeatured || !featuredPlaceText, // if there is no featured place text, we should disable the filter
        label: featuredPlaceText?.banner || 'Featured',
      },
    };

    // ugly client specific logic
    if (client === WALMART) {
      filters.inNetworkPreferred = { disabled: false };
    }

    if (client === MICROSOFT && networkSlug === 'ymj') {
      filters.healthConnectPlan = { disabled: false };
    }

    if (client === SURGERY_PLUS) {
      filters.featured = { disabled: true };
      filters.excludeClientFeatured = {
        disabled: false,
        label: `Exclude ${featuredProviderText?.banner}`,
      };
    }

    if (client === JPMC) {
      filters.preferredGroup = { disabled: false };
    }
    return filters;
  }, [
    showTelehealth,
    showBoardCertified,
    showOutcare,
    showWheelchairAccessible,
    showBenefitDiff,
    showHighPerformingRibbon,
    showProviderCredentialsFilter,
    showClientFeatured,
    featuredProviderText,
    client,
    networkSlug,
    featuredPlaceText,
  ]);

  useEffect(() => {
    dispatch(
      actions.filters.updateFromClientConfig({
        filters: filtersToUpdate,
      })
    );
  }, [dispatch, filtersToUpdate]);
}
