import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TextField, InputAdornment } from '@material-ui/core';

import MultiAutocompleteStartAdornment from 'components/ModernExperience/Search/Autocomplete/MultiAutocompleteStartAdornment';
import { MULTI_SEARCH_PLACEHOLDER_TEXT } from 'components/ModernExperience/Search/Autocomplete/MultiAutocomplete';
import { select, actions } from 'store/toolkit';
import { MOBILE_UPDATE_SEARCH_ROUTE } from 'navigation/navigationConstants';

function MultiAutocompleteDummy() {
  const dispatch = useDispatch();
  const searchInput = useSelector(select.search.text);
  const history = useHistory();

  return (
    <TextField
      id="provider-search-dummy"
      placeholder={MULTI_SEARCH_PLACEHOLDER_TEXT}
      value={searchInput}
      margin="dense"
      fullWidth
      classes={{ root: 'rounded' }}
      onClick={() => {
        history.push(MOBILE_UPDATE_SEARCH_ROUTE);
      }}
      onChange={({ target: { value } }) => {
        history.push(MOBILE_UPDATE_SEARCH_ROUTE);
        dispatch(actions.search.handleTextInput({ value, reason: 'input' }));
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <MultiAutocompleteStartAdornment />
          </InputAdornment>
        ),
      }}
    />
  );
}

export default MultiAutocompleteDummy;
