import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions, select } from 'store/toolkit';
import { AiSparkle } from 'icons/colored';

import { Typography, Grid, Box, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { ARIA_ID } from './AiSearchModal';
import AiSearchContent from './AiSearchContent';
import AiSearchInput from './AiSearchInput';
import AiSearchQuickFeedback from './AiSearchQuickFeedback';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('sm')]: {
      maxHeight: 'calc(100vh - 64px)',
    },
    overflow: 'auto',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: theme.spacing(0.5),
  },
  paddedSection: {
    padding: `${theme.spacing(2)}px ${theme.spacing(10)}px ${theme.spacing(3)}px`,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  scrollContainer: {
    flex: 1,
    overflow: 'auto',
    paddingBottom: theme.spacing(10),
  },
}));

function AiSearch() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const specialties = useSelector(select.aiSearch.specialtyRecommendations);
  const loading = useSelector(select.aiSearch.loading);
  const error = useSelector(select.aiSearch.error);
  const chatResponse = useSelector(select.aiSearch.chatResponse);

  const shouldPromptFeedback = useSelector(select.aiSearch.shouldPromptFeedback);
  const shouldDisplayFeedback = useSelector(select.aiSearch.shouldDisplayFeedback);

  const showResponse = useMemo(
    () => Boolean(loading || error || chatResponse || specialties.length),
    [chatResponse, error, loading, specialties.length]
  );

  const dismissFeedback = useCallback(() => {
    dispatch(actions.aiSearch.setShouldDisplayFeedback(false));
    dispatch(actions.aiSearch.setShouldPromptFeedback(false));
  }, [dispatch]);

  useEffect(() => {
    if (shouldPromptFeedback) {
      setTimeout(() => dispatch(actions.aiSearch.setShouldDisplayFeedback(true)), 1200);
    }
  }, [dispatch, shouldPromptFeedback]);

  return (
    <Grid container direction="column" wrap="nowrap" classes={{ root: classes.container }}>
      <Box classes={{ root: classes.paddedSection }}>
        <Typography
          color="primary"
          id={`${ARIA_ID}-title`}
          variant="h2"
          classes={{ root: classes.title }}
        >
          <AiSparkle /> AI Search
        </Typography>
        <Typography id={`${ARIA_ID}-description`} paragraph>
          In a few words enter the medical concern you would like to address, and AI search will
          guide you to an appropriate specialist. For facility recommendations use the standard
          search bar.
        </Typography>
        <Typography paragraph>
          <strong>Disclaimer:</strong> This tool uses generative AI and it can make mistakes. Always
          consult a healthcare professional for medical advice.
        </Typography>
        <AiSearchInput />
      </Box>
      {showResponse && (
        <>
          <Divider />
          <Box classes={{ root: `${classes.paddedSection} ${classes.scrollContainer}` }}>
            <AiSearchContent />
          </Box>
        </>
      )}
      <AiSearchQuickFeedback isOpen={shouldDisplayFeedback} dismiss={dismissFeedback} />
    </Grid>
  );
}

export default AiSearch;
