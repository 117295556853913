import React, { useState, useMemo, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import SplashAnimation from 'components/SplashPage/SplashAnimation';

import StyleguideCanvas from './StyleguideCanvas';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}));

export default function SplashPageStyleguide() {
  const [delayComplete, setDelayComplete] = useState(false);
  useEffect(() => setTimeout(() => setDelayComplete(true), 1500), []);
  const classes = useStyles();
  const duration1 = useMemo(() => ({ intro: 5, loop: 10 }), []); // prevent re-render
  const duration2 = useMemo(() => ({ intro: 5, loop: 10 }), []); // prevent re-render
  const duration3 = useMemo(() => ({ intro: 1, loop: 4 }), []); // prevent re-render
  return (
    <Grid classes={classes}>
      <StyleguideCanvas
        name="SplashAnimation"
        display="row"
        justify="evenly"
        description="Animation can passed props 'size={number}' and 'duration={{ intro: number, loop: number }}'. The demo components are set to delay 1500ms so that the splash screen doesn't obscure their intro animation"
      >
        {delayComplete && (
          <>
            <SplashAnimation duration={duration1} size={50} />
            <SplashAnimation duration={duration2} size={100} />
            <SplashAnimation duration={duration3} size={150} />
          </>
        )}
      </StyleguideCanvas>
    </Grid>
  );
}
SplashPageStyleguide.propTypes = {};
SplashPageStyleguide.defaultProps = {};
