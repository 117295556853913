/* eslint-disable no-alert */
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import useUpdateTitle from 'hooks/useUpdateTitle';

import ResponsiveIconButton from 'components/ResponsiveIconButton';
import CheckboxChip from 'components/Chips/CheckboxChip';
import FeaturedActionCard from 'components/FeaturedActions/FeaturedActionCard';
import HorizontalScrollingContainer from 'components/HorizontalScrollingContainer';
import PopoverChip from 'components/Chips/PopoverChip';
import CardiologyIcon from 'icons/colored/Cardiology';
import MenuChipExample from './MenuChipExample';
import StyleguideCanvas from './StyleguideCanvas';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flex: 1,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
}));

const img =
  'https://emboldstatichosting.blob.core.windows.net/external/promote/doctor_on_demand_square.png';

const featuredActionCardProps = {
  avatarUrl: img,
  title: 'Title Goes Here',
  subtitle: 'Subtitle Goes Here (optional)',
  buttonText: 'Click Here',
  onClick: () => alert('clicked'),
};

const colors = [
  'red',
  'orange',
  'yellow',
  'green',
  'blue',
  'violet',
  'brown',
  'black',
  'silver',
  'gold',
];

export default function CustomComponentStyleguide() {
  const classes = useStyles();

  useUpdateTitle('Custom Component Styleguide', { hidePrefix: true });

  return (
    <div className={classes.root}>
      <StyleguideCanvas
        name="ResponsiveIconButton"
        description={`This component renders an MUI Button if the iconOnly prop is false. 
          
          If iconOnly is truthy it renders an IconButton that uses either the startIcon or endIcon. The text provided as children will serve as the aria-label for the IconButton. 
          
          Props that should only be applied to the Button should be passed via MuiButtonProps.
          Props that should only be applied to the IconButton should be passed via MuiIconButtonProps

          All other props are forwarded to both.`}
      >
        {['primary', 'secondary', undefined].map((color) => (
          <React.Fragment key={color || '-'}>
            <ResponsiveIconButton startIcon={<AccountCircleIcon />} color={color}>
              Responsive Icon Button
            </ResponsiveIconButton>
            <ResponsiveIconButton iconOnly startIcon={<AccountCircleIcon />} color={color}>
              Responsive Icon Button
            </ResponsiveIconButton>
          </React.Fragment>
        ))}
        <br />
        {['contained', 'outlined'].map((variant) => (
          <React.Fragment key={variant || '-'}>
            <ResponsiveIconButton
              startIcon={<AccountCircleIcon />}
              color="primary"
              MuiButtonProps={{ variant }}
            >
              Responsive Icon Button
            </ResponsiveIconButton>
            <ResponsiveIconButton
              iconOnly
              startIcon={<AccountCircleIcon />}
              color="primary"
              MuiButtonProps={{ variant }}
            >
              Responsive Icon Button
            </ResponsiveIconButton>
          </React.Fragment>
        ))}
      </StyleguideCanvas>
      <StyleguideCanvas
        name="HorizontalScrollingContainer"
        description={`This component creates a container with a horizontal scrolling function.
          By default there is a fade effect on each end to indicate to a users that content is being hidden. This can be disabled via the disableFade prop.
          This component is a focusable div to ensure accessability. It is recommended to give this an aria-label or aria-labelledby value for screen readers.`}
      >
        <HorizontalScrollingContainer
          style={{ maxWidth: '75%' }}
          aria-label="Basic horizontal scrollbar example"
        >
          {colors.map((color) => (
            <div key={color} style={{ backgroundColor: color, height: 100, width: 200 }} />
          ))}
        </HorizontalScrollingContainer>
        <br />
        <pre>disableFade</pre>
        <HorizontalScrollingContainer
          disableFade
          style={{ maxWidth: '75%' }}
          aria-label="this example disables the visual fade effect"
        >
          {colors.map((color) => (
            <div key={color} style={{ backgroundColor: color, height: 100, width: 200 }} />
          ))}
        </HorizontalScrollingContainer>
        <br />
        <pre>backgroundColor: &apos;grey.200&apos;</pre>
        <pre>
          scrollBarColor: &apos;primary.main&apos; (does not work in non-webkit browsers like
          Firefox)
        </pre>
        <HorizontalScrollingContainer
          backgroundColor="grey.200"
          scrollBarColor="primary.main"
          style={{ maxWidth: '75%' }}
          aria-label="This example has a custom background color and scrollbar color"
        >
          {colors.map((color) => (
            <div key={color} style={{ backgroundColor: color, height: 100, width: 200 }} />
          ))}
        </HorizontalScrollingContainer>
        <br />
        <pre>spacing: 0</pre>
        <HorizontalScrollingContainer
          style={{ maxWidth: '75%' }}
          aria-label="This example demonstrates the spacing prop"
          spacing={0}
        >
          {colors.map((color) => (
            <div key={color} style={{ backgroundColor: color, height: 100, width: 200 }} />
          ))}
        </HorizontalScrollingContainer>
      </StyleguideCanvas>

      <StyleguideCanvas name="FeaturedActionCard">
        <FeaturedActionCard {...featuredActionCardProps}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo sunt est provident explicabo
          corrupti veritatis aliquid, optio dolore asperiores officiis animi, ullam laudantium
        </FeaturedActionCard>
        <FeaturedActionCard {...featuredActionCardProps} view="mobile">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo sunt est provident explicabo
          corrupti veritatis aliquid, optio dolore asperiores officiis animi, ullam laudantium
        </FeaturedActionCard>
        <FeaturedActionCard
          {...featuredActionCardProps}
          title="No Image Passed"
          subtitle="Using the fallback icon"
          avatarUrl=""
        >
          When no avatarUrl is passed, it will fallback to the avatarIcon node. There is a default
          prop value included for this if it is also not passed.
        </FeaturedActionCard>
        <FeaturedActionCard
          {...featuredActionCardProps}
          title="Use An Icon Rather Than An Image URL"
          subtitle="The avatarIcon prop will handle this"
          avatarUrl=""
          avatarIcon={<CardiologyIcon />}
        >
          Here I passed the &lt;CardiologyIcon /&gt; to the avatarIcon prop and did NOT pass an
          avatarUrl
        </FeaturedActionCard>
        <FeaturedActionCard
          title="This uses a link for the button"
          buttonText="I'm a link"
          ButtonProps={{ component: RouterLink, to: '#' }}
        >
          I can pass ButtonProps to take control of how the MUI button is rendered
        </FeaturedActionCard>
      </StyleguideCanvas>
      <StyleguideCanvas
        name="CheckboxChips"
        description={`
        The design of this component is meant to mimic the MUI Chip component with a checkbox inside.
        The MUI Button component is used to achieve the functionality and accessibility that we need while looking like a 'Chip'.
        Since we don't want to render a nested checkbox input inside of a button, we utilize our custom CheckboxIcon component.

        Any additional props are passed to the MUI button component.
        `}
      >
        <CheckboxChip
          checked={false}
          label="Unchecked"
          onClick={() => {}}
          disabled={false}
          classes={{ root: classes.marginRight }}
        />
        <CheckboxChip
          checked
          label="Checked"
          onClick={() => {}}
          disabled={false}
          classes={{ root: classes.marginRight }}
        />
        <CheckboxChip checked label="Disabled" onClick={() => {}} disabled />
      </StyleguideCanvas>
      <StyleguideCanvas
        name="PopoverChips"
        description={`
        The design of this component is also meant to mimic the MUI Chip component, but taking an action on the element should open a popover.
        The content that we would like to use in the the popover can be passed as children.
        
        The popover and delete button attributes can be extended with the PopoverProps and DeleteButtonProps.
        Any additional props will be applied to the base component which is the ButtonGroup
        `}
      >
        <PopoverChip
          buttonLabel="Popover"
          contentLabel="Popover Content"
          classes={{ root: classes.marginRight }}
        >
          <div>Any content can go here</div>
        </PopoverChip>
        <PopoverChip
          buttonLabel="Delete Button"
          contentLabel="Popover Content"
          classes={{ root: classes.marginRight }}
          onDelete={() => {}}
        >
          <div style={{ width: 300 }}>
            <div>
              If the onDelete prop is present, the delete button will appear in the chip and the
              arrow will be removed.
            </div>
            <div>
              {' '}
              These two components should never render at the same time. The icon that we render for
              the delete button can be overridden to follow the MUI Chip component convention and
              will default to CloseIcon.
            </div>
          </div>
        </PopoverChip>
        <PopoverChip
          buttonLabel="Show Close Button"
          contentLabel="Popover Content"
          classes={{ root: classes.marginRight }}
          showCloseButton
        >
          <div style={{ marginTop: 20, width: 300 }}>
            When the showCloseButton prop is true, we will add a close button to the top right
            corner of the popover
          </div>
        </PopoverChip>
        <PopoverChip
          buttonLabel="Disabled"
          contentLabel="Popover Content"
          classes={{ root: classes.marginRight }}
          disabled
        >
          <div>Any content can go here</div>
        </PopoverChip>
      </StyleguideCanvas>
      <StyleguideCanvas
        name="MenuPopoverChips"
        description={`
        This component is an extension of the PopoverChip component that is meant to create a consistent experience for menu like content.
        
        The values and options props will be used to determine the button label and aria-labels needed for accessibility.
        The "onClear" prop is a function that will be used any time a user takes an action on the clear button inside of the chip or the menu.
        All additional props passed will be applied to the PopoverChip component.
        `}
      >
        <MenuChipExample />
      </StyleguideCanvas>
    </div>
  );
}
