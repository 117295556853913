/* eslint-disable no-shadow */
import { createSelector } from '@reduxjs/toolkit';

import {
  CARE_CATEGORIES,
  CARE_CATEGORIES_MAP,
  CARE_CATEGORY_OPTIONS,
  PLACE_CARE_CATEGORIES,
  PROVIDER_CARE_CATEGORIES,
  PROVIDER_RESULT_TYPE,
} from 'utils/constants';
import { select9 } from 'utils/utils';

import * as selectLocation from '../location/selectLocation';
import { SEARCH_SLICE_NAME } from '../slicesNames';
import { getAutocompleteTypeFromCareCategory, getLabelText } from './searchUtils';
import { VALID_SEARCH_TYPES } from './searchConstants';

// Basic selectors

/** Returns the entire searchSlice - should be used sparingly. In most cases you should use a more granular selector. */
export const slice = (state) => state[SEARCH_SLICE_NAME];

/** @returns {string} The type of search being performed - from CARE_CATEGORIES */
export const type = (state) => state[SEARCH_SLICE_NAME].type;

/**
 * This returns the state of the autocomplete search suggestion type as set by the first two options of the autocomplete menu
 * @returns {'provider'|'place'} Provider/Place type determine which autocomplete suggestions to show
 */
export const autocompleteType = createSelector(
  [type],
  (type) => getAutocompleteTypeFromCareCategory(type) || PROVIDER_RESULT_TYPE
);
/**
 * This is the managed state for the autocomplete selected value. It is set anytime a new autocomplete suggestion is selected.
 * This value should never be null as to avoid bypassing the MUI Autocomplete "selected" behavior
 */
export const autocompleteValue = (state) => state[SEARCH_SLICE_NAME].autocompleteValue;

/** @returns {string} The text shown in the ProviderAutocomplete text box */
export const text = (state) => state[SEARCH_SLICE_NAME].text;

export const specialtyId = (state) => state[SEARCH_SLICE_NAME].specialtyId;
export const subspecialtyId = (state) => state[SEARCH_SLICE_NAME].subspecialtyId;
export const entityId = (state) => state[SEARCH_SLICE_NAME].entityId;
export const entityIds = (state) => state[SEARCH_SLICE_NAME].entityIds;
export const serviceId = (state) => state[SEARCH_SLICE_NAME].serviceId;
export const affiliationName = (state) => state[SEARCH_SLICE_NAME].affiliationName;
export const affiliationType = (state) => state[SEARCH_SLICE_NAME].affiliationType;

export const autocompleteFetchingMap = (state) => state[SEARCH_SLICE_NAME].autocompleteFetchingMap;
export const autocompleteSuggestionsMap = (state) =>
  state[SEARCH_SLICE_NAME].autocompleteSuggestionsMap;

/**
 * Returns a flat array of 9 autocomplete suggestions with name and group properties added
 */
export const autocompleteSuggestions = createSelector(
  [autocompleteSuggestionsMap, text, autocompleteType],
  (autocompleteSuggestionsMap, text, autocompleteType) => {
    if (!text) return [];

    const careCategories = CARE_CATEGORIES_MAP[autocompleteType];
    const countsPerCategory = select9(autocompleteSuggestionsMap, careCategories);

    const flatArray = careCategories
      .map((careCategory) =>
        autocompleteSuggestionsMap[careCategory]
          .slice(0, countsPerCategory[careCategory])
          .map((suggestion) => ({
            ...suggestion,
            name: getLabelText({ suggestion, careCategory }),
            group: careCategory,
          }))
      )
      .flat(1);

    return flatArray;
  }
);

/**
 * Get keys of care categories currently fetching autocomplete suggestions
 */
export const suggestionCategoriesFetching = createSelector([autocompleteFetchingMap], (map) =>
  Object.keys(map).filter((key) => Boolean(map[key]))
);

/** @returns {boolean} Indicates if the user has selected an option from the search autocomplete suggestions */
export const isSuggestionSelected = (state) => state[SEARCH_SLICE_NAME].isSuggestionSelected;

/** @returns {boolean} If true, then searches are performed using a bounding box rather than location + radius */
export const isBoundingBoxSearch = (state) => state[SEARCH_SLICE_NAME].isBoundingBoxSearch;

// Advanced selectors
/** @returns {boolean} True for all place by name, place by type, or place type service searches */
export const isPlaceSearch = createSelector([type], (searchType) =>
  Object.values(PLACE_CARE_CATEGORIES).includes(searchType)
);

/** @returns {boolean} Indicates if the user has performed a freeform search from the search bar */
export const isFreeFormSearch = createSelector(
  [isSuggestionSelected],
  (isSuggestionSelected) => !isSuggestionSelected
);

/** @returns {boolean} True for provider by name and provider by specialty */
export const isProviderSearch = createSelector([type], (searchType) =>
  Object.values(PROVIDER_CARE_CATEGORIES).includes(searchType)
);

/** @returns {boolean} True for service searches with a valid service type */
export const isServiceSearch = createSelector(
  [type],
  (searchType) =>
    searchType === CARE_CATEGORIES.PROVIDER_SERVICE ||
    searchType === CARE_CATEGORIES.FACILITY_SERVICE
);

/** @returns {boolean} A loose place search suggests that we are searching for a place, but have not selected a specific place from autocomplete suggestions */
export const isLoosePlaceSearch = createSelector(
  [type, isSuggestionSelected],
  (searchType, suggestionSelected) =>
    searchType === CARE_CATEGORIES.FACILITY_NAME && !suggestionSelected
);

/** @returns {boolean} True if doing a place by name search and autocomplete suggestion was chosen */
export const isPlaceByExactNameSearch = createSelector(
  [type, isLoosePlaceSearch],
  (searchType, isLoosePlaceSearch) =>
    searchType === CARE_CATEGORIES.FACILITY_NAME && !isLoosePlaceSearch
);

/** @returns {Object|undefined} Returns the corresponding options object from CARE_CATEGORY_OPTIONS for the current search type */
export const currentSearchTypeOptions = createSelector(
  [type],
  (searchType) => CARE_CATEGORY_OPTIONS[searchType]
);

/** @returns {boolean} Indicates that we are searching for one single provider via entityId */
export const isSingleProviderSearch = createSelector(
  [entityId, type],
  (entityId, type) => Boolean(entityId) && type === CARE_CATEGORIES.PROVIDER_NAME
);

/** @returns {boolean} Indicates that we are searching for one single place via entityId */
export const isSinglePlaceSearch = createSelector(
  [entityId, type],
  (entityId, type) => Boolean(entityId) && type === CARE_CATEGORIES.FACILITY_NAME
);

/** @returns {boolean} True if the type is a valid value in the CARE_CATEGORIES object */
export const isValidSearchType = createSelector([type], (searchType) =>
  VALID_SEARCH_TYPES.includes(searchType)
);

/** @returns {boolean} For a given searchType, 'true' if there enough information in the searchInput to perform a search, 'false' otherwise */
export const isValidSearchInput = createSelector(
  [type, specialtyId, subspecialtyId, entityId, serviceId, text, affiliationName, affiliationType],
  (
    type,
    specialtyId,
    subspecialtyId,
    entityId,
    serviceId,
    text,
    affiliationName,
    affiliationType
  ) => {
    switch (type) {
      case CARE_CATEGORIES.FACILITY_NAME:
        return Boolean(text?.length >= 2);
      case CARE_CATEGORIES.FACILITY_TYPE:
        return Boolean(specialtyId || subspecialtyId);
      case CARE_CATEGORIES.PROVIDER_NAME:
        return Boolean(entityId || text?.length >= 2);
      case CARE_CATEGORIES.PROVIDER_SPECIALTY:
        return Boolean(specialtyId || subspecialtyId);
      case CARE_CATEGORIES.PROVIDER_AFFILIATION:
        return Boolean(affiliationName && affiliationType);
      case CARE_CATEGORIES.PROVIDER_SERVICE:
      case CARE_CATEGORIES.FACILITY_SERVICE:
        return Boolean(serviceId);
      default:
        return false;
    }
  }
);

/** @returns {boolean} True if location is searchable */
export const isLocationReadyForSearch = createSelector(
  [selectLocation.validatedLatLong, selectLocation.locationResolved],
  (validLatLong, locationResolved) => {
    if (validLatLong && locationResolved) return true;
    return false;
  }
);

/** @returns {boolean} True if we have enough data to perform a search */
export const isReadyForSearch = createSelector(
  [isValidSearchInput, isLocationReadyForSearch],
  (validSearchInput, validLocation) => {
    if (validSearchInput && validLocation) return true;
    return false;
  }
);
