export function getEnv() {
  return 'staging';
}

export function getClient() {
  return 'quantum';
}

export function selectToken(apiTokens) {
  return apiTokens.staging;
}

export function selectMapKey(azureMapsKeys) {
  return azureMapsKeys.staging;
}
