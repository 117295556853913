import React from 'react';
import PropTypes, { ButtonColorPropType } from 'propTypes/index';
import Button from '@material-ui/core/Button';
import CheckboxIcon from 'icons/dynamic/CheckboxIcon';

function CheckboxChip({ label, ariaLabel, onClick, checked, color, disabled, ...props }) {
  return (
    <Button
      role="checkbox"
      aria-checked={checked}
      aria-label={ariaLabel || label}
      variant="outlined"
      disabled={disabled}
      onClick={() => onClick(!checked)}
      color={checked ? color : 'default'}
      startIcon={<CheckboxIcon checked={checked} />}
      {...props}
    >
      {label}
    </Button>
  );
}

export default CheckboxChip;

CheckboxChip.propTypes = {
  label: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  color: ButtonColorPropType,
};

CheckboxChip.defaultProps = {
  disabled: false,
  color: 'primary',
  ariaLabel: undefined,
};
