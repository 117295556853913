import React from 'react';
import PropTypes from 'prop-types';
import { IconColorPropType } from 'propTypes/index';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineIcon from '@material-ui/icons/CheckBoxOutlineBlank';

export default function CheckboxIcon({ checked, checkedColor, uncheckedColor, ...props }) {
  return checked ? (
    <CheckBoxIcon color={checkedColor} {...props} />
  ) : (
    <CheckBoxOutlineIcon color={uncheckedColor} {...props} />
  );
}

CheckboxIcon.propTypes = {
  checked: PropTypes.bool,
  checkedColor: IconColorPropType,
  uncheckedColor: IconColorPropType,
};

CheckboxIcon.defaultProps = {
  checked: false,
  checkedColor: 'inherit',
  uncheckedColor: 'inherit',
};
