import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';

import SortMenu from 'components/Results/Filters/SortMenu';
import FilterMenu from 'components/Results/Filters/FilterMenu';
import MobileSearchButton from 'components/Results/MobileSearchButton';
import { select } from 'store/toolkit';

import SearchForm from './SearchForm';
import AiSearchButton from './Search/AiSearchButton';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > *': {
      marginBottom: theme.spacing(0.5),
      display: 'flex',
      alignItems: 'center',
      columnGap: theme.spacing(1),
    },
  },
  searchFormWrap: {
    '& > :first-child': {
      maxWidth: 850,
    },
  },
  sortFilterWrap: {
    [theme.breakpoints.down('xs')]: {
      '& > *': { flex: 1 },
    },
  },
}));

function SearchBar({ mobileView }) {
  const classes = useStyles();
  const enableAiSearch = useSelector(select.featureFlags.enableAiSearch);
  const isSmDown = useSelector(select.ui.isSmDown);

  const searchComponent = mobileView ? (
    <MobileSearchButton />
  ) : (
    <SearchForm mobileView={false} autoSearch />
  );

  return (
    <div className={classes.root}>
      <div className={classes.searchFormWrap}>
        {searchComponent}
        {enableAiSearch && <AiSearchButton iconOnly={isSmDown} />}
      </div>
      <div className={classes.sortFilterWrap} aria-label="sort and filter controls">
        <SortMenu />
        <FilterMenu />
      </div>
    </div>
  );
}

export default React.memo(SearchBar);

/**
 * mobileView (boolean) - if true, use the compact "MobileSearchButton", else use the horizontal search form
 */
SearchBar.propTypes = {
  mobileView: PropTypes.bool.isRequired,
};
