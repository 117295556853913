import React, { useMemo, useState, useCallback, useRef } from 'react';
import PropTypes from 'propTypes/index';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  TextField,
  InputAdornment,
  CircularProgress,
  Typography,
  IconButton,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(1)}px 0`,
  },
  inputWrap: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  input: {
    paddingLeft: theme.spacing(1),
  },
  clearInputButton: { padding: theme.spacing(1) },
  loadingSpinner: { marginRight: theme.spacing(1) },
}));

export default function FilterAppendixInput({ handleChange, loading }) {
  const classes = useStyles();

  const [value, setValue] = useState('');
  const ref = useRef();

  const handleChangeInput = useCallback(
    (val) => {
      setValue(val);
      handleChange(val);
    },
    [handleChange]
  );

  const handleChangeEvent = useCallback(
    (e) => {
      const val = e.target.value;
      handleChangeInput(val);
    },
    [handleChangeInput]
  );

  const handleClearInput = useCallback(() => {
    if (ref.current) ref.current.focus();
    handleChangeInput('');
  }, [handleChangeInput]);

  const endAdornment = useMemo(() => {
    if (loading)
      return (
        <InputAdornment position="end">
          <CircularProgress classes={{ root: classes.loadingSpinner }} thickness={3} size={30} />
        </InputAdornment>
      );
    if (value.length > 0)
      return (
        <InputAdornment position="end">
          <IconButton classes={{ root: classes.clearInputButton }} onClick={handleClearInput}>
            <CloseIcon alt="" />
            <Typography variant="srOnly">Clear input value</Typography>
          </IconButton>
        </InputAdornment>
      );
    return null;
  }, [classes, handleClearInput, loading, value]);

  return (
    <Grid container direction="column" classes={{ root: classes.root }}>
      <TextField
        value={value}
        onChange={handleChangeEvent}
        className="rounded"
        margin="dense"
        placeholder="Filter specialties"
        InputProps={{
          inputRef: ref,
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon color="primary" />
            </InputAdornment>
          ),
          endAdornment,
          classes: { root: classes.inputWrap, input: classes.input },
        }}
      />
    </Grid>
  );
}
FilterAppendixInput.propTypes = {
  handleChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};
FilterAppendixInput.defaultProps = {
  loading: false,
};
