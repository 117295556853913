import React from 'react';
import { Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import { select } from 'store/toolkit';

import MultiAutocomplete from 'components/ModernExperience/Search/Autocomplete/MultiAutocomplete';
import SearchForm from 'components/ModernExperience/SearchForm';
import SearchBar from 'components/ModernExperience/SearchBar';

import StyleguideCanvas from './StyleguideCanvas';

const useStyles = makeStyles(() => ({
  root: {},
}));

export default function AutocompleteStyleguide() {
  const classes = useStyles();
  const isMdDown = useSelector(select.ui.isMdDown);
  const isSmDown = useSelector(select.ui.isSmDown);
  const isXsDown = useSelector(select.ui.isXsDown);

  return (
    <Container className={classes.root}>
      <Typography variant="h2" align="center">
        Search Components
      </Typography>

      <StyleguideCanvas name="MultiAutocomplete">
        <MultiAutocomplete />
      </StyleguideCanvas>

      <StyleguideCanvas name="Search Form">
        <pre>mobileView: isXsDown</pre>
        <SearchForm mobileView={isXsDown} />
        <pre>mobileView: isSmDown</pre>
        <SearchForm mobileView={isSmDown} />
        <pre>mobileView: isMdDown</pre>
        <SearchForm mobileView={isMdDown} />
      </StyleguideCanvas>

      <StyleguideCanvas name="Search Bar">
        <pre>mobileView: isXsDown</pre>
        <SearchBar mobileView={isXsDown} />
        <pre>mobileView: isSmDown</pre>
        <SearchBar mobileView={isSmDown} />
        <pre>mobileView: isMdDown</pre>
        <SearchBar mobileView={isMdDown} />
      </StyleguideCanvas>
    </Container>
  );
}
