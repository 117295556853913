import { select, actions, thunks } from 'store/toolkit';
import { FEATURED_ACTION_VARIANTS } from 'utils/constants';
import { UI_INTERACTION, CHAT_VIEW } from './analyticsConstants';

/* ************************************* */
/* *** 400-499 UI Interaction Events *** */
/* ************************************* */

export default {
  tooltipOpened: {
    code: 400,
    category: UI_INTERACTION,
    description: 'A user opened a tooltip to get more information',
    getEventData: (state) => ({
      title: select.tooltip.getTitle(state),
      message: select.tooltip.getMessage(state),
    }),
    triggers: [actions.tooltip.open],
  },

  chatModalOpened: {
    code: 401,
    category: CHAT_VIEW,
    description: 'A user has opened the chat modal',
    getEventData: () => ({
      eventAction: 'Chat Modal Opened',
      eventLabel: 'Chat Modal Opened',
    }),
    labelDescription: 'Chat Modal Opened',
    actionDescription: 'Chat Modal Opened',
  },

  /**
   * @param {string} action Url for the cost estimator tool being directed to
   * @example
   * Action dispatch
   * dispatch(_analyticsActions.costEstimatorRedirect(<carrier portal url>));
   */
  costEstimatorRedirect: {
    code: 402,
    category: UI_INTERACTION,
    description: 'A user has been redirected to external cost estimator tool',
    getEventData: (state, { payload = '' }) => ({
      eventAction: `User redirected to ${payload} for cost estimator tool`,
      eventLabel: 'Cost Estimator Button Click',
    }),
    labelDescription: 'Cost Estimator Button Click',
    actionDescription: 'The url that the user was redirected to for cost estimator tool',
  },

  /**
   * @example
   * Action dispatch
   * dispatch(_analyticsActions.howToVideoClick());
   */
  howToVideoClick: {
    code: 403,
    category: UI_INTERACTION,
    description: 'A user has opened the how-to video from the landing page',
    getEventData: () => ({
      eventLabel: 'How To Video Click',
      eventAction: 'How To Video Click',
    }),
    labelDescription: 'How To Video Click',
    actionDescription: 'How To Video Click',
  },

  featuredActionClick: {
    code: 404,
    category: UI_INTERACTION,
    description: 'A featured action from the homepage was clicked',
    getEventData: (state, action) => {
      const featuredActionObj = action.meta?.arg || action.payload; // featured action would be passed as an arg to a thunk, or in the payload of a raw _analyticsAction dispatch

      const {
        title,
        variant,
        search_specialty: specialtyId,
        search_subspecialty: subspecialtyId,
        promotion: promotionId,
      } = featuredActionObj;

      const variantMap = {
        [FEATURED_ACTION_VARIANTS.PROMOTION]: 'Promotion',
        [FEATURED_ACTION_VARIANTS.SEARCH]: 'Search',
        default: 'unknown',
      };

      return {
        title,
        variant: variantMap[variant] || variantMap.default,
        specialtyId,
        subspecialtyId,
        promotionId,
        eventLabel: 'Featured Action Click',
      };
    },
    triggers: [thunks.results.featuredActionSearch.pending],
  },
};
