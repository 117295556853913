import analyticsSlice from './analyticsSlice';

export const {
  userLogin,
  returningUserLogin,
  alternateProviderDirectoryRedirected,
  changedNetworkSlug,
  searchFormSubmitted,
  topSearch,
  searchThisArea,
  appliedFiltersSearch,
  directionsOpenedFromResultCard,
  directionsOpenedFromProfile,
  locationAutocompleteSelected,
  chatModalOpened,
  directionsClickFromChatProfile,
  phoneClickFromChatProfile,
  phoneClickFromProfile,
  phoneClickFromResultCard,
  phoneClickFromNeedHelpButton,
  phoneClickFromCompareModal,
  addressClickFromCompareModal,
  scheduleClickFromNeedHelpButton,
  promotionResultView,
  promotionProfileView,
  landingPagePromotionClick,
  serviceResultView,
  serviceProfileView,
  compareView,
  compareProfileView,
  mapMarkerView,
  directionsClickFromPromotionProfile,
  websiteClickFromPromotionProfile,
  accessAppLinkFromPromotionProfile,
  accessChatLinkFromPromotionProfile,
  accessEmailFromPromotionProfile,
  accessPDFfromPromotionProfile,
  phoneClickFromPromotionProfile,
  profileView,
  chatProfileView,
  getStartedClickFromPromotionProfile,
  printProfile,
  printCompareTable,
  printShareCompareTable,
  sharedProfile,
  providerServiceCostView,
  alternateSearchRecommended,
  zipcodeNetworkSelectionSubmitted,
  copyTextCompareTable,
  costEstimatorRedirect,
  howToVideoClick,
  featuredActionClick,
} = analyticsSlice.actions;
